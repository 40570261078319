import { action, observable } from 'mobx'
import { pricingTiers } from '../services/Registration'

export default class RegistrationStore {
  @observable tiers = []

  @observable userRegistration = observable.map({})

  @observable loading = false

  @action
  async pricingTiers() {
    this.tiers = await pricingTiers()
  }

  @action
  update(name, value) {
    this.userRegistration.set(name, value)
  }

  @action
  reset() {
    this.userRegistration = observable.map({})
  }
}
