import React, { Component } from 'react'
import { isObservable, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

@observer
class Form extends Component {
  static childContextTypes = {
    _hexlyForm: PropTypes.any.isRequired
  }

  errors = observable.map({})

  model = observable.map({})

  constructor(props) {
    super(props)

    if (props.model) {
      if (isObservable(props.model)) {
        this.model = props.model
      } else {
        this.model.replace(props.model)
      }
    }
  }

  submitForm = (event) => {
    this.errors.clear()
    const { submit, validate, model } = this.props

    if (event && event.preventDefault) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (typeof validate === 'function') {
      this.errors.merge(validate(model))
    }

    submit({
      valid: this.errors.size === 0,
      errors: toJS(this.errors),
      data: toJS(this.model)
    })
  }

  render() {
    const { className } = this.props
    const children = React.Children.map(this.props.children, (child) => {
      return child
        ? React.cloneElement(child, {
            ref: child.props.name,
            error: this.errors.get(child.props.name)
          })
        : null
    })
    return (
      <form
        className={className}
        onSubmit={this.submitForm}
        ref={(el) => {
          this.el = el
        }}
      >
        {children}
      </form>
    )
  }

  getChildContext() {
    return {
      _hexlyForm: {
        model: this.model
      }
    }
  }
}

export default Form
