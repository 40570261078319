import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as MobxProvider } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import { CloudinaryContext } from 'cloudinary-react'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './services/Api'
import RegistrationStore from './mobx/RegistrationStore'
import ListingStore from './mobx/ListingStore'
import SearchStore from './mobx/SearchStore'
import locker from './services/Locker'
import chat from './services/Chat'
import conversation from './services/Conversation'
import { registerErrorCapture } from './services/errorReporting'
import uiStore from './mobx/UiStore'

import App from './App'

const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME

registerErrorCapture()

const stores = {
  registrationStore: new RegistrationStore(),
  listingStore: new ListingStore(),
  search: new SearchStore(),
  locker,
  chat,
  conversation,
  uiStore
}

const GA_KEY = process.env.REACT_APP_GA_KEY
ReactGA.initialize(GA_KEY, { debug: true })

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <MobxProvider {...stores}>
        <CloudinaryContext cloudName={CLOUDINARY_CLOUD_NAME}>
          <App />
        </CloudinaryContext>
      </MobxProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
