import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Checkbox from './checkbox'

import { includes } from 'lodash'

@observer
class CheckboxListManager extends Component {
  static contextTypes = {
    _hexlyForm: PropTypes.any.isRequired
  }

  @observable selected = []

  @observable model = undefined

  constructor(props, context) {
    super(props, context)

    if (!context || !context._hexlyForm) {
      // throw new Error('No context of HexlyForm!')
    } else {
      this.model = context._hexlyForm.model
      this.selected = this.model.get(props.name) || []
    }
  }

  onChange = (e) => {
    const { name, onChange } = this.props

    if (e.target.checked) {
      this.selected.push(e.target.value)
    } else {
      const idx = this.selected.indexOf(e.target.value)
      this.selected.splice(idx, 1)
    }

    onChange && onChange(name, this.selected, e)
  }

  render() {
    const { options } = this.props

    return (
      <div className="checkbox-list">
        {options &&
          options.map((o, idx) => {
            return (
              <div className="checkbox-item" key={idx}>
                <Checkbox
                  label={o.label}
                  value={o.value}
                  checked={includes(this.selected, o.value)}
                  onChange={this.onChange}
                />
              </div>
            )
          })}
      </div>
    )
  }
}

export default CheckboxListManager
