import React, { Component } from 'react'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { RADIO_TYPE } from './radio'
import { CHECKBOX_TYPE } from './checkbox'

function isNullOrUndefined(val) {
  return val === null || val === undefined
}


class FormFieldRenderProp extends Component {
  static contextTypes = {
    _hexlyForm: PropTypes.any.isRequired
  }

  errorMessage = ''
  model = undefined

  constructor(props, context) {
    super(props, context)

    makeObservable(this, {
      errorMessage: observable,
      onChange: action,
      model: observable,
      componentWillReceiveProps: action,
      onInvalid: action
    })

    if (!context || !context._hexlyForm) {
      // throw new Error('No context of HexlyForm!')
    } else {
      this.model = context._hexlyForm.model
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.errorMessage = nextProps.error
    }
  }

  onChange = (event, name, value) => {
    this.errorMessage = ''
    if (value === undefined) {
      value = event.target.value
    }

    if (name === undefined) {
      name = this.props.name
    }

    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }

    if (event.target.type === 'radio') {
      // console.log(event, name, value)
    }

    if (this.props.onChange) {
      this.props.onChange(name, value, event)
    } else {
      this.model.set(name, value)
    }
  }

  onInvalid = (event) => {
    event.preventDefault()
    this.errorMessage = event.target.validationMessage
  }

  blur = (event) => {
    const { onBlur, name } = this.props
    this.onInvalid(event)
    onBlur instanceof Function && onBlur(name, event.target.value)
  }

  render() {
    const {
      name,
      value,
      children,
      component: WrappedComponent,
      defaultValue = ''
    } = this.props
    const modelValue = this.model ? this.model.get(name) : undefined
    let valueToPass = isNullOrUndefined(modelValue) ? defaultValue : modelValue
    let checked = false
    if (WrappedComponent.type) {
      if (WrappedComponent.type === RADIO_TYPE) {
        checked = valueToPass === value
        valueToPass = value
      } else if (WrappedComponent.type === CHECKBOX_TYPE) {
        checked = valueToPass
      }
    }

    const propsToPass = {
      ...this.props,
      checked,
      value: valueToPass,
      onBlur: this.blur,
      errorMessage: this.errorMessage,
      onInvalid: this.onInvalid,
      onChange: this.onChange
    }
    return (
      <WrappedComponent {...propsToPass}>
        {children && children}
      </WrappedComponent>
    )
  }
}

export default (observer(FormFieldRenderProp))
