import React from 'react'
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client'
import { map } from 'lodash'
import Select from './select'

function LanguageSelect({
  value,
  name,
  label,
  onChangeEvent,
  onInvalid,
  onChange,
  required,
  errorMessage,
  data: { languages }
}) {
  return (
    <Select
      value={value}
      onChangeEvent={onChangeEvent}
      onChange={onChange}
      onInvalid={onInvalid}
      errorMessage={errorMessage}
      required={required}
      name={name}
      label={label}
      options={map(languages, (s) => {
        return { value: s.alpha3Code, name: s.language }
      })}
    />
  )
}

export default graphql(gql`
  query LanguagesQuery {
    languages {
      language
      alpha3Code
    }
  }
`)(LanguageSelect)
