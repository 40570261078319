import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { QandA as QandASVG } from '../components/svg/privacy-terms'
import QAndAComponent from '../components/home/QandA'
import '../styles/privacy-terms.scss'

import { FAQ } from '../services/faq'

export default class TermsPage extends Component {
  constructor(props) {
    super(props)
    this.sections = {}
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).parentElement.parentElement.scrollTop = 0
  }

  scrollTo = (sectionNumber) => () => {
    this.sections[sectionNumber].scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <div className="privacy page">
        <div className="hero">
          <div className="hero-container">
            <div className="title">
              <h1>Frequently Asked Questions</h1>
            </div>
            <div className="image">
              <QandASVG />
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="page-container">
            <div className="table-of-contents">
              <h6>Table Of Contents</h6>
              <ul>
                {FAQ.map((c, i) => (
                  <li key={i} onClick={this.scrollTo(i)}>
                    {c.title}
                  </li>
                ))}
              </ul>
            </div>
            <div className="sections">
              {FAQ.map((c, i) => (
                <div
                  key={i}
                  ref={(section) => {
                    this.sections[i] = section
                  }}
                  className="section"
                >
                  <h2>{c.title}</h2>
                  <ul>
                    {c.qa.map((qa, i) => (
                      <QAndAComponent key={i} question={qa.q} answer={qa.a} />
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
