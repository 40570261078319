import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { verify } from '../../rest_services/verify'
import Loader from '../loader'
import { Link, useHistory } from 'react-router-dom'
import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle
} from 'react-icons/ai'
import { inject, observer } from 'mobx-react'
import { identity } from 'lodash'
import Tick from '../svg/confirmationTick'
import { Button } from '../onBoarding/Button'
import '../../styles/onBoarding.scss'

const Verify = ({ locker }) => {
  const history = useHistory()
  const notLoggedUser = !locker.accesToken && !locker.identity
  const myAccountPathname = notLoggedUser ? `/login` : `/account`

  const { search } = useLocation()

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)

  const verifyEmail = async () => {
    setLoading(true)
    const searchKey = 'code='
    if (search && search.includes(searchKey)) {
      const code = search.split(searchKey)[1]
      if (code) {
        const [data, requestError] = await verify(code)
        if (!requestError) {
          locker.emailVerified = true
          locker.serialize()
          setSuccess(true)
        } else {
          setMessage(requestError.response.data.message)
          setError(true)
        }
      }
    } else {
      setError(true)
      setMessage('Invalid verification code')
    }

    setLoading(false)
  }

  const onContinue = () => {
    if (!locker?.profile.currentUser.onboardingComplete)
      history.replace('/welcome')
    else {
      history.replace('/')
    }
  }

  useEffect(() => {
    verifyEmail()
  }, [search])

  return (
    <>
      <div className="container">
        <div className="verify-card">
          {loading && (
            <div>
              <h2>Wait a second, we are verifying your email...</h2>
              <Loader />
            </div>
          )}

          {message && error && (
            <div className="verification-icon">
              <AiOutlineExclamationCircle color="red" size={70} />
              <h2>{message}</h2>

              <div className="menu">
                <Link
                  className="button"
                  style={{ marginRight: 10 }}
                  to={{
                    pathname: `/`
                  }}
                >
                  Home
                </Link>
                <Link
                  className="button"
                  to={{
                    pathname: myAccountPathname
                  }}
                >
                  My Account
                </Link>
              </div>
            </div>
          )}

          {success && (
            <div className="onboarding-wrapper">
              <div className="form-container">
                <div className="form-heading">
                  <div className="step-title centered">
                    Your Email has been verified
                  </div>
                  <div className="form-body centered">
                    <div className="illustration-container green">
                      <Tick />
                    </div>
                    <p>
                      Thanks for verifying your account. Continue with the
                      onboarding, complete your information and get ready to
                      enjoy N’TICE
                    </p>
                  </div>
                  <br />
                </div>
                <Button onClick={onContinue} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default inject('locker')(observer(Verify))
