import { keyBy } from 'lodash'

export const FAQ = [
  {
    title: 'Community',
    qa: [
      {
        q: "Do I have to be in education to join the N'TICE community?",
        a:
          "Yes. N'TICE is a lodging facilitator designed exclusively for educators. Verification will be required at registration."
      },
      /* {
        q: "What does it cost to join N'TICE?",
        a:
          'There is an annual membership fee which is based on the level of subscription. Platinum - $249.99 Gold - $149.99, or Silver - $49.99.'
      }, */
      {
        q:
          "How will being a member of N'TICE make travel more affordable for me?",
        a:
          'At registration, each member will list his or her property to be shared with the community. According to the level of subscription whether Platinum, Gold or Silver, once the yearly membership fee is paid, unlimited worldwide lodging becomes available with no additional costs.'
      },
      {
        q: "How will joining N'TICE extend my network?",
        a:
          "Through property share, N'TICE provides its members the unique opportunity to meet and associate with like professionals worldwide."
      },
      {
        q: "How will my membership with N'TICE benefit education?",
        a:
          "A portion of the company’s profits support the N'TICE Education Foundation bringing resources to schools, staff, and students...providing scholarships, materials, and professional development."
      }
    ]
  },
  {
    title: 'Property',
    qa: [
      {
        q: 'Is listing a property required?',
        a:
          'Yes. In order to eliminate the cost of lodging, each member is required to list at least one bedroom.'
      },
      {
        q: 'Can I list only part of my property?',
        a:
          'Yes. You have the option of listing the entire property or just a portion, such as one bedroom and a bathroom.'
      },
      {
        q: 'What if I cannot list my place of residence?',
        a:
          'No worries. The only requirement is that you list "A PLACE" that is conducive to receiving guests, whether it belong to a relative or a close friend. You will be responsible for facilitating the stays of fellow members at that property.'
      },
      {
        q: 'Will I have control over who stays at my place?',
        a:
          "Yes! Each person will have access to individual profiles and host reviews of N'TICE members. You will be notified when a “Request to Stay” has been made and will be required to either accept or reject the booking."
      },
      {
        q: 'Does it matter if I am home when a guest comes to stay?',
        a:
          "That's totally up to you.  If you are not comfortable with teachers staying at your place in your absence, then you will only approve requests when you know you will be in town. Otherwise, you will also make your property available to others while you are out traveling the world."
      },
      {
        q: 'What happens if there are accidental damages to my property?',
        a:
          "As a member of N'TICE each guest agrees to compensate host for said damages."
      }
    ]
  },
  {
    title: 'Destination',
    qa: [
      {
        q: 'How do I see where I can stay?',
        a:
          "Search the N'TICE website for available properties in your chosen destination."
      },
      {
        q: 'How often can I book a stay?',
        a:
          'As often as you desire! Once you pay your annual membership fee, there are no restrictions to the number of stays that you can book.'
      },
      {
        q: 'Can I bring friends with me?',
        a:
          'Yes. We understand that traveling is often done with friends or family.  Although your guests do not have to be teachers, you will need to stay with them in order for them to enjoy the benefits of your membership.'
      },
      {
        q: 'How many friends can I bring?',
        a: 'That number depends on the bedding capacity listed by the host.'
      },
      {
        q: "What's the catch?",
        a:
          'There is no catch! List your property. Pay the membership fee. After that, enjoy unlimited worldwide lodging in a safe and affordable community of educators like yourself.'
      }
    ]
  }
]

export const FAQGrouped = keyBy(FAQ, (o) => o.title.toLowerCase())
