import axios from 'axios';
const BASE_URL = process.env.REACT_APP_NTICE_BASE_URL

export const forget = async (email) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/auth/forget-password`, { email })
    return [data, null]
  }
  catch(e) {
    return [null, e]
  }
}

export const verifyCode = async (code) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/auth/verify-reset-code?code=${code}`)
    return [data, null]
  }
  catch(e) {
    return [null, e]
  }
}

export const setNewPassword = async (code, password) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/auth/reset-password`, { code, password })
    return [data, null]
  }
  catch(e) {
    return [null, e]
  }
}