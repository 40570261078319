import React from 'react'
import PropTypes from 'prop-types'
import LogoIcon from '../svg/logoicon'
import styled from 'styled-components'

const AvatarContainer = styled.div``

const AvatarImage = styled.img`
  width: 45px;
  border-radius: 100%;
`

const InitialContainer = styled.div(({ selected }) => {
  const styles = {
    width: '45px',
    height: '45px',
    borderRadius: '50%',
    fontSize: '25px',
    color: '#fff',
    lineHeight: '45px',
    textAlign: 'center',
    backgroundColor: '#543aff',
    textTransform: 'uppercase'
  }

  if (selected) {
    styles.border = '1px solid white'
  }

  return styles
})

const ProfileAvatar = ({ isSelected, user = {} }) => {
  return (
    <AvatarContainer>
      {user.profile_url && <AvatarImage src={user.profile_url} />}

      {!user.profile_url && user.first_name && (
        <InitialContainer selected={isSelected}>
          {user.first_name.charAt(0)}{user.last_name.charAt(0)}
        </InitialContainer>
      )}
    </AvatarContainer>
  )
}

ProfileAvatar.propTypes = {
  isSelected: PropTypes.bool,
  user: PropTypes.object
}

export default ProfileAvatar
