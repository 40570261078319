import { assign } from 'lodash'
import React, { Component } from 'react'

const DEFAULT_STYLE = {
  counterWrapper: {
    display: 'block'
  },
  counter: {
    color: '#697a91',
    fontSize: '16px',
    display: 'inline-block',
    width: '50px',
    margin: '0 5px'
  },
  counterIcon: {
    border: '1px solid #bfc8d4',
    borderRadius: '100%',
    color: '#bfc8d4',
    cursor: 'pointer',
    display: 'inline-block',
    height: '20px',
    textAlign: 'center',
    userSelect: 'none',
    verticalAlign: 'middle',
    width: '20px'
  },
  counterSubtract: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  counterAdd: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    color: '#909fb2',
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '10px',
    marginLeft: '26px',
    textTransform: 'uppercase'
  }
}

export default class CounterTwo extends Component {
  checkValue = (event) => {
    this.errorMessage = ''
    const { name, onChange } = this.props
    if (!isNaN(event.target.value)) {
      onChange(event, name, +event.target.value)
    }
  }

  subtract = (event) => {
    this.errorMessage = ''
    const { name, incrementBy = 1, onChange, value } = this.props
    let tempValue
    if (incrementBy % 1 !== 0) {
      tempValue = parseFloat(value)
    } else {
      tempValue = parseInt(value, 10)
    }

    tempValue -= incrementBy
    onChange(event, name, tempValue < 0 ? 0 : tempValue)
  }

  add = (event) => {
    this.errorMessage = ''
    const { name, incrementBy = 1, onChange, value } = this.props
    let tempValue
    if (incrementBy % 1 !== 0) {
      tempValue = parseFloat(value)
    } else {
      tempValue = parseInt(value, 10)
    }
    onChange(event, name, tempValue + incrementBy)
  }

  render() {
    const { label, name, required, errorMessage, onInvalid, value } = this.props
    const { inputStyle } = this.props
    return (
      <div className={`form-control ${errorMessage ? 'error' : ''}`}>
        <div style={assign(DEFAULT_STYLE.label)}>{label}</div>
        <div style={assign(DEFAULT_STYLE.counterWrapper)}>
          <span
            name={name}
            onClick={this.subtract}
            key="counter-subtract"
            style={assign(DEFAULT_STYLE.counterSubtract)}
          >
            <span style={assign(DEFAULT_STYLE.counterIcon)}>-</span>
          </span>
          <input
            id={name}
            style={assign(DEFAULT_STYLE.counter, inputStyle)}
            onInvalid={onInvalid}
            key="counter"
            required={required}
            value={value}
            onChange={this.checkValue}
            type="text"
            name={name}
            placeholder="0"
          />
          <span
            name={name}
            onClick={this.add}
            key="counter-add"
            style={assign(DEFAULT_STYLE.counterAdd)}
          >
            <span style={assign(DEFAULT_STYLE.counterIcon)}>+</span>
          </span>
          {errorMessage && <span className="error-text">{errorMessage}</span>}
        </div>
      </div>
    )
  }
}
