import React, { Component } from 'react'

export const CHECKBOX_TYPE = 'Checkbox'

export default class Checkbox extends Component {
  static type = CHECKBOX_TYPE

  render() {
    const {
      name,
      children,
      required,
      label,
      value,
      onChange,
      onInvalid,
      errorMessage,
      checked
    } = this.props

    return (
      <div className={`${errorMessage ? 'error' : ''}`}>
        <label>
          <div className={`radio inline-middle`}>
            <input
              type="checkbox"
              name={name}
              value={value}
              onInvalid={onInvalid}
              onChange={onChange}
              checked={checked}
              required={required}
            />
            <div className="check" />
          </div>
          <div className="inline-middle">
            {children && children}
            {label && <span>{label}</span>}
          </div>
        </label>
        {errorMessage && <span className="error-text">{errorMessage}</span>}
      </div>
    )
  }
}
