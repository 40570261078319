import React from 'react'
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client'
import { map } from 'lodash'
import Select from './select'

function StateSelect({
  label,
  value,
  onChangeEvent,
  onInvalid,
  onChange,
  required,
  errorMessage,
  data: { states }
}) {
  return (
    <Select
      value={value}
      onChangeEvent={onChangeEvent}
      onChange={onChange}
      onInvalid={onInvalid}
      errorMessage={errorMessage}
      required={required}
      name="state"
      label={label || 'State'}
      options={map(states, (s) => {
        return { value: s.name, name: s.name }
      })}
    />
  )
}

export default graphql(gql`
  query CountryQuery {
    states {
      code
      name
    }
  }
`)(StateSelect)
