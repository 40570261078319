
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import styled from "styled-components";

import { TbAlertSquareFilled } from "react-icons/tb";
import ReactTooltip from "react-tooltip";
import { inject, observer } from "mobx-react";
import { useQuery, gql } from '@apollo/client'

const CURRENT_USER_PROPERTIES = gql`
  query {
    currentUser {
      venues {
        id
        status
      }
    }
  }
`

const OnboardingIcon = ({ locker }) => {
  const { loading, data } = useQuery(CURRENT_USER_PROPERTIES)

  const userHasVenues = !data?.currentUser.venues

  if (!locker?.profile.currentUser.onboardingComplete && !locker.profileHasBeenUpdated() && !userHasVenues) {
    return (
      <Link to="/welcome" >
        <IconContext.Provider value={{ color: "#432ECC", border: "1px solid #e61616", size: "25px", style: { marginTop: "9px", marginRight: "-3px" } }}>
          <TbAlertSquareFilled data-tip data-for="onboarding" />
        </IconContext.Provider>
        <ReactTooltip id="onboarding" place="bottom" effect="solid">
          Finish setting up your profile
        </ReactTooltip>
      </Link>
    )
  }
  else return null
}

export default inject("locker")(observer(OnboardingIcon));