
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { BsChatSquareDotsFill } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import { inject, observer } from "mobx-react";
import { action } from "mobx";
import conversation from "../../services/Conversation";
import styled from "styled-components";
import MessageCircle from "../svg/message-circle";

const NotificationDot = styled.div`
  position: absolute;
  right: -10px;
  top: -5px;
  `
const NotificationContainer = styled.span`
  display: block;
  position: relative;
`

const NavInbox = ({ locker }) => {

    useEffect(() => {
        if (locker.authenticated) {
            checkUnreadMessages()
            conversation.initialized && addMessageListener()
        }
    }, [/* locker.unreadConversation, locker.newMessages */])

    const checkUnreadMessages = action(async () => {
        !conversation.initialized && await conversation.initialize(locker.chatToken)
        await conversation.listConversations()
        const convList = conversation.conversations

        convList.length > 0 && convList.forEach(async conv => {
            const unreadMessages = await conv.getUnreadMessagesCount()
            if (unreadMessages !== null && unreadMessages > 0) {
                locker.newMessages = true
                locker.unreadConversation?.length > 0 ? locker.unreadConversation = [...locker.unreadConversation, conv] : locker.unreadConversation = [conv]
            }
        })
    });

    const addMessageListener = () => {
        const Messagelisteners = conversation.client.listenerCount("messageAdded")
        if (Messagelisteners === 0) {
            conversation.client.on("messageAdded", action((message) => {
                const conversationIndex = locker.unreadConversations.findIndex(convo => convo.sid === message.conversation.sid)

                if (conversationIndex === -1) {
                    locker.unreadConversation?.length > 0 ? locker.unreadConversation = [...locker.unreadConversation, message.conversation] : locker.unreadConversation = [message.conversation]
                    locker.newMessages = true
                }
            }))
        }
    }

    return (
        <Link to="/inbox" >
            <IconContext.Provider value={{ color: "909FB2", size: "25px", style: { marginTop: "11px", marginLeft: "10px" } }}>

                <NotificationContainer>
                    <BsChatSquareDotsFill data-tip data-for="inbox" />

                    {locker.newMessages && (
                        <NotificationDot>
                            <MessageCircle />
                        </NotificationDot>)}

                </NotificationContainer>

            </IconContext.Provider>
            <ReactTooltip id="inbox" place="bottom" effect="solid">
                View Inbox
            </ReactTooltip>
        </Link>
    )
}

export default inject('locker')(observer(NavInbox));