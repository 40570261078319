import React, { Component, use } from 'react'
import Autocomplete from 'react-autocomplete'
import { debounce, find } from 'lodash'
import { withRouter } from 'react-router-dom'

// import Moment from 'moment'
// import { extendMoment } from 'moment-range'

// import PeopleFilter from './peopleFilter'
// import CalendarFilter from './calendarFilter'
import { googleGeoCode } from '../../services/Maps'

// const moment = extendMoment(Moment)

const suggestions = [
  {
    id: '1',
    placeName: 'New York City, New York',
    city: 'New York City',
    state: 'New York',
    latitude: 40.712775,
    longitude: -74.005973
  },
  {
    id: '2',
    placeName: 'Seattle, Washington',
    city: 'Seattle',
    state: 'Washington',
    latitude: 47.606209,
    longitude: -122.332071
  },
  {
    id: '3',
    placeName: 'Salt Lake City, Utah',
    city: 'Salt Lake City',
    state: 'Utah',
    latitude: 40.760779,
    longitude: -111.891047
  },
  {
    id: '4',
    placeName: 'Madrid, Spain',
    city: 'Madrid',
    state: 'Spain',
    latitude: 40.416775,
    longitude: -3.70379
  },
  {
    id: '5',
    placeName: 'San Juan, Puerto Rico',
    city: 'San Juan',
    state: 'Puerto Rico',
    latitude: 18.465539,
    longitude: -66.105735
  },
  {
    id: '6',
    placeName: 'Oslo, Norway',
    city: 'Oslo',
    state: 'Norway',
    latitude: 59.913869,
    longitude: 10.752245
  }
]

@withRouter
class GlobalSearch extends Component {
  inputRef = React.createRef()

  // state = {
  //   location: undefined,
  //   name: undefined,
  //   startDate: undefined,
  //   endDate: undefined,
  //   guests: undefined,
  //   items: suggestions
  // }

  constructor(props) {
    super(props)

    const { initial } = props

    this.state = {
      location: {
        placeName: initial.name,
        city: initial.city,
        state: initial.state,
        latitude: initial.latitude,
        longitude: initial.longitude
      },
      name: initial.name,
      startDate: initial.startDate,
      endDate: initial.endDate,
      guests: initial.guests,
      items: suggestions,
      highlightedIndex: null,
      onFocusElementId: null,
      viewportWidth: window.innerWidth,

    }
  }




  // static getDerivedStateFromProps(props, state) {
  //
  //
  //   const { initial } = props
  //
  //   return null
  //
  // return {
  //   location: {
  //     placeName: initial.name,
  //     city: initial.city,
  //     state: initial.state,
  //     latitude: initial.latitude,
  //     longitude: initial.longitude
  //   },
  //   name: initial.name,
  //   startDate: initial.startDate,
  //   endDate: initial.endDate,
  //   guests: initial.guests
  // }
  // }
  componentDidMount() {
    this.setState({ name: '', items: suggestions })
    this.setState({ highlightedIndex: null })
    this.setState({ onFocusElementId: null })
    this.setState({ location: null })
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({ viewportWidth: window.innerWidth });
  }

  getGeocodingResults = debounce(
    async (query) => {
      try {
        if (!query) {
          return
        }
        if (query.length < 3) {
          return
        }

        const data = await googleGeoCode(query)
        this.setState({ items: data })
      } catch (e) {
        this.setState({ items: [] })
      }
    },
    200,
    { leading: true }
  )

  clear = () => {
    this.setState({ name: '', items: suggestions })
    this.inputRef.current.focus()
    this.setState({ highlightedIndex: null })
    this.setState({ onFocusElementId: null })
  }

  change = (event, value) => {
    this.setState({ highlightedIndex: null })
    this.setState({ onFocusElementId: null })
    /* 
        let newState
        this.props.home ? newState = { name: value } : newState = { name: event.target.value } */
    const newState = !this.props.home ? { name: value } : { name: event.target.value }

    if (!value && !this.props.home) {
      newState.items = suggestions
    }
    this.setState(newState)
    this.props.home ? this.getGeocodingResults(event.target.value) : this.getGeocodingResults(value)
  }

  select = (id) => {
    this.setState({ highlightedIndex: null })
    this.setState({ onFocusElementId: null })
    const location = find(this.state.items, { id })
    const locationRef =
      location.city && location.state
        ? `${location.city}, ${location.state}`
        : location.placeName

    this.setState({ editing: true, name: locationRef, location: location })
    this.inputRef && this.inputRef.current && this.inputRef.current.blur()
  }

  dateChange = (range) => {
    this.setState({
      startDate: range.start.format('YYYY-MM-DD'),
      endDate: range.end.format('YYYY-MM-DD')
    })
  }

  peopleChange = ({ total }) => {
    this.setState({ guests: total })
  }

  search = () => {

    if (this.props.isAuthenticated) {
      const { onSearch } = this.props
      const { location } = this.state
      const valid = location && location.placeName // && startDate && endDate && guests > 0
      valid && onSearch && onSearch({ location })
    } else this.noAuthRedirect()


  }

  onEnterHandle = () => {

    const searchUtil = () => {
      this.select(this.state.onFocusElementId)
      this.setState({ editing: false }, () => {
        // Callback function, called after the state has been updated
        this.search();
      });
    }


    if (!this.state.onFocusElementId) {
      const firstResult = this.state.items[0].id
      this.setState({ onFocusElementId: firstResult }, () => {
        searchUtil()
      }
      )
    }
    else {

      searchUtil()
    }
  }

  noAuthRedirect = () => {

    const { history } = this.props
    this.props.history.push('/login')
  }

  handleinputKeyDown = (e) => {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        this.moveSelection(-1);
        break;

      case "ArrowDown":
        e.preventDefault();
        this.moveSelection(1);
        break;

      case "Enter":

        if (this.props.isAuthenticated) {
          setTimeout(this.onEnterHandle, 500)
        } else {
          this.noAuthRedirect()
        }

        break;

      default:
        break;
    }

  }

  moveSelection = (step) => {
    const { highlightedIndex, items } = this.state;
    const newIndex = highlightedIndex === null ? 0 : highlightedIndex + step
    const selectedItem = items[newIndex];

    if (highlightedIndex >= 0 && highlightedIndex < items.length && selectedItem) {
      this.setState({ highlightedIndex: newIndex })
      this.setState({ onFocusElementId: selectedItem.id })
    }

  }


  // TODO: this should be a form
  render() {
    const { items, name } = this.state
    const editing = !!name
    const valid = name?.length > 3  //&& startDate && endDate
    // const selectedRange =
    //   startDate && endDate ? moment.range(startDate, endDate) : undefined
    /*   this.clear(
      ) */
    return (


      <div className={`global-search ${this.props.home ? "home" : ""}`}>

        {!this.props.home ? <Autocomplete
          ref={this.inputRef}
          value={name}
          items={items}
          getItemValue={(item) => item.id}
          inputProps={{
            placeholder: 'Where to?',
            onFocus: (e) => {
              this.inputRef && this.inputRef.current.select()
            },
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                this.search()
              }
            }
          }}
          wrapperStyle={{
            display: 'inline-flex',
            flex: 1
          }}
          renderInput={(props) => {
            return (
              <div className="search-input">

                <i className="icon ion-android-locate" />
                <input type="text" {...props} onKeyDown={(e) => { this.handleinputKeyDown(e) }} />

                {editing && (
                  <button onClick={this.clear}>
                    <i className="icon ion-close" />
                  </button>
                )}
              </div>
            )
          }}
          renderMenu={(items, value, { top }) => {
            return (
              <div
                className="search-results"
                style={{ top, ...this.menuStyle }}
              >
                <div className="container" children={items} />
                {/* TODO: add this in */}
                {/* <div className="browse-region"> */}
                {/* <p>Not sure where to start?</p> */}
                {/* <button className="button primary"> */}
                {/* Browse Properties Within My Region */}
                {/* </button> */}
                {/* </div> */}
              </div>
            )
          }}
          renderItem={(item) => (
            <div key={item.id} className={`search-result ${item.id === this.state.onFocusElementId ? "hovered" : ""}`}>
              <i className="icon ion-android-pin" />
              {item.placeName}

            </div>
          )}
          onChange={this.change}
          onSelect={this.select}
        />
          : <Autocomplete
            ref={this.inputRef}
            value={name}
            items={items}
            getItemValue={(item) => item.id}
            inputProps={{
              placeholder: 'Where to?',
              onFocus: (e) => {
                this.inputRef && this.inputRef.current.select()
              },
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  this.search()
                }
              }
            }}
            wrapperStyle={{
              display: 'inline-flex',
              flex: 1
            }}
            renderInput={(props) => {
              return (
                <div className="search-input">

                  <i className="icon ion-android-locate" />
                  <input type="text" {...props} onKeyDown={(e) => { this.handleinputKeyDown(e) }} />

                  {editing && (
                    <button onClick={this.clear}>
                      <i className="icon ion-close" />
                    </button>
                  )}
                </div>
              )
            }}
            renderMenu={(items, value) => {
              return (
                <div
                  className="search-results"
                  style={{
                    top: "80px",
                    zIndex: "1",
                    width: `${this.state.viewportWidth >= 1440 ? "504px" : "auto"}`,
                    ...this.menuStyle
                  }}
                >
                  <div /* className="container" */ children={items} />
                  {/* TODO: add this in */}
                  {/* <div className="browse-region"> */}
                  {/* <p>Not sure where to start?</p> */}
                  {/* <button className="button primary"> */}
                  {/* Browse Properties Within My Region */}
                  {/* </button> */}
                  {/* </div> */}
                </div>
              )
            }}
            renderItem={(item) => (
              <div key={item.id} className={`search-result ${this.props.home ? "home" : ""} ${item.id === this.state.onFocusElementId ? "hovered" : ""}`}>
                <i className="icon ion-android-pin" />
                {item.placeName}

              </div>
            )}
            onChange={this.change}
            onSelect={this.select}
          />
        }

        {/*NOTE: removing for now */}
        {/*<CalendarFilter*/}
        {/*  selectedRange={selectedRange}*/}
        {/*  onChange={this.dateChange}*/}
        {/*/>*/}

        {/*<PeopleFilter guests={guests} onChange={this.peopleChange} />*/}

        <button
          className="button primary mobile"
          disabled={!valid}
          onClick={() => this.search()}
        >
          Go!
        </button>
      </div>
    )
  }
}

export default GlobalSearch
