import React from 'react'
import { Button } from '../Button'

import '../../../styles/onBoarding.scss'
import Envelope from '../../svg/onBoardingEnvelope'

const VerifyYourAccount = ({ onStepChange, form }) => {
  return (
    <>
      <div className="form-container">
        <div className="form-heading">
          <div className="step-title centered">Verify your account</div>
          <div className="form-body centered">
            <div className="illustration-container">
              <Envelope />
            </div>
            <p>
              We sent an email to <strong>{form.email}</strong>, follow the
              instructions to verify and complete your account
            </p>
          </div>
          <br />
        </div>
        <Button onClick={onStepChange} />
      </div>
    </>
  )
}

export default VerifyYourAccount
