import axios from 'axios'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY
const GOOGLE_MAPS_ENDPOINT = process.env.REACT_APP_GOOGLE_MAPS_ENDPOINT

function createComponentObject(components) {
  return components.reduce((all, component) => {
    const type = component.types.filter((t) => t !== 'political')[0]

    all[type] = component[type === 'country' ? 'short_name' : 'long_name']
    return all
  }, {})
}

const ComponentType = {
  CITY: 'locality',
  NEIGHBORHOOD: 'neighborhood',
  STATE: 'administrative_area_level_1',
  COUNTY: 'administrative_area_level_2',
  POSTAL_CODE: 'postal_code',
  STREET_NUMBER: 'street_number',
  ROUTE: 'route',
  COUNTRY: 'country'
}

const MAP_BOX_GEOCODE_API = "https://api.mapbox.com/geocoding/v5/mapbox.places"
const MAP_BOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export const googleGeoCode = (query, options = {}) => {
  if (!query) return []
  const apiUrl = options.isAddress ? `${MAP_BOX_GEOCODE_API}/${query}.json?language=en&types=address&access_token=${MAP_BOX_ACCESS_TOKEN}` : `${MAP_BOX_GEOCODE_API}/${query}.json?language=en&types=place&access_token=${MAP_BOX_ACCESS_TOKEN}`
  return axios.get(apiUrl).then((resp) => {
    const results = resp ? resp.data.features : []

    if (results.length) {
      return results.map((r) => {
        // TODO: `types` is an array will tell us if it's a house, poi, airport, etc from `r`
        const {
          geometry: { coordinates, location_type: locationType },
          address_components: addressComponents
        } = r
        // const address = createComponentObject(addressComponents)
        // const street = address[ComponentType.STREET_NUMBER]
        // const route = address[ComponentType.ROUTE]

        const postcode = r.context.find(item => item.id.includes("postcode."))
        const country = r.context.find(item => item.id.includes("country."))
        const city = r.context.find(item => item.id.includes("place."))
        const teritory = r.context.find(item => item.id.includes("region."))

        return {
          id: r.id,
          placeName: r.matching_place_name ? r.matching_place_name : r.place_name,
          placeType: locationType,
          latitude: coordinates[1],
          longitude: coordinates[0],
          address1: `${r.matching_text ? r.matching_text : r.text_en} ${r.address ? r.address : ""}`,
          city: city ? city.text : null,
          state: teritory ? teritory.text : null,
          postalCode: postcode ? postcode.text : null,
          country: country ? country.short_code.toUpperCase() : null
        }
      })
    }

    return []
  })
}
