import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

@withRouter
class ErrorPage extends Component {
  render() {
    const {
      location: { state }
    } = this.props
    return (
      <div className="page-not-found">
        <h1>Whoops... we're still ironing out all the kinks!</h1>

        {state && <pre>{JSON.stringify(state, null, 2)}</pre>}

        <Link to="/" className="button primary">
          Find your next adventure
        </Link>
      </div>
    )
  }
}

export default ErrorPage
