import axios from 'axios';
const BASE_URL = process.env.REACT_APP_NTICE_BASE_URL

export const verify = async (code) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/auth/verify`, { code })
    return [data, null]
  }
  catch(e) {
    return [null, e]
  }
}