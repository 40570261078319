import { action, computed, observable, runInAction } from 'mobx'
import { googleGeoCode } from '../services/Maps'

export default class SearchStore {
  query = observable({
    name: '',
    city: '',
    state: '',
    latitude: undefined,
    longitude: undefined,
    startDate: undefined,
    endDate: undefined,
    guests: 0
  })

  @action
  async updateQuery(query = {}) {
    this.query.name = query.name
    this.query.startDate = query.startDate
    this.query.endDate = query.endDate
    this.query.guests = parseInt(query.guests, 10) || 1
    if (!(query.longitude && query.latitude)) {
      runInAction(async () => {
        const results = await googleGeoCode(query.name)
        if (results.length) {
          const { latitude, longitude } = results[0]
          this.query.longitude = longitude
          this.query.latitude = latitude
        }
      })
    } else {
      this.query.longitude = query.longitude
      this.query.latitude = query.latitude
    }
  }

  @computed
  get center() {
    const { longitude, latitude } = this.query
    return longitude && latitude ? [longitude, latitude] : [0, 0]
  }

  set center({ longitude, latitude }) {
    this.query.longitude = longitude
    this.query.latitude = latitude
  }

  @computed
  get params() {
    return {
      w:
        this.query.city && this.query.state
          ? `${this.query.city},${this.query.state}`
          : this.query.name,
      s: this.query.startDate,
      e: this.query.endDate,
      t: this.query.guests
    }
  }
}
