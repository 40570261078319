import React, { Component, Suspense } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Route, Switch, withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import Loader from './components/loader'
import Layout from './Layout'
import NotFound from './NotFound'
import ErrorPage from './Error'

import Home from './components/home/home'
import LoginCallback from './components/auth/LoginCallback'

import Privacy from './pages/privacy'
import Terms from './pages/terms'
import FAQ from './pages/faq'
import ContactPage from './pages/contact'
import WhyPage from './pages/why'
import FoundationPage from './pages/foundation'

import Verify from './components/verify'
import Reset from './components/reset'
import OnBoardingForm from './components/onBoarding/OnBoardingForm'

import 'mapbox-gl/dist/mapbox-gl.css'
import './styles/app.scss'
import './styles/page.scss'
import './styles/tabs.scss'
import './styles/cards.scss'
import './styles/regions.scss'
import './styles/recurly.scss'
import './styles/search.scss'
import './styles/calendar.scss'

import { Crisp } from 'crisp-sdk-web'
import LogIn from './components/onBoarding/Login'
import forget from './components/forget/'

const Map = React.lazy(() =>
  import(/* webpackChunkName: "map" */ './components/home/map')
)
const RegistrationModal = React.lazy(() =>
  import(/* webpackChunkName: "registration" */ './components/registration')
)
const JoinModal = React.lazy(() =>
  import(/* webpackChunkName: "join" */ './components/join')
)
const Inbox = React.lazy(() =>
  import(/* webpackChunkName: "inbox" */ './components/messages/inbox')
)
const VenueDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "venue-details" */ './components/venues/VenueDetails'
  )
)
const SearchListing = React.lazy(() =>
  import(/* webpackChunkName: "search" */ './components/search')
)
const Account = React.lazy(() =>
  import(/* webpackChunkName: "account" */ './components/account')
)
const PublicProfile = React.lazy(() =>
  import(/* webpackChunkName: "publicProfile" */ './components/profile')
)
const SignIn = React.lazy(() =>
  import(
    /* webpackChunkName: "logIn" */ './components/onBoarding/OnBoardingForm'
  )
)

const Welcome = React.lazy(() =>
  import(
    /* webpackChunkName: "logIn" */ './components/onBoarding/OnBoardingSecondForm'
  )
)

const Forgot = React.lazy(() =>
  import(/* webpackChunkName: "Forgot" */ './components/forget/')
)

const REDIRECT_KEY = '_authRedirect'
const crispWebid = process.env.REACT_APP_CRISP_WEBSITE_ID

@inject('locker')
@withRouter
class App extends Component {
  constructor(props) {
    super(props)
    this.previousLocation = this.props.location
  }
  componentDidMount() {
    Crisp.configure(crispWebid)
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props

    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location
    }
  }

  componentDidUpdate() {
    ReactDOM.findDOMNode(this).scrollIntoView()

    const { location } = this.props
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }

  handleAuthentication = async (nextState) => {
    const { locker, history } = this.props

    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      try {
        await locker.handleAuthentication()

        let redirect = { location: '/' }
        const storedRedirect = localStorage.getItem(REDIRECT_KEY)
        if (storedRedirect) {
          redirect = JSON.parse(storedRedirect)
          localStorage.removeItem(REDIRECT_KEY)
        }

        // if for some reason they came from the callback page... go to the home page
        if (/signed-in/gi.test(redirect.location)) {
          redirect.location = '/'
        }

        if (!locker.profile) {
          redirect.location = '/'
        } else if (locker?.profile?.registered === false) {
          redirect.location = '/'
        }

        history.push(redirect.location)
      } catch (err) {
        history.replace('/error', { err: err })
      }
    }
  }

  render() {
    const { location, locker } = this.props
    const userStatus = locker?.profile?.currentUser.status
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    )
    const loggedIn = locker?.accessToken && locker?.profile

    return (
      <Layout>
        <Suspense fallback={<Loader />}>
          <Switch location={isModal ? this.previousLocation : location}>
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/map" component={Map} /> */}
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/why" component={WhyPage} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/foundation" component={FoundationPage} />
            <Route path="/reset" component={Reset} />
            <Route exact path="/verify" component={Verify} />

            {/* <Route
              path="/auth/signed-in"
              render={(props) => {
                this.handleAuthentication(props)
                return <LoginCallback {...props} />
              }}
            /> */}

            {/* {!loggedIn && (
              <Route
                exact
                path="/join"
                render={() => (
                  <div>
                    {isModal ? null : <Home />}
                    <JoinModal />
                  </div>
                )}
              />
            )} */}

            {!loggedIn && (
              <Route
                path="/join"
                render={() => (
                  <div>
                    <SignIn />
                  </div>
                )}
              />
            )}

            {!loggedIn && (
              <Route
                path="/login"
                render={() => (
                  <div>
                    <LogIn />
                  </div>
                )}
              />
            )}

            {!loggedIn && (
              <Route
                path="/forgot"
                component={forget}
              />
            )}

            {loggedIn && <Route path="/search" component={SearchListing} />}
            {loggedIn && (
              <Route path="/account" component={Account} selectedIndex={0} />
            )}
            {loggedIn && (
              <Route exact path="/venue/:id" component={VenueDetail} />
            )}
            {loggedIn && <Route exact path="/inbox/:sid?" component={Inbox} />}

            {loggedIn &&
              !locker?.profile.currentUser.onboardingComplete &&
              !locker.profileHasBeenUpdated() && (
                <Route exact path="/welcome" component={Welcome} />
              )}
            {loggedIn && (
              <Route
                exact
                path="/profile/:sid?"
                render={(props) => (
                  <PublicProfile {...props} status={userStatus} />
                )}
              />
            )}
            {loggedIn && <Route exact path="/notfound" component={NotFound} />}

            {/* 
            <Route component={ErrorPage} /> */}
            <Route path="*" component={NotFound} />
          </Switch>

          {isModal && !loggedIn ? (
            <>
              {/* <Route exact path="/login" component={SignIn} /> */}
              <Route exact path="/forgot" component={Forgot} />
              {/* <Route path="/join" component={JoinModal} /> */}
            </>
          ) : null}
        </Suspense>
      </Layout>
    )
  }
}

export default App
