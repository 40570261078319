import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { map } from 'lodash'
import Modal from 'react-modal'
import YouTube from 'react-youtube'
import { observable } from 'mobx'

import GlobalSearch from '../search/globalSearch'
import * as qs from '../../query-string'

import '../../styles/home.scss'
import frFlag from '../../images/flags/fr.svg'
import gbFlag from '../../images/flags/gb.svg'
import esFlag from '../../images/flags/es.svg'
import cnFlag from '../../images/flags/cn.svg'
import leagueFlag from '../../images/flags/league.svg'
import { MdVerified } from 'react-icons/md'
import Carousel from '../../components/carousels/HomeCarousel'
import FeaturedPlaces from './FeaturedPlaced'
import RecommendedPosts from './RecommendPosts'

const introVideos = [
  { value: 'b7VGvKvRA30', name: 'English', flagPath: gbFlag },
  { value: '5e8jA-bMGro', name: 'Español', flagPath: esFlag },
  { value: 'V8BYsfHnpjc', name: 'Français ', flagPath: frFlag },
  { value: 'HKi80BGu4iY', name: 'Chinese ', flagPath: cnFlag },
  { value: 'Ur5IaSdgoIQ', name: 'Arabic ', flagPath: leagueFlag }
]

@observer
class YouTubeModal extends Component {
  @observable currentVideo = undefined

  onClose = () => {
    const { onClose } = this.props
    this.currentVideo = undefined
    onClose && onClose()
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal
        isOpen={isOpen}
        className="youtube-modal modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="youtube-container">
          {this.currentVideo && (
            <YouTube
              videoId={this.currentVideo}
              opts={{
                width: '560',
                height: '315',
                playerVars: {
                  rel: 0,
                  autoplay: 1,
                  controls: 1,
                  showInfo: 0
                }
              }}
            />
          )}
        </div>
        <div className="form">
          {!this.currentVideo && <h4>Select a language</h4>}

          <div
            className={`language-selector ${this.currentVideo ? 'mini' : ''}`}
          >
            {map(introVideos, (video) => {
              return (
                <div
                  key={video.value}
                  className={`language ${
                    this.currentVideo === video.value ? 'selected' : ''
                  }`}
                  onClick={() => {
                    this.currentVideo = video.value
                  }}
                >
                  <img
                    src={video.flagPath}
                    alt={video.name}
                    title={video.name}
                  />
                </div>
              )
            })}
          </div>

          <button type="button" className="blue" onClick={this.onClose}>
            Close
          </button>
        </div>
      </Modal>
    )
  }
}
const mainFeatures = [
  'Trending Destinations',
  'Discover Events',
  'Opportunities'
]

@withRouter
@inject('registrationStore', 'locker', 'search', 'uiStore')
@observer
class Home extends Component {
  @observable showRegionSelector = false

  @observable showPioneerDiscount = false

  @observable showVideo = false

  @observable searchOnNavbar = false

  handleScroll = () => {
    const container = document.getElementById('app-container')
    container.scrollTop > 240
      ? this.props.uiStore.setSearchOnNavbar(true)
      : this.props.uiStore.setSearchOnNavbar(false)
  }

  async componentWillMount() {
    const { registrationStore } = this.props
    await registrationStore.pricingTiers()
  }

  componentDidMount() {
    const appContainer = document.getElementById('app-container')
    appContainer.addEventListener('scroll', this.handleScroll)
    appContainer.scrollTo(0, 0)
    this.handleScroll()
  }

  componentWillUnmount() {
    document
      .getElementById('app-container')
      .removeEventListener('scroll', this.handleScroll)
  }

  toggleRegionSelector = () => {
    this.showRegionSelector = !this.showRegionSelector
  }

  updateSearchUrl = ({ location, startDate, endDate, guests }) => {
    const { history } = this.props

    history.push({
      pathname: '/search',
      hash: qs.stringify({
        w:
          location.city && location.state
            ? `${location.city}, ${location.state}`
            : location.placeName
      })
    })
  }

  render() {
    const { registrationStore, locker, search } = this.props
    const isRegistered = locker?.profile?.registered === true
    const isAuthenticated = locker?.authenticated
    const { query } = this.props.search

    return (
      <section className="home-main-container">
        <article className="home-header-container">
          <section className="hero-welcome-container">
            <h3>
              Welcome <span>{locker?.profile?.currentUser?.first_name}</span>{' '}
              <MdVerified className="verified-icon" />
            </h3>
            <button
              onClick={() => {
                isAuthenticated
                  ? this.props.history.push('/account/property')
                  : this.props.history.push('/login')
              }}
              className="hero-button"
            >
              List a place
            </button>
          </section>
        </article>
        <article className="home-content-container">
          <section className="hero-search">
            {this.props.uiStore.searchOnNavbar === false ? (
              <>
                <GlobalSearch
                  initial={query}
                  onSearch={(query) => this.updateSearchUrl(query)}
                  home={true}
                  isAuthenticated={isAuthenticated}
                />
                <p>Search for a destination</p>
              </>
            ) : null}
          </section>
          <section className="feature-main-container">
            <article className="list-main-features">
              <section className="main-feature-tabs">
                {mainFeatures.map((item, idx) => (
                  <button
                    key={'main-feature-' + idx}
                    className={`feature-tab {idx === 0 ? 'active' : undefined}`}
                    disabled={idx !== 0 ?? false}
                  >
                    {item}
                    {idx !== 0 && <span> (Coming soon)</span>}
                  </button>
                ))}
              </section>
              <Carousel isAuthenticated={isAuthenticated} />
            </article>
            <section className="feature-secondary-container">
              <FeaturedPlaces isAuthenticated={isAuthenticated} />
              <RecommendedPosts />
            </section>
          </section>
        </article>
      </section>
    )
  }
}

export default Home
