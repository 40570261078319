import { computed, observable, action, runInAction } from 'mobx'
import { Client } from 'twilio-chat'
import Api from '../services/Api'

const Status = {
  UNINITIALIZED: 'uninitialized',
  CONNECTED: 'connected',
  ERRORED: 'errored'
}

const fetchToken = async ({ identity, deviceId = 'browser' }) => {
  const { token } = await Api.post('/api/twilio/chat/token', {
    identity,
    deviceId
  })

  return { token }
}

class Chat {
  @observable status = Status.UNINITIALIZED
  @observable publicChannelsDescriptors = []
  @observable subscribedChannels = []
  @observable error = undefined

  async initialize(identity) {
    try {
      const { token } = await fetchToken({ identity })
      this.client = new Client(token)
      
      await this.sync()

      this.client.on('tokenAboutToExpire', async () => {
        const { token } = await fetchToken({ identity })
        this.client.updateToken(token)
      })
      this.client.on('channelJoined', (ch) => this.onChannelJoined(ch))
      this.client.on('channelLeft', (ch) => this.onChannelLeft(ch))

      this.status = Status.CONNECTED
    } catch (e) {
      // TODO handle more here
      console.warn('Failed to initialize to Twilio', e)
      this.status = Status.ERRORED
      this.error = e
    }
  }

  @computed get notificationChannels() {
    return this.subscribedChannels
  }

  @computed get inboxChannels() {
    return this.subscribedChannels.filter((channel) => {
      return (
        channel?.uniqueName.endsWith('INBOX') ||
        channel?.friendlyName.endsWith('INBOX')
      )
    })
  }

  @computed get notificationMessagesCount() {
    let notificationCount = 0
    if (this.subscribedChannels) {
      notificationCount = this.subscribedChannels
        .filter((channel) => {
          return (
            channel?.uniqueName.endsWith('NOTIFICATION') ||
            channel.friendlyName.endsWith('NOTIFICATION')
          )
        })
        .reduce((acc, channel) => acc + channel.unreadCount, 0)
    }
    return notificationCount
  }

  @computed get inboxMessagesCount() {
    let inboxCount = 0
    if (this.subscribedChannels) {
      inboxCount = this.subscribedChannels
        .filter((channel) => {
          return (
            channel?.uniqueName.endsWith('INBOX') ||
            channel.friendlyName.endsWith('INBOX')
          )
        })
        .reduce((acc, channel) => acc + channel.unreadCount, 0)
    }
    return inboxCount
  }

  async dismissMessage(message) {
    await message.remove()
    await this.sync()
  }

  async markMessagesReadToIndex(channel, index) {
    await channel.updateLastConsumedMessageIndex(index)
    await this.sync()
  }

  async markAllMessagesRead(lastMessage) {
    await lastMessage.channel.updateLastConsumedMessageIndex(lastMessage.index)
    await this.sync()
  }

  async loadMessages(ch, pageSize) {
    if (!ch) {
      return
    }

    return await ch.getMessages(pageSize)
  }

  async getUnreadCount(ch) {
    if (!ch) {
      return
    }
    return await ch.getUnconsumedMessagesCount()
  }

  // TOOD: generate uniqueName?
  async createChannel({ uniqueName, friendlyName, isPrivate = true }) {
    const channel = await this.client.createChannel({
      uniqueName,
      friendlyName,
      isPrivate
    })
    await channel.join()
    return channel
  }

  @action
  async onChannelJoined(ch) {
    console.log(`Joined Channel: "${ch.friendlyName}"`)

    await ch.updateLastConsumedMessageIndex(0)
    const channel = await this.syncChannel(ch)
    this.subscribedChannels.push(channel)
  }

  @action
  async onChannelLeft(ch) {
    if (!ch) {
      return
    }

    console.log(`Left Channel: "${ch.friendlyName}"`)

    ch.removeAllListeners()

    const idx = this.subscribedChannels.indexOf(ch)
    this.subscribedChannels.splice(idx, 1)
  }

  async sync() {
    this.publicChannelsDescriptors = await this.extract(
      this.client.getPublicChannelDescriptors
    )


    const channels = await this.extract(this.client.getSubscribedChannels)

    if (channels && channels.length) {
      this.subscribedChannels = await Promise.all(
        channels.map(async (ch) => this.syncChannel(ch))
      )
    }
  }

  async syncChannel(ch) {
    if (!ch.lastConsumedMessageIndex) {
      await ch.updateLastConsumedMessageIndex(0)
    }

    const { sid, uniqueName, friendlyName } = ch
    const channel = observable({
      sid,
      uniqueName,
      friendlyName,
      typing: false,
      typingMember: {},
      hasPrev: false,
      channel: ch,
      messages: []
    })

    // get the most recent message
    const { items, hasPrevPage, prevPage } = await this.loadMessages(ch, 20)
    channel.hasPrev = hasPrevPage
    channel.messages = items
    channel.unreadCount = await this.getUnreadCount(ch)

    const handler = (prevPage) => {
      return async () => {
        const data = await prevPage()
        channel.hasPrev = data.hasPrevPage
        channel.messages = [...data.items, ...channel.messages]
        channel.prevPage = handler(data.prevPage)
      }
    }

    channel.prevPage = handler(prevPage)

    ch.on('messageAdded', async (message) => {
      runInAction(async () => {
        channel.unreadCount = await this.getUnreadCount(ch)

        const idx = channel.messages.indexOf(message)
        if (idx < 0) {
          channel.messages.push(message)
        }
      })
    })

    ch.on('typingStarted', function (member) {
      channel.typing = true
      channel.typingMember = member
    })

    ch.on('typingEnded', function (member) {
      channel.typing = false
      channel.typingMember = member
    })

    return channel
  }

  async extract(fn) {
    return await extractAll(fn.bind(this.client))
  }
}

async function extractAll(fn) {
  let items = []
  let paginator
  do {
    paginator = await fn()
    paginator.items.forEach((item) => {
      items.push(item)
    })
  } while (paginator.hasNextPage)
  return items
}

export default new Chat()
