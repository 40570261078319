import React, { Component } from 'react'
import styled from 'styled-components'

const CounterInput = styled.input`
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 100px) !important;
`

const CounterSubtract = styled.div`
  display: inline-block;
  color: #bfc8d4;
  margin: 0 15px;
  border: 1px solid #bfc8d4;
  border-radius: 100%;
  padding: 0px 6px 4px 6px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
`

const CounterAdd = styled(CounterSubtract)`
  margin: 0;
`

export default class counter extends Component {
  checkValue = (event) => {
    this.errorMessage = ''
    const { name, onChange } = this.props
    if (!isNaN(event.target.value)) {
      onChange(event, name, +event.target.value)
    }
  }

  subtract = (event) => {
    this.errorMessage = ''
    const { name, incrementBy = 1, onChange, value } = this.props
    let tempValue
    if (incrementBy % 1 !== 0) {
      tempValue = parseFloat(value)
    } else {
      tempValue = parseInt(value, 10)
    }
    tempValue = value - incrementBy
    onChange(event, name, tempValue < 0 ? 0 : tempValue)
  }

  add = (event) => {
    this.errorMessage = ''
    const { name, incrementBy = 1, onChange, value } = this.props
    let tempValue
    if (incrementBy % 1 !== 0) {
      tempValue = parseFloat(value)
    } else {
      tempValue = parseInt(value, 10)
    }
    onChange(event, name, tempValue + incrementBy)
  }

  render() {
    const { label, name, required, errorMessage, onInvalid, value } = this.props
    return (
      <div className={`form-control ${errorMessage ? 'error' : ''}`}>
        <label htmlFor={name}>{label}</label>
        <CounterInput
          id={name}
          onInvalid={onInvalid}
          key="counter"
          required={required}
          value={value}
          onChange={this.checkValue}
          type="text"
          name={name}
          placeholder="0"
        />
        <CounterSubtract
          name={name}
          onClick={this.subtract}
          key="counter-subtract"
        >
          -
        </CounterSubtract>
        <CounterAdd name={name} onClick={this.add} key="counter-add">
          +
        </CounterAdd>
        {errorMessage && <span className="error-text">{errorMessage}</span>}
      </div>
    )
  }
}
