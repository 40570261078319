import { action, makeObservable, observable } from 'mobx'

export default class ListingStore {
  currentListing = undefined
  currentStep = 1
  validGeolocation = null

  constructor() {
    makeObservable(this, {
      currentListing: observable,
      currentStep: observable,
      validGeolocation: observable,
      updateStep: action,
      updateListing: action,
      updateValidGeolocation: action
    })
  }

  updateStep(step) {
    this.currentStep = step
  }

  updateListing(listing) {
    this.currentListing = listing
  }

  updateValidGeolocation(boolean) {
    this.validGeolocation = boolean
  }
}
