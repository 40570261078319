import React, { Component } from 'react'

export default class Select extends Component {
  render() {
    const {
      type,
      name,
      placeholder,
      required,
      label,
      options,
      errorMessage,
      onInvalid,
      onChange,
      value
    } = this.props

    return (
      <div className={`form-control ${errorMessage ? 'error' : ''}`}>
        <label htmlFor={name}>{label}</label>
        <div className="input-wrapper">
          <select
            id={name}
            key={name}
            onInvalid={onInvalid}
            required={required}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
          >
            <option value="">{placeholder}</option>
            {options &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
          </select>
          {errorMessage && <span className="error-text">{errorMessage}</span>}
          <i key="ion-ios-arrow-down" className="icon ion-ios-arrow-down" />
        </div>
      </div>
    )
  }
}
