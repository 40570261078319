import React from 'react'
export default function ({ fill = '#909fb2' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 448 512"
      height="25"
      width="25"
    >
      <path
        fill={fill}
        d="M224 512a64 64 0 0064-64H160a64 64 0 0064 64zm215.4-149.7c-19.3-20.8-55.5-52-55.5-154.3 0-77.7-54.5-139.9-128-155.2V32a32 32 0 10-63.9 0v20.8C118.6 68.1 64 130.3 64 208c0 102.3-36 133.5-55.4 154.3A32 32 0 0032.1 416h383.8a32 32 0 0023.5-53.7z"
      />
    </svg>
  )
}
