import React, { useEffect } from 'react'
import { Button } from '../Button'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import { useQuery } from '@apollo/client'
import { COUNTRIES_LIST } from '../../../gql_services/common'

import '../../../styles/onBoarding.scss'

const WhereDoYouWork = ({ onStepChange, form, setForm }) => {
  const { data, loading } = useQuery(COUNTRIES_LIST)

  const formik = useFormik({
    initialValues: {
      schoolName: '',
      country: ''
    },
    validationSchema: object().shape({
      schoolName: string().required('School Name is required'),
      country: string().required('Country is required')
    }),
    onSubmit: (values, { setErrors }) => {
      const country = data.countries.find(
        (country) =>
          country.country.toLowerCase() === values.country.toLowerCase()
      )

      if (!country)
        return setErrors({
          country: 'Select country from the list'
        })

      const updatedValues = {
        ...values,
        countryId: country.id,
        countryCode: country.alpha2Code
      }

      setForm({ ...form, ...updatedValues })
      onStepChange('SecureYourAccount')
    }
  })

  useEffect(() => {
    if (form) {
      const updateFormik = {}

      if (form.schoolName) updateFormik.schoolName = form.schoolName
      if (form.country) updateFormik.country = form.country

      formik.setValues(updateFormik)
    }
  }, [])

  return (
    <>
      <div className="form-container">
        <div className="form-heading">
          <div className="step-title ">Where do you work?</div>
          <div className="form-stepper">
            <div className="step" />
            <div className="step current" />
            <div className="step" />
          </div>
          <div className="form-body">
            <form className="field-container" onSubmit={formik.handleSubmit}>
              <label className="label">SCHOOL NAME</label>
              <input
                id="schoolName"
                name="schoolName"
                type="schoolName"
                className="inputContent"
                placeholder="School Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.schoolName}
              />
              <p>{formik.touched.schoolName && formik.errors.schoolName}</p>
              <br />
              <label className="label">COUNTRY </label>
              <input
                id="country"
                name="country"
                type="country"
                className="inputContent"
                placeholder="Country"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                list="countryList"
                disabled={!data}
              />
              {!loading && data && (
                <datalist id="countryList">
                  {data.countries.map((country) => (
                    <option
                      key={`country_option_${country.id}`}
                      data-value={country.id}
                      value={country.country}
                    />
                  ))}
                </datalist>
              )}
              <p>{formik.touched.country && formik.errors.country}</p>
              <br />
            </form>
          </div>
          <Button
            disabled={!formik.values.schoolName || !formik.values.country}
            onClick={formik.submitForm}
          />
        </div>
      </div>
    </>
  )
}

export default WhereDoYouWork
