import React, { Component } from 'react'

export default class Input extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  focus = () => {
    this.inputRef && this.inputRef.current && this.inputRef.current.focus()
  }

  render() {
    const {
      type,
      name,
      placeholder,
      required,
      icon,
      label,
      pattern,
      small,
      onClick,
      onBlur,
      errorMessage,
      onInvalid,
      onChange,
      value,
      autoComplete,
      style,
      iconStyle,
      iconPosition,
      labelStyle,
      flex,
      readOnly = false,
      disabled = false
    } = this.props

    const _iconPosition = iconPosition || 'right'
    const flexClass = flex ? 'form-control--flex' : ''
    return (
      <div
        className={`form-control ${flexClass} ${errorMessage ? 'error' : ''}`}
      >
        {label && (
          <label
            className={small ? 'has-small-label' : ''}
            htmlFor={name}
            style={labelStyle || {}}
          >
            {label}
          </label>
        )}
        {small && <small className="has-small-label">{small}</small>}
        <div className="input-wrapper">
          {_iconPosition === 'left' && icon && (
            <i key={icon} className={`icon ${icon}`} style={iconStyle || {}} />
          )}
          <input
            ref={this.inputRef}
            id={name}
            key={name}
            type={type}
            onInvalid={onInvalid}
            required={required}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            pattern={pattern}
            autoComplete={autoComplete || undefined}
            style={style || {}}
            disabled={disabled}
            readOnly={readOnly}
          />
          {errorMessage && <span className="error-text">{errorMessage}</span>}
          {_iconPosition === 'right' && icon && (
            <i key={icon} className={`icon ${icon}`} style={iconStyle || {}} />
          )}
        </div>
      </div>
    )
  }
}
