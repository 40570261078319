// import Raven from 'raven-js'

const sentryConfig = process.env.REACT_APP_SENTRY_CONFIG

export function captureException(err, extra, showReportDialog = false) {
  if (sentryConfig) {
    // Raven.captureException(err, { extra })
    // showReportDialog && Raven.showReportDialog()
  } else {
    console.error(err)
  }
}

export function showDialog() {
  if (sentryConfig) {
    // Raven.showReportDialog()
  } else {
    console.warn('Raven config is missing or not enabled')
  }
}

export function registerErrorCapture() {
  if (sentryConfig) {
    // Raven.config(sentryConfig).install()
  }
}
