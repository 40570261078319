import React, { Component } from 'react'
import loading from './loading.svg'

export class PageLoading extends Component {
  render() {
    return (
      <div className="loading">
        <Loader />
      </div>
    )
  }
}

export class PageOverlayLoader extends Component {
  render() {
    return (
      <div className="loading-overlay">
        <PageLoading>
          <Loader />
        </PageLoading>
      </div>
    )
  }
}

export default class Loader extends Component {
  render() {
    return (
      <div className={'spinner'}>
        <img src={loading} alt="loading" />
      </div>
    )
  }
}
