import React, { useState } from 'react';
import Form, { Input, FormField } from '../forms';
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'
import { inject } from 'mobx-react'
import { forget } from "../../rest_services/forget"
import { Button } from '../onBoarding/Button';
import { useFormik } from 'formik'
import { IoMdArrowBack } from 'react-icons/io';
import { IconContext } from 'react-icons'
import { object, string } from 'yup'
import Envelope from '../svg/onBoardingEnvelope';
import {
    AiOutlineExclamationCircle
} from 'react-icons/ai'

const Forgot = () => {
    const history = useHistory()
    const [error, setError] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: object().shape({
            email: string().email('Invalid email').required('Email is required')
        }),
        onSubmit: async (values) => {
            setLoading(true)

            const [_, requestError] = await forget(values.email)

            if (requestError) {

                if (requestError.response) {

                    if (requestError.response.data.message === 'User with this email does not exist') formik.setErrors({ email: requestError.response.data.message });
                    else setError(requestError.response.data.message)
                }
                else setError(requestError)

                setLoading(false)
                setSubmitted(false)
            } else {
                setSubmitted(true)
            }

            setLoading(false)
        }
    })

    const onGoBack = () => {
        history.push('/');
    }

    const successFragment = (<div className="form-body centered">
        <div className="illustration-container">
            <Envelope />
        </div>
        <p>
            We sent an email to <strong>{formik.values.email}</strong>, follow the
            instructions to reset your password.
        </p>
    </div>)

    const failureFragment = (<div className="form-body centered">
        <AiOutlineExclamationCircle color="red" size={70} />
        <h2 style={{ textAlign: "center" }}>
            Oops, something went wrong, please retry on a few minutes.
        </h2>
    </div>)

    const formFragment = <>
        <div className="step-title ">Forgot your password?</div>
        <div className="form-body">
            <form className="field-container" /* onSubmit={formik.handleSubmit} */>
                <label className="label">EMAIL</label>
                <input
                    id="email"
                    name="email"
                    type="email"
                    className="inputContent"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                <p>{formik.touched.email && formik.errors.email}</p>
                <br />
            </form>
        </div>
        <Button
            disabled={
                !formik.values.email ||
                formik.errors.email ||
                loading
            }
            text={loading ? 'Loading...' : 'Continue'}
            state={loading ? 'loading' : null}
            onClick={formik.submitForm}
        /></>

    const contentHandler = () => {
        if (error) {
            return failureFragment;
        } else if (submitted) {
            return successFragment;
        } else {
            return formFragment;
        }
    }


    return (
        <div className="onboarding-wrapper">

            <div className="back-nav" onClick={onGoBack}>
                <IconContext.Provider value={{ size: '24px' }}>
                    <IoMdArrowBack />
                </IconContext.Provider>
                Back
            </div>

            <div className="form-container">
                <div className="form-heading">

                    {/*       <div className="form-stepper">
                        <div className="step current" />
                        <div className="step" />
                    </div> */}

                    {contentHandler()}

                </div>
            </div>
        </div>
    )



    /*  modalIsOpen ? (
         <Modal
                 isOpen
                 onRequestClose={handleClose}
                 shouldCloseOnEsc={true}
                 shouldCloseOnOverlayClick={true}
                 className="modal-content"
                 overlayClassName="modal-overlay"
             >
                 {!submitted ? <Form
                     className="form"
                     submit={handleSubmit}
                 >
                     <h2>Forget password</h2>
                     <div style={{ height: 10 }} />
 
                     {error && (
                         <div className="alert alert-warning">
                             {error}
                         </div>
                     )}
 
                     <FormField
                         component={Input}
                         label="Email Address"
                         icon="ion-android-mail"
                         type="text"
                         name="email"
                         placeholder="jane.doe@gmail.com"
                         autoComplete={'email'}
                         required
                     />
 
                     <div className="close">
                         <button type="button" className="blue" onClick={handleClose}>
                             Cancel
                         </button>
                         <div>
                             <button type="submit" className="button primary">
                                 Send reset link
                             </button>
                         </div>
                     </div>
                 </Form> : <h2 style={{ padding: 20 }}>{message}</h2>}
             </Modal>
             ) : null; */

};

export default inject('locker')(Forgot);
