import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

const TimestampWrapper = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #727a84;

  .selected & {
    color: white;
    opacity: 0.6;
  }
`
//TODO: don't run this if timestamp is greater than an hour
export const Timestamp = ({ timestamp = Date.now() }) => {
  const [fromNow, setFromNow] = React.useState(moment(timestamp).fromNow())
  React.useEffect(() => {
    let timer = setInterval(() => {
      setFromNow(moment(timestamp).fromNow())
    }, 1000)

    return () => clearInterval(timer)
  }, [timestamp])

  return <TimestampWrapper>{fromNow}</TimestampWrapper>
}
