import {initializeApp} from '@firebase/app'
import { getFirestore, collection, query, where, getDocs, doc, writeBatch, addDoc } from "firebase/firestore";  
import '@firebase/database'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
}

const fire = initializeApp(config)

const fireStore = getFirestore(fire);

export const getUserNotifications = async (userId) => {
  const notificationsRef = collection(fireStore, 'notifications')
  const q = query(notificationsRef, where("read", "==", false), where('notifyUser', '==', userId));

  const notifications = await getDocs(q)

  const cleanNotifications = []

  notifications.forEach((doc) => cleanNotifications.push({...doc.data(), id: doc.id}));

  return cleanNotifications
}

export const markAllAsRead = async (notifications) => {
  const batch = writeBatch(fireStore)

  notifications.map(notification => {
    const documentRef = doc(fireStore, "notifications", notification.id)
    batch.set(documentRef, {read: true})
  })

  await batch.commit()
}

export const newMessageNotification = async (user) => {
  const notificationsRef = collection(fireStore, 'notifications')
  
  const docref = await addDoc(notificationsRef, {
    notifyUser: user,
    body: "You have a new message",
    type: "message",
    read: false
  });
}

export default fire
