import React, { useState } from 'react'
import { IoArrowBack, IoArrowForward } from 'react-icons/io5'
import '../../styles/carousel.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom'


function CarouselWithControls({ currentImage, setCurrentImage, backHandler, nextHandler, isAuthenticated }) {





  return (
    <section className="carousel-with-controls">
      <Link to={isAuthenticated ? currentImage.url : "/login"}>
        <article className="image-container">
          <div className="carousel-image-container">
            <img src={currentImage.image} alt={currentImage.name} />
          </div>
          <h6>{currentImage.name}</h6>
        </article>
      </Link>
      <div className="carousel-controls">
        <button onClick={backHandler} name="back" className="carousel-control-button">
          <IoArrowBack />
        </button>
        <button onClick={nextHandler} name="next" className="carousel-control-button">
          <IoArrowForward />
        </button>
      </div>
    </section>
  )
}

export default CarouselWithControls
