import React, { Component } from 'react'
import Autocomplete from 'react-autocomplete'
import { debounce, find } from 'lodash'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { googleGeoCode } from '../../services/Maps'

@observer
class Geocoder extends Component {
  static contextTypes = {
    _hexlyForm: PropTypes.any.isRequired
  }

  @observable model = undefined

  @observable errorMessage = undefined

  constructor(props, context) {
    super(props, context)
    this.state = {
      data: []
    }

    if (!context || !context._hexlyForm) {
      // throw new Error('No context of HexlyForm!')
    } else {
      this.model = context._hexlyForm.model
    }
  }

  getGeocodingResults = debounce(
    async (query) => {
      try {
        const { country, isAddress } = this.props

        this.data = []

        if (!query) {
          return
        }

        if (query.length < 3) {
          return
        }

        const data = await googleGeoCode(query, { country, isAddress })

        this.setState({
          data
        })


      } catch (e) {
        console.error(e)
        this.data = []
      }
    },
    250,
    { leading: true }
  )

  onInput = (e) => {
    if (e.target.validity.valid) {
      this.errorMessage = undefined
    }
  }

  onInvalid = (event) => {
    event.preventDefault()
    this.errorMessage = event.target.validationMessage
  }

  render() {
    const {
      label,
      name,
      placeholder,
      onSelect,
      onChange,
      required,
      flex,
      icon,
      iconPosition = 'right',
      iconStyle = {}
    } = this.props


    const value = this.model.get(name) || undefined
    const flexClass = flex ? 'form-control--flex' : ''

    return (
      <div
        className={`form-control ${flexClass} ${this.errorMessage ? 'error' : ''
          }`}
      >
        <label htmlFor="address-autocomplete">{label}</label>
        {label === "Address" ? <span style={{fontSize:"x-small", color:"gray"}}>Please fill in your complete address and then select the option on the dropdown</span> : null}
        <div className="input-wrapper">
          {iconPosition === 'left' && icon && (
            <i key={icon} className={`icon ${icon}`} style={iconStyle} />
          )}

          <Autocomplete
            inputProps={{
              id: 'address-autocomplete',
              onInvalid: this.onInvalid,
              onInput: this.onInput,
              placeholder,
              required
            }}
            wrapperStyle={{
              width: '100%',
              display: 'block',
              position: 'relative'
            }}
            menuStyle={{
              position: 'absolute',
              left: 0,
              top: '35px',
              fontSize: '90%',
              overflow: 'auto',
              background: 'rgb(244, 247, 250, 0.9)',
              boxShadow: '0px 2px 2px 1px rgba(206, 213, 220, .8)',
              cursor: 'pointer',
              zIndex: 1000
            }}
            getItemValue={(item) => item.id}
            items={this.state.data}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.id}
                style={{
                  background: isHighlighted ? 'white' : '#f4f7fa',
                  color: '#697a91',
                  lineHeight: 1.26,
                  padding: '7px 10px',
                  zIndex: 1001,
                  cursor: 'pointer'
                }}
              >
                {item.placeName}
              </div>
            )}
            value={value}
            onChange={(e, value) => {
              this.model.set(name, value)
              this.getGeocodingResults(value)
              onChange && onChange(name, value)
            }}
            onSelect={(value) => {
              const addr = find(this.state.data, { id: value })
              if (addr) {
                this.model.set(name, addr.address1)
                onSelect && onSelect(addr)

              }
            }}
          />
          {this.errorMessage && (
            <span className="error-text">{this.errorMessage}</span>
          )}

          {iconPosition === 'right' && icon && (
            <i key={icon} className={`icon ${icon}`} style={iconStyle} />
          )}
        </div>
      </div>
    )
  }
}

export default Geocoder
