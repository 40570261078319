import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import { observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components';
import Api from '../services/Api'

import Form, { FormField, Input, TextArea } from '../components/forms'
import '../styles/privacy-terms.scss'
import Loader from '../components/loader'

const ContactState = {
  FORM: 'form',
  SENDING: 'sending',
  SENT: 'sent'
}

const CURRENT_USER_QUERY = gql`
  query {
    currentUser {
      firstName
      lastName
      email
    }
  }
`

@withRouter
@observer
class ContactPage extends Component {
  @observable contact = observable.map({})

  /* @observable  */
  state = {
    formState: ContactState.FORM
  }


  componentDidMount() {
    ReactDOM.findDOMNode(this).parentElement.parentElement.scrollTop = 0
  }

  submit = async () => {

    const payload={}

    this.contact.forEach( (value, key) => {
      payload[key] = value
    })

    this.setState({
      formState: ContactState.SENDING
    })
    
    await Api.post('/api/contact', payload)

    this.setState({
      formState: ContactState.SENT
    })
    
  }

  render() {
    
    return (
      <div className="contact">
        <div className="contact-details">
          <div className="contact-details-container">
            <div className="contact-spacer" />
            <div className="contact-info">
              <div className="contact-inner">
                <h1>The support you need</h1>
                <p>
                  E-mail us with your questions. Our service center is standing
                  by to assist you. On line chat coming soon.
                </p>
                <div>
                  <h3>Contact Info</h3>
                  <label>Email</label>
                  <p>
                    <a href="mailto:dev@ntice.me" target="_top">
                      dev@ntice.me
                    </a>
                  </p>

                  <label>Address</label>
                  <p>
                    NTICE Inc.
                    <br />
                    44 Progreso St
                    <br />
                    Aguadilla PR 00603
                  </p>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <div
                className={[
                  'contact-inner',
                  this.state.formState === ContactState.SENDING ? 'loading' : ''
                ].join(' ')}
              >
                {this.state.formState === ContactState.FORM && (
                  <Query query={CURRENT_USER_QUERY} fetchPolicy="no-cache">
                    {({ loading, data }) => {
                      if (loading) return null

                      if (data && data.currentUser) {
                        const currentUser = data.currentUser

                        this.contact.set(
                          'name',
                          `${currentUser.firstName} ${currentUser.lastName}`
                        )
                        this.contact.set('email', currentUser.email)
                      }

                      return (
                        <Fragment>
                          <h2>Get in touch with us</h2>

                          <Form
                            className="form"
                            submit={this.submit}
                            model={this.contact}
                          >
                            <FormField
                              component={Input}
                              label="Name"
                              type="text"
                              name="name"
                              placeholder="Jane Doe"
                              autoComplete={'name'}
                              required
                            />

                            <FormField
                              component={Input}
                              label="Email"
                              type="email"
                              name="email"
                              placeholder="jane.doe@school.edu"
                              autoComplete={'email'}
                              required
                            />

                            <FormField
                              component={Input}
                              label="Subject"
                              type="text"
                              name="subject"
                              placeholder=""
                              required
                            />

                            <FormField
                              component={TextArea}
                              label="Message"
                              type="text"
                              name="message"
                              placeholder="We want to hear what you have to say"
                              required
                            />

                            <button type="submit" className="button primary">
                              Say Hello
                            </button>
                          </Form>
                        </Fragment>
                      )
                    }}
                  </Query>
                )}

                {this.state.formState === ContactState.SENDING && <Loader />}

                {this.state.formState === ContactState.SENT && (
                  <div>
                    <h1>Your message has been sent.</h1>
                    <p>Look for a timely response from our service center.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="contact-spacer white" />
          </div>
        </div>

        <div className="faq-banner">
          <div className="banner-container">
            <div className="banner-header">
              <div className="banner-small">Make sure to reference our</div>
              <div className="banner-title">Frequently asked questions</div>
            </div>
            <button
              className="button secondary light"
              onClick={() => this.props.history.push('/faq')}
            >
              View Frequently Asked Questions
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactPage
