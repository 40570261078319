import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { toJS, observable, makeObservable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import ReactTooltip from 'react-tooltip'
import { FcApproval } from 'react-icons/fc'
import Downshift from 'downshift'
import Notifications from '../notifications/notifications'
import MessageCircle from '../svg/message-circle'
import AlertIcon from '../svg/alert'
import styled, { createGlobalStyle } from 'styled-components'

import { CURRENT_USER_PROFILE } from '../../gql_services/profile'
import { apolloClient } from '../../services/Api'

const NotificationContainer = styled.span`
  display: block;
  position: relative;
`

const NotificationIcon = styled.div`
  position: absolute;
  right: -10px;
  top: -5px;
`

const UserProfile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`

const VerificationBadge = styled.div`
  position: absolute;
  right: -4px;
  bottom: -2px;
`
@inject('locker', 'chat')
@observer
class AccountDropdown extends Component {
  state = {
    data: null,
    loading: null,
    error: null
  }

  async componentDidMount() {
    try {
      this.setState({
        loading: true
      })

      const { data, loading } = await apolloClient.query({
        query: CURRENT_USER_PROFILE
      })

      if (data) {
        this.setState({
          data: data,
          loading: false,
          error: null
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { name, profileUrl, locker, chat } = this.props
    const isEmailVerified =
      locker.emailVerified || locker.profile?.currentUser?.email_verified

    return (
      <Downshift>
        {({ isOpen, toggleMenu }) => (
          <div className={`dropdown`} onMouseLeave={toggleMenu}>
            <button
              className={`account-dropdown-toggle dropdown-toggle ${
                isOpen ? 'active' : ''
              }`}
              onClick={toggleMenu}
              onMouseOver={() => (isOpen ? null : toggleMenu())}
            >
              {profileUrl ? (
                <img className="account-avatar" src={profileUrl} alt={name} />
              ) : (
                <div className="account-avatar">
                  {`${locker.profile?.currentUser.first_name[0]}${locker.profile?.currentUser.last_name[0]}`}
                </div>
              )}
              <div className="verification-badge">
                {this.state.data?.currentUser?.status === 'verified' && (
                  <FcApproval />
                )}
              </div>
            </button>

            {isOpen && (
              <div className="account-dropdown dropdown-overlay dropdown-overlay--right">
                <ul>
                  <>
                    <ReactTooltip id="verify" place="bottom">
                      We've sent verification link to your email
                    </ReactTooltip>
                    {!isEmailVerified ? (
                      <div
                        data-tip
                        data-for="verify"
                        className="alert alert-warning"
                      >
                        Email isn't verified
                      </div>
                    ) : null}

                    <li>
                      <Link
                        className="menu-button-link"
                        to="/account"
                        onClick={toggleMenu}
                      >
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="menu-button-link"
                        to="/account/trips"
                        onClick={toggleMenu}
                      >
                        Manage Trips
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="menu-button-link"
                        to="/account/bookings"
                        onClick={toggleMenu}
                      >
                        Manage Bookings
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="menu-button-link"
                        to="/account/property"
                        onClick={toggleMenu}
                      >
                        My Properties
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="menu-button-link"
                        to="/contact"
                        onClick={toggleMenu}
                      >
                        Contact Us
                      </Link>
                    </li>
                  </>

                  <li>
                    <button
                      className="menu-button-link-red"
                      onClick={() => locker.logout()}
                    >
                      Log out
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        )
      </Downshift>
    )
  }
}

@inject('locker', 'chat')
@withRouter
@observer
class NavigationMenu extends Component {
  showNotification = false

  constructor() {
    super()
    makeObservable(this, {
      showNotification: observable
    })
  }

  showMenu = (chat, locker, isMobile) => {
    //TODO: (fix) probably not the best way to do this with mobx but it works for now
    const identity = toJS(locker.identity)
    const profile = toJS(locker.profile)

    const name = `${profile?.currentUser?.first_name} ${profile?.currentUser?.last_name}`
    const profileUrl = profile?.currentUser?.profile_url

    const totalUnread = locker?.newNotifications

    const isEmailVerified =
      locker.emailVerified || locker.profile?.currentUser?.email_verified

    const ismobileMenu = (
      <>
        {!isEmailVerified && (
          <div data-tip data-for="verify" className="alert alert-warning">
            <strong>Email isn't verified.</strong>
            Please, check your email, we've sent you a verification link
          </div>
        )}

        <UserProfile>
          {profileUrl ? (
            <div style={{ position: 'relative' }}>
              <img className="account-avatar" src={profileUrl} alt={name} />
              {locker.profile?.currentUser?.status === 'verified' && (
                <VerificationBadge>
                  {' '}
                  <FcApproval />
                </VerificationBadge>
              )}
            </div>
          ) : (
            <div className="account-avatar">
              {`${locker.profile?.currentUser.first_name[0]}${locker.profile?.currentUser.last_name[0]}`}
              {locker.profile?.currentUser?.status === 'verified' && (
                <VerificationBadge>
                  {' '}
                  <FcApproval />
                </VerificationBadge>
              )}
            </div>
          )}
        </UserProfile>

        <li>
          <Link to="/account">My Account</Link>
        </li>
        <li>
          <Link className="menu-button-link" to="/account/property">
            My Properties
          </Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
        <li>
          <button
            className="menu-button-link-red"
            onClick={() => locker.logout()}
          >
            Log Out
          </button>
        </li>
      </>
    )

    return (
      <ul className="menu">
        {/* <li data-tip data-for="map">
          <Link to="/map" className="icon">
            <Earth />
            <ReactTooltip id="map" place="bottom" effect="solid">
              View Cottages Around The World
            </ReactTooltip>
          </Link>
        </li> */}

        {/* Not logged in */}
        {!identity && (
          <>
            <li>
              {/* <button className="button-link" onClick={() => locker.login()}>
                Login
              </button> */}
              <Link
                className="button-link"
                to={{
                  pathname: `/login`
                }}
              >
                Login
              </Link>
            </li>
            <li>
              <Link
                className="button"
                to={{
                  pathname: `/join`
                }}
              >
                Join Now
              </Link>
            </li>
          </>
        )}

        {/* Logged in / Mobile menu */}
        {/*  {identity && isMobile && (
          <>
            {!isEmailVerified && <div data-tip data-for="verify" className="alert alert-warning">
              <strong>Email isn't verified.</strong>
              Please, check your email, we've sent you a verification link
            </div>}
            <li>
              <Link to="/account">My Account</Link>
            </li>
            <li>
              <Link to="/inbox">Inbox</Link>
            </li>
            <li>
              <Link to="/contact">
                Contact Us
              </Link>
            </li>
            <li>
              <button className="menu-button-link-red" onClick={() => locker.logout()}>
                Log Out
              </button>
            </li>
            {profileUrl && (
              <li>
                {name}
                <img src={profileUrl} alt={name} className="account-avatar" />
              </li>
            )}
          </>
        )} */}

        {/* Logged in / Desktop menu */}
        {identity && (
          <>
            {/*  <li data-tip data-for="notifications">
              <button
                className="nav-button-icon"
                onClick={() => (this.showNotification = !this.showNotification)}
              >
                <NotificationContainer>
                  <AlertIcon />
                  {totalUnread > 0 && (
                    <NotificationIcon>
                      <MessageCircle />
                    </NotificationIcon>
                  )}
                </NotificationContainer>
                <ReactTooltip id="notifications" place="bottom" effect="solid">
                  View Notifications
                </ReactTooltip>
              </button>
              <Notifications
                isOpen={this.showNotification}
                onClose={() => (this.showNotification = false)}
              />
            </li> */}
            <div>
              {isMobile ? (
                ismobileMenu
              ) : (
                <AccountDropdown name={name} profileUrl={profileUrl} />
              )}
            </div>
          </>
        )}
      </ul>
    )
  }

  render() {
    const { chat, locker, mobile = false } = this.props

    return this.showMenu(chat, locker, mobile)
  }
}

export default NavigationMenu
