import { gql } from '@apollo/client'

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    profileUrl
    firstName
    lastName
    email
    primaryLanguageCode
    phone
    address1
    address2
    city
    state
    postalCode
    countryCode
    gender
    teachingLevel
    teachingSubject
    registered
    school
    country {
      country
    }
    status
  }
`

export const CURRENT_USER_PROFILE = gql`
  ${USER_FRAGMENT}
  query {
    currentUser {
      ...UserFragment
    }
  }
`

export const GET_USER_PROFILE_BY_ID = gql`
  ${USER_FRAGMENT}
  query GetAccountById($id: Int!) {
    account(id: $id) {
      id
      name
      users {
        ...UserFragment
      }
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  ${USER_FRAGMENT}
  mutation updateUserProfile($input: UserProfileInput) {
    updateProfile(input: $input) {
      ...UserFragment
    }
  }
`
export const CREATE_VERIFICATION_REQUEST = gql`
mutation{
  createVerificationRequest(input:{comments: ""
  }){
    message
    created
  }
}
`