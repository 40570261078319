import React, { Component } from 'react'

export const RADIO_TYPE = 'Radio'

export default class Radio extends Component {
  static type = RADIO_TYPE

  render() {
    const { checked, name, children, required, value, onChange } = this.props

    return (
      <label>
        <div className="radio inline-middle">
          <input
            type="radio"
            name={name}
            key={name}
            value={value}
            checked={checked}
            onChange={onChange}
            required={required}
          />
          <div className="check" />
        </div>
        <div className="inline-middle">{children}</div>
      </label>
    )
  }
}
