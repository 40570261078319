import React, { Component } from 'react'

export default class QandA extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAnswer: false
    }
  }

  toggle = () => {
    const { showAnswer } = this.state
    this.setState({ showAnswer: !showAnswer })
  }

  render() {
    const { question, answer } = this.props
    const { showAnswer } = this.state
    return (
      <li className={'q-and-a ' + (showAnswer ? 'showAnswer' : '')}>
        <p className="question" onClick={this.toggle}>
          <span className="blue">Q: </span>
          <span> {question} </span>
          {!showAnswer && <span className="plus">+</span>}
          {showAnswer && <span className="minus">-</span>}
        </p>
        <p className="answer">
          <span className="light-blue">A: </span>
          <span> {answer} </span>
        </p>
      </li>
    )
  }
}
