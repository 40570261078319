import React, { useState } from 'react'
import { Button } from '../Button'
import { inject } from 'mobx-react'

import { useFormik } from 'formik'
import { object, string, bool, ref } from 'yup'

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

import '../../../styles/onBoarding.scss'

const SecureYourAccount = ({ onStepChange, form, setForm, locker }) => {
  const [submitting, setSubmitting] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false);


  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
      marketing: true,
      terms: true
    },
    validationSchema: object().shape({
      password: string().required('Password is required'),
      repeatPassword: string()
        .required('Please, repeat your password')
        .oneOf([ref('password'), null], 'Passwords must match'),
      marketing: bool(),
      terms: bool()
    }),
    onSubmit: async (values) => {
      setSubmitting(true)

      const updatedForm = {
        ...form,
        ...values
      }
      setForm(updatedForm)

      const [_, error] = await locker.signup(updatedForm)
      if (!error) {
        onStepChange('VerifyYourAccount')
      }

      setSubmitting(false)
    }
  })

  const toggleVisibility = (element) => {
    if (element === 'password') {
      setPasswordVisibility((prevState) => !prevState);
    } else if (element === 'repeatPassword') {
      setRepeatPasswordVisibility((prevState) => !prevState);
    }
  }


  return (
    <>
      <div className="form-container">
        <div className="form-heading">
          <div className="step-title ">Secure your account</div>
          <div className="form-stepper">
            <div className="step" />
            <div className="step" />
            <div className="step current" />
          </div>
          <div className="form-body">
            <form className="field-container" onSubmit={formik.handleSubmit}>
              <label className="label">PASSWORD</label>

              <div className='input-wrapper'>
                <input
                  id="password"
                  name="password"
                  type={passwordVisibility ? "text" : "password"}
                  className="inputContent"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />

                <button className='eye-icon' onClick={() => toggleVisibility("password")}>
                  {passwordVisibility ? <FaRegEyeSlash /> : <FaRegEye />}
                </button>
              </div>


              <p>{formik.touched.password && formik.errors.password}</p>
              <br />
              <label className="label">REPEAT PASSWORD</label>

              <div className='input-wrapper'>
                <input
                  id="repeatPassword"
                  name="repeatPassword"
                  type={repeatPasswordVisibility ? "text" : "password"}
                  className="inputContent"
                  placeholder="Repeat Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.repeatPassword}
                />
                <button className='eye-icon' onClick={() => toggleVisibility("repeatPassword")}>
                  {repeatPasswordVisibility ? <FaRegEyeSlash /> : <FaRegEye />}
                </button>
              </div>

              <p>
                {formik.touched.repeatPassword && formik.errors.repeatPassword}
              </p>
              <br />
              <div className="field-container checkbox">
                <label className="label checkbox">
                  I WANT TO RECEIVE PROMOS AND UPDATES
                </label>
                <input
                  id="marketing"
                  type="checkbox"
                  name="marketing"
                  className="inputContent checkbox"
                  placeholder="Repeat Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.marketing}
                  checked={formik.values.marketing}
                />
              </div>
              <div className="field-container checkbox">
                <label
                  className="label checkbox link"
                  onClick={() => window.open('/terms', '_blank')}
                >
                  I AGREE TO TERMS AND CONDITIONS
                </label>
                <input
                  id="terms"
                  type="checkbox"
                  name="terms"
                  className="inputContent checkbox"
                  placeholder="Repeat Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.terms}
                  checked={formik.values.terms}
                />
              </div>
              <br />
            </form>
          </div>
          <Button
            disabled={
              !formik.values.password ||
              !formik.values.repeatPassword ||
              formik.values.terms == false ||
              submitting
            }
            onClick={formik.submitForm}
            text={submitting ? 'Creating account...' : 'Create Account'}
            state={submitting ? 'loading' : undefined}
          />
        </div>
      </div>
    </>
  )
}

export default inject('locker')(SecureYourAccount)
