import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IoArrowBack, IoArrowForward, } from 'react-icons/io5'
import { IconContext } from "react-icons"
import '../../styles/carousel.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const items = [
  {
    name: "Barcelona",
    image: "https://images.unsplash.com/photo-1511527661048-7fe73d85e9a4?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    url: "/search#w=Barcelona%2C Barcelona%2C Spain"
  },
  {
    name: "Hanoi",
    image: "https://images.unsplash.com/photo-1553851919-596510268b99?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    url: "/search#w=Hanoi%2C Vietnam"
  },
  {
    name: "New York City",
    image: "https://images.unsplash.com/photo-1500916434205-0c77489c6cf7?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    url: "/search#w=New York City%2C New York"
  },
  {
    name: "Mexico City",
    image: "https://images.unsplash.com/photo-1521216774850-01bc1c5fe0da?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    url: "/search#w=Mexico City%2C Mexico"
  }


]
function HomeCarousel({ isAuthenticated }) {
  const [carouselCurrentStep, setCarouselCurrentStep] = useState(0)
  const carouselImagesContainerRef = useRef(null);

  const getImageWidth = () => {
    const image = carouselImagesContainerRef.current.querySelector('img')
    /* if (!image) return;  */
    return image.offsetWidth
  };

  const handlePreviousClick = () => {
    if (carouselCurrentStep === 0) return null
    const container = carouselImagesContainerRef.current
    const imageWidth = getImageWidth()
    const targetScroll = container.scrollLeft - imageWidth
    requestAnimationFrame(() => container.scrollTo({ left: targetScroll, behavior: 'smooth' }))
    setCarouselCurrentStep(prevStep => prevStep - 1)
  };

  const handleNextClick = () => {
    if (carouselCurrentStep === items.length - 1) return null
    const container = carouselImagesContainerRef.current
    const imageWidth = getImageWidth()
    const targetScroll = container.scrollLeft + imageWidth
    requestAnimationFrame(() => container.scrollTo({ left: targetScroll, behavior: 'smooth' }))
    setCarouselCurrentStep(prevStep => prevStep + 1)
  };

  return (
    <section className="carousel-main-container">

      <article className="carousel-images-container" ref={carouselImagesContainerRef}>
        {items.map((item, idx) => (

          <Link to={isAuthenticated ? item.url : "/login"} key={idx} className="carousel-item-container">
            <article className="carousel-item-image">
              <img src={item.image} alt="" />
            </article>
            <article className="carousel-item-body">
              <h5>{item.name}</h5>
              {/* TODO: Pending description or filling up */}
              <p></p>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </article>
          </Link>

        ))}
      </article>


      <article className="carousel-dots-container">

        <div className="main-carousel-controls">
          <button className="carousel-control-button" onClick={handlePreviousClick}>
            <IconContext.Provider value={{ color: "#221766", size: "24px" }} >
              <IoArrowBack />
            </IconContext.Provider>
          </button>
          <button className="carousel-control-button" onClick={handleNextClick}>
            <IconContext.Provider value={{ color: "#221766", size: "24px" }} >
              <IoArrowForward />
            </IconContext.Provider>
          </button>
        </div>

        <div className='carousel-dots'>
          {items.map((item, idx) => (
            <span key={item.name} className={`carousel-dot ${carouselCurrentStep === idx ? "active" : null}`} />
          )
          )}
        </div>
      </article>
    </section>
  )
}

HomeCarousel.propTypes = {
  items: PropTypes.array
}

export default HomeCarousel
