import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { verifyCode, setNewPassword } from "../../rest_services/forget"
import Form, { Input, FormField } from '../forms';
import Loader from '../loader'
import { Link } from 'react-router-dom'
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { useFormik } from "formik";
import { object, string, bool, ref } from 'yup'
import { Button } from "../onBoarding/Button";
import "../../styles/onBoarding.scss"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const Reset = () => {
  const { search } = useLocation();
  const [code, setCode] = useState('')

  const [loading, setLoading] = useState(true)
  const [validCode, setValidCode] = useState(null)
  const [codeValidationError, setCodeValidationError] = useState(null)
  const [passwordValidationError, setPasswordValidation] = useState(null)
  const [message, setMessage] = useState(null)

  const [hasBeenReset, setHasBeenReset] = useState(false)

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false);

  const history = useHistory()

  const verifyResetCode = async () => {
    setLoading(true)
    const searchKey = 'code='
    if (search && search.includes(searchKey)) {
      const code = search.split(searchKey)[1]
      if (code) {
        const [data, requestError] = await verifyCode(code)
        if (!requestError) {
          setCode(code)
          setValidCode(true)
        } else {
          setMessage("Reset code is invalid")
          setCodeValidationError(true)
        }
      }
    } else {
      setCodeValidationError(true)
      setMessage("Reset code is invalid")
    }

    setLoading(false)
  }

  useEffect(() => {
    verifyResetCode()
  }, [search])

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validationSchema: object().shape({
      password: string().required('Password is required'),
      repeatPassword: string()
        .required('Please, repeat your password')
        .oneOf([ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: async (values) => {
      setPasswordValidation(null)
      /*  const { password, repeatPassword } = Object.fromEntries(event.data)
 
       if (password !== repeatPassword) return setPasswordValidation("Password does not match")
  */
      const [_, resetError] = await setNewPassword(code, values.password)

      if (!resetError) {
        setValidCode(false)
        setHasBeenReset(true)
        setMessage("Password has been changed successfully!")
      } else {
        setPasswordValidation("Something went wrong, try one more time or contact our team!")
      }
    }
  })

  const toggleVisibility = (event, element) => {
    event.preventDefault()
    if (element === 'password') {
      setPasswordVisibility((prevState) => !prevState);
    } else if (element === 'repeatPassword') {
      setRepeatPasswordVisibility((prevState) => !prevState);
    }
  }

  return (
    <div className="onboarding-wrapper">
      <div className="form-container">
        <div className="form-heading">

          {loading && <div>
            <h2>Wait a second, we are verifying your email...</h2>
            <Loader />
          </div>}

          {!loading &&
            <>

              <div className="form-body">
                {codeValidationError && <div style={{ alignSelf: "center" }}><AiOutlineExclamationCircle color="red" size={70} /></div>}
                {passwordValidationError && <div style={{ alignSelf: "center" }}><AiOutlineExclamationCircle color="red" size={70} /></div>}
                {hasBeenReset && <div style={{ alignSelf: "center" }}><AiOutlineCheckCircle color="green" size={70} /></div>}

                {message && <>
                  <h2 style={{ alignSelf: "center" }} >{message}</h2>
                  {hasBeenReset &&

                    <Button
                      onClick={() => {
                        history.push("/login")
                      }
                      }
                      text="Login" />
                  }</>}


                {validCode &&
                <>
                  <div className="step-title centered">Create your new password</div>
                  <form className="field-container" onSubmit={formik.handleSubmit}>

                  <label className="label">PASSWORD</label>
                  <div className='input-wrapper'>
                    <input
                      id="password"
                      name="password"
                      type={passwordVisibility ? "text" : "password"}
                      className="inputContent"
                      placeholder="Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <button className='eye-icon' onClick={(event) => toggleVisibility(event, "password")}>
                      {passwordVisibility ? <FaRegEyeSlash /> : <FaRegEye />}
                    </button>
                  </div>

                  <p>{formik.touched.password && formik.errors.password}</p>
                  <br />

                  <label className="label">REPEAT PASSWORD</label>
                  <div className='input-wrapper'>
                    <input
                      id="repeatPassword"
                      name="repeatPassword"
                      type={repeatPasswordVisibility ? "text" : "password"}
                      className="inputContent"
                      placeholder="Repeat Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.repeatPassword}
                    />
                    <button className='eye-icon' onClick={(event) => toggleVisibility(event, "repeatPassword")}>
                      {repeatPasswordVisibility ? <FaRegEyeSlash /> : <FaRegEye />}
                    </button>
                  </div>

                  <p>
                    {formik.touched.repeatPassword && formik.errors.repeatPassword}
                  </p>
                </form>

                <Button
                  disabled={
                    !formik.values.password ||
                    !formik.values.repeatPassword ||
                    formik.errors.password ||
                    formik.errors.repeatPassword ||
                    loading
                      }
                    onClick={formik.submitForm}
                    text={loading ? 'Reseting password...' : 'Reset password'}
                    state={loading ? 'loading' : undefined}
                  />
                </>
              }

            </div>
          </>
          }
        </div>
      </div>
    </div>
  );
}

export default Reset