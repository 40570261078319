import { assign } from 'lodash'
import React, { Component } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import { Input } from '../forms'

const defaultStyle = {
  icon: { color: '#bfc8d4', marginLeft: '-25px' },
  label: {
    display: 'block',
    fontSize: '14px',
    marginBottom: '10px'
  },
  input: {
    height: '40px',
    fontSize: '16px',
    paddingLeft: '15px',
    paddingRight: '30px',
    width: '84%'
  }
}

const buildString = (value) => {
  if (!value) {
    return ''
  }
  if (Array.isArray(value)) {
    return value
      .map(
        ({ start, end }) =>
          `${start.format('D MMM YYYY')} - ${end.format('D MMM YYYY')}`
      )
      .join(', ')
  }
  if (value.start && value.end) {
    return `${value.start.format('D MMM YYYY')} - ${value.end.format(
      'D MMM YYYY'
    )}`
  }
  return ''
}

export default class DatePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showCalendar: false,
      currentDate: null
    }

    this.handleDateSelect = this.handleDateSelect.bind(this)
  }

  handleDateSelect(range=[]) {
    const { onSelect } = this.props
    this.setState({ currentDate: range })
    let newRange = { start: null, end: null }
    if (onSelect && Array.isArray(range)) {
      newRange =
        range.length > 1
          ? { start: moment(range[0]), end: moment(range[1]) }
          : newRange
      onSelect(newRange)
    }
  }

  render() {
    const { showCalendar, currentDate } = this.state
    /* `icon` is available from this.props */
    const { value, label, iconStyle, labelStyle, inputStyle } = this.props
    const dateText = buildString(value)

    return (
      <div className="date-picker">
        <div className="date-picker-input">
          <Input
            readOnly={true}
            label={label}
            style={assign(defaultStyle.input, inputStyle)}
            icon="ion-android-calendar"
            iconStyle={assign(defaultStyle.icon, iconStyle)}
            labelStyle={assign(defaultStyle.label, labelStyle)}
            onClick={() => this.setState({ showCalendar: !showCalendar })}
            value={dateText}
          />
          <DateRangePicker 
            onChange={this.handleDateSelect} 
            value={currentDate} 
            shouldCloseCalendar={()=> this.setState({ showCalendar: false })}
            minDate={new Date()}
            locale={"es-US"}
            isOpen={showCalendar}
            calendarIcon={<i className='ion-android-calendar' />}
          />
        </div>
      </div>
    )
  }
}
