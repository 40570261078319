import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import '../styles/privacy-terms.scss'

export default class WhyPage extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).parentElement.parentElement.scrollTop = 0
  }

  render() {
    return (
      <div className="why page">
        <div className="hero hero--small">
          <div className="hero-container">
            <div className="title">
              <h1>Why N'TICE</h1>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="page-container">
            <div className="sections">
              <div className="section">
                <h2>Mission</h2>
                <div className="box">
                  <p>
                    We facilitate unlimited lodging worldwide for teachers
                    affording them hands on experience in global exploration.
                  </p>
                </div>
              </div>

              <div className="section">
                <h2>Beliefs</h2>
                <div className="box">
                  <ul>
                    <li>
                      We believe that teachers are an extraordinary community of
                      people who merit affordable access to meeting other "like"
                      professionals around the world.
                    </li>
                    <li>
                      We believe that regardless of nationality, teachers are
                      part of a "global family" that when united can elevate
                      instructional expertise.
                    </li>
                    <li>
                      We believe that classroom methodology is enhanced through
                      the collaboration of trade techniques.
                    </li>
                    <li>
                      We believe that socialization builds community,
                      strengthens student/staff relations and enriches personal
                      lives.
                    </li>
                    <li>
                      We believe that travel deepens knowledge and ignites a
                      passion for learning.
                    </li>
                    <li>
                      We believe in continued personal growth; that travel
                      provides the most advantageous learning opportunities
                      available.
                    </li>
                    <li>
                      We believe that travel replenishes the spirit and
                      rejuvenates the soul; that it should be an integral part
                      of every teacher's life regardless of location or economic
                      situation.
                    </li>
                    <li>
                      N'TICE supports the N'TICE Education Foundation which
                      improves the learning experience for children across the
                      globe by upgrading learning facilities, bringing
                      educational and technological resources to schools,
                      providing scholarships for students, and professional
                      development for educators.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
