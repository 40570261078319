import React from 'react'
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client'
import { map } from 'lodash'
import Select from './select'

function CountrySelect({
  label = 'Country',
  value,
  onInvalid,
  onBlur,
  name,
  errorMessage,
  onChange,
  required,
  data: { countries, refetch }
}) {
  return (
    <Select
      value={value}
      onChange={onChange}
      onInvalid={onInvalid}
      onBlur={onBlur}
      errorMessage={errorMessage}
      required={required}
      name={name}
      label={label}
      placeholder="Select Country"
      options={map(countries, (c) => {
        return { value: c.alpha2Code, name: c.country }
      })}
    />
  )
}

export default graphql(gql`
  query CountryQuery {
    countries {
      country
      alpha2Code
    }
  }
`)(CountrySelect)
