import { WebAuth } from 'auth0-js'
import { observable, toJS, action, makeObservable } from 'mobx'
import conversation from './Conversation'
import { apolloClient } from "./Api"
import { CURRENT_USER_PROFILE } from "../gql_services/profile"
import axios from "axios"

const { localStorage, location } = window

const hostPage = `${location.protocol}//${location.host}`

const authContext = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  // redirectUri: `${hostPage}/auth/signed-in`,
  redirectUri: `${hostPage}`,
  audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
  responseType: 'token id_token',
  scope: 'openid profile email offline_access',
}

class LockerLegacy {
  static Status = {
    UNINITIALIZED: 'uninitialized',
    INITIALIZING: 'initializing',
    INITIALIZED: 'initialized'
  }

  @observable account = undefined

  @observable identity = undefined

  @observable profile = undefined

  @observable claims = undefined

  @observable error = undefined

  @observable status = Locker.Status.UNINITIALIZED

  @observable expiresAt = undefined

  @observable tokenRenewalTimeout = undefined

  @observable newNotifications = false

  constructor() {
    this.webAuth = new WebAuth(authContext)

    this.deserialize()
    this.scheduleRenewal()
  }

  signup = async (options = {}) => {
    return new Promise((resolve, reject) => {
      if (!options.email || !options.password) {
        // eslint-disable-next-line
        return reject({
          code: 'email_and_password_required',
          description: 'Email and password required'
        })
      }

      this.webAuth.signupAndAuthorize(
        {
          connection: 'Username-Password-Authentication',
          ...options
        },
        async (err, authResult) => {
          if (err) {
            return reject(err)
          }

          if (authResult && authResult.accessToken && authResult.idToken) {
            await this.setSession(authResult)
            return resolve(authResult)
          } else if (err) {
            return reject(err)
          }
        }
      )
    })
  }

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash(async (err, authResult) => {
        if (authResult?.accessToken && authResult?.idToken) {
          await this.setSession(authResult)
          resolve(authResult)
        } else if (err) {
          reject(err)
        }
      })
    })
  }

  // TODO: temp
  updateProfile(profile) {
    const prevProfile = this.profile
    this.profile = { ...prevProfile, ...profile }
    this.serialize()
    this.deserialize()
  }

  @action
  setSession = async (authResult) => {
    if (!authResult) {
      throw new Error('No access token found')
    }

    try {
      this.status = Locker.Status.INITIALIZING
      this.account = authResult

      const payload = authResult.idToken.split('.')[1]

      this.claims = JSON.parse(decodeToString(payload))
      this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime()

      if (this.claims) {
        this.profile = {}
      }

      const identity = await this.getUserProfile()

      this.identity = identity
      this.status = Locker.Status.INITIALIZED

      this.serialize()
      this.scheduleRenewal()
      // chat.initialize(this.identity.email)

      await conversation.initialize()

      const { data } = await apolloClient.query({
        query: CURRENT_USER_PROFILE
      })

      if (data.currentUser) {
        this.profile.currentUser = {
          ...data.currentUser
        }
      }

      this.serialize()

    } catch (err) {
      this.identity = null

      throw new Error(err)
    }
  }

  // login = (config = {}) => {
  //   this.webAuth.authorize(config)
  // }

  login = async (options = {}) => {
    try {
      this.webAuth.login(options, (err, authResult) => {

        if (!options.email || !options.password) {
          throw new Error("Empty inputs")
        }

        this.setSession(authResult)

      })

    } catch (error) {
      console.log(`Login failed: ${error.message}`)
    }


  }

  logout = (config = {}) => {
    this.reset()
    clearTimeout(this.tokenRenewalTimeout)

    this.webAuth.logout({
      returnTo: `${hostPage}`,
      ...config
    })
  }

  // Check whether the current time is past the
  // access token's expiry time
  isAuthenticated = () => {
    return Date.now() < this.expiresAt
  }

  getUserProfile = () => {
    return new Promise((resolve, reject) => {
      this.webAuth.client.userInfo(
        this.account.accessToken,
        (err, identity) => {
          if (err) {
            return reject(err)
          } else {
            return resolve(identity)
          }
        }
      )
    })
  }

  // TODO: this only works for SSO
  renewToken = () => {
    console.log('renewToken')

    if (!this.account) {
      console.warn('No token available to refresh')
      // this.login()
      // throw new Error('No token available to refresh')
      return
    }

    this.webAuth.checkSession({}, async (err, authResult) => {
      if (err) {
        if (err.error === 'login_required') {
          this.reset()
          this.login()
        }
        return err
      }

      await this.setSession(authResult)
      return authResult
    })
  }

  scheduleRenewal = () => {
    const expiresAt = this.expiresAt
    const delay = expiresAt - Date.now()

    clearTimeout(this.tokenRenewalTimeout)
    if (isNaN(delay)) {
      return
    }

    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken()
      }, delay)
    } else {
      this.renewToken()
    }
  }

  reset = () => {
    this.account = undefined
    this.claims = undefined
    this.identity = undefined
    this.profile = undefined
    this.error = undefined
    this.expiresAt = undefined

    localStorage.clear()
  }

  serialize = () => {
    const { account, identity, claims, profile, expiresAt, onboarding } = this
    const serialized = JSON.stringify(
      toJS({
        account,
        onboarding,
        identity,
        claims,
        profile,
        expiresAt
      })
    )

    localStorage.setItem('_locker', serialized)
  }

  deserialize = async () => {
    const serialized = localStorage.getItem('_locker')
    if (serialized) {
      try {
        const { account, identity, claims, profile, expiresAt } = JSON.parse(
          serialized
        )

        this.account = account
        this.identity = identity
        this.claims = claims
        this.profile = profile
        this.expiresAt = expiresAt
        // chat.initialize(this.identity.email)
        conversation.initialize(this.identity.email)
      } catch (e) {
        console.warn('Failed to deserialize locker details', e)
      }
    }
  }
}

const BASE_URL = process.env.REACT_APP_NTICE_BASE_URL

class Locker {
  authenticated = undefined;
  emailVerified = undefined;

  profile = undefined;
  identity = undefined;

  newNotifications = undefined
  unreadConversations = [];
  newMessages = false


  accessToken = undefined;
  chatToken = undefined;

  conversationClient = undefined;

  onboarding = {
    isActive: false,
    step: null,
  }

  onboardingIsCompleted = localStorage.getItem("onboardingCompleted")

  constructor() {
    makeObservable(this, {
      authenticated: observable,
      emailVerified: observable,
      profile: observable,
      identity: observable,
      accessToken: observable,
      chatToken: observable,
      signin: action,
      newNotifications: observable,
      newMessages: observable,
      unreadConversations: observable,
      onboarding: observable,
      onboardingIsCompleted: observable,
      setOnboardingState: action,
      setOnboardingStep: action
    })

    this.deserialize()

    this.authenticated = this.profile && this.accessToken
  }



  profileHasBeenUpdated() {
    const possibleUpdatedProps = [
      "address1",
      "address2",
      "bio",
      "birthday",
      "city",
      "country_code",
      "email_verified",
      "gender_id",
      "phone",
      "postal_code",
      "primary_holder",
      "primary_language_code",
      "profile_url",
      "state",
      "teaching_level_id",
      "teaching_subject"
    ];

    for (const prop of possibleUpdatedProps) {
      if (this.profile.currentUser.hasOwnProperty(prop) && this.profile.currentUser[prop]) {
        return true
      } else return false
    }
  }

  setOnboardingState(value) {
    this.onboarding.isActive = value

    if (value === false) {
      localStorage.removeItem("onboardingState")
      this.profile.currentUser.onboardingComplete = true
      this.serialize()
    }
    else {
      this.setOnboardingLocalStorage(this.onboarding)
      this.profile.currentUser.onboardingComplete = false
      this.serialize()
    }
  }

  setOnboardingStep(step) {
    this.onboarding.step = step
    this.setOnboardingLocalStorage(this.onboarding)
  }

  setOnboardingLocalStorage(onboardingState) {
    const data = JSON.stringify(
      toJS({
        ...onboardingState
      })
    )

    localStorage.setItem("onboardingState", data);
  }

  _setAuthState(accessToken, chatToken, account) {
    this.accessToken = accessToken
    this.chatToken = chatToken

    this.identity = {
      id: account.id,
      firstName: account.first_name,
      lastName: account.last_name,
      profileUrl: account.profile_url
    }

    this.profile = { currentUser: account }
    this.emailVerified = account.email_verified
    this.authenticated = true;
    this.newNotifications = false;
    this.serialize()
  }

  updateProfile(updated) {
    this.profile.currentUser.first_name = updated.firstName
    this.profile.currentUser.last_name = updated.lastName
    this.profile.currentUser.profile_url = updated.profileUrl

    this.identity = {
      firstName: updated.firstName,
      lastName: updated.lastName,
      profileUrl: updated.profileUrl
    }

    this.serialize()
  }

  signin = async (payload) => {
    try {
      const { data: { accessToken, account } } = await axios.post(`${BASE_URL}/api/auth/signin`, payload)
      this._setAuthState(accessToken, null, account)
      const chatToken = await conversation.getToken()
      this._setAuthState(accessToken, chatToken, account)
      return [account, null]
    } catch (e) {
      return [null, e]
    }
  };

  signup = async (payload) => {
    try {
      const { data: { accessToken, account } } = await axios.post(`${BASE_URL}/api/auth/signup`, payload)
      this._setAuthState(accessToken, null, account)
      const chatToken = await conversation.getToken()

      this._setAuthState(accessToken, chatToken, account)
      return [account, null]
    } catch (e) {
      return [null, e]
    }
  };

  updateConcersationToken = async () => {
    try {
      const chatToken = await conversation.getToken()
      this.chatToken = chatToken
      this.serialize()
    } catch (e) { }
  }

  logout = () => {
    localStorage.removeItem('_locker')
    window.location = '/'
  }

  serialize = () => {
    const { profile, identity, accessToken, chatToken, emailVerified } = this
    const serialized = JSON.stringify(toJS({ profile, identity, accessToken, emailVerified, chatToken }))

    localStorage.setItem('_locker', serialized)
  }

  deserialize = async () => {
    const serialized = localStorage.getItem('_locker')
    if (serialized) {
      try {
        const { profile, identity, accessToken, chatToken, emailVerified } = JSON.parse(serialized)

        this.accessToken = accessToken
        this.chatToken = chatToken
        this.identity = identity
        this.profile = profile
        this.emailVerified = emailVerified
      } catch (e) {
        console.warn('Failed to deserialize locker details', e)
      }
    }
  }
}

function padding(str) {
  const mod = str.length % 4
  const pad = 4 - mod

  if (mod === 0) {
    return str
  }

  return str + new Array(1 + pad).join('=')
}

function decodeToString(str) {
  str = padding(str)
    .replace(/-/g, '+') // Convert '-' to '+'
    .replace(/_/g, '/') // Convert '_' to '/'

  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

const locker = new Locker()
export default locker
