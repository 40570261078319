import React from 'react'

export default function Divider(props) {
  const { stroke = '#543aff' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="4"
      viewBox="0 0 15 4"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="square"
        d="M1 3L3.02.98l2.296 2.297L7.595.998 9.757 3.16l1.965-1.965 1.874 1.874"
      />
    </svg>
  )
}
