import React, { useEffect, useState } from 'react'
import { Button } from '../Button'

import { useFormik } from 'formik'
import { object, string } from 'yup'
import { useQuery } from '@apollo/client'
import { ACCOUNT_ALREADY_EXISTS } from '../../../gql_services/common'

import '../../../styles/onBoarding.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const AboutYourself = ({ onStepChange, form, setForm }) => {
  const [loading, setLoading] = useState(false)
  const { refetch } = useQuery(ACCOUNT_ALREADY_EXISTS, { skip: true })

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validationSchema: object().shape({
      firstName: string().required('First name is required'),
      lastName: string().required('Last name is required'),
      email: string().email('Invalid email').required('Email is required')
    }),
    onSubmit: async (values, { setErrors }) => {
      setLoading(true)

      const {
        data: { accountWithEmailAlreadyExists }
      } = await refetch({
        email: values.email
      })

      if (accountWithEmailAlreadyExists) {
        setLoading(false)
        return setErrors({
          email: 'User with this email already exists'
        })
      }

      setLoading(false)
      setForm({ ...form, ...values })
      onStepChange('WhereDoYouWork')
    }
  })

  useEffect(() => {
    if (form) {
      const updateFormik = {}

      if (form.firstName) updateFormik.firstName = form.firstName
      if (form.lastName) updateFormik.lastName = form.lastName
      if (form.email) updateFormik.email = form.email

      formik.setValues(updateFormik)
    }
  }, [])

  return (
    <>
      <div className="form-container">
        <div className="form-heading">
          <div className="step-title ">Tell us about you</div>
          <div className="form-stepper">
            <div className="step current" />
            <div className="step" />
            <div className="step" />
          </div>
          <div className="form-body">
            <form className="field-container" onSubmit={formik.handleSubmit}>
              <label className="label">FIRST NAME</label>
              <input
                id="firstName"
                name="firstName"
                type="firstName"
                className="inputContent"
                placeholder="First Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              <p>{formik.touched.firstName && formik.errors.firstName}</p>
              <br />
              <label className="label">LAST NAME</label>
              <input
                id="lastName"
                name="lastName"
                type="lastName"
                className="inputContent"
                placeholder="Last Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              <p>{formik.touched.lastName && formik.errors.lastName}</p>
              <br />
              <label className="label">EMAIL</label>
              <input
                id="email"
                name="email"
                type="email"
                className="inputContent"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <p>{formik.touched.email && formik.errors.email}</p>
              <br />
            </form>
          </div>
          <Button
            disabled={
              !formik.values.firstName ||
              !formik.values.lastName ||
              !formik.values.email ||
              loading
            }
            text={loading ? 'Loading...' : 'Continue'}
            state={loading ? 'loading' : null}
            onClick={formik.submitForm}
          />
        </div>
        <div className='links-container'>
          <p> Allready registered? </p>
          <Link to="/login" >Login</Link>
        </div>
      </div>
    </>
  )
}

export default AboutYourself
