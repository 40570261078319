import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Link, withRouter, useHistory } from 'react-router-dom'

import Divider from '../svg/divider'
import logo from '../../images/nticeLogo.png'
import GlobalSearch from '../search/globalSearch'
import * as qs from '../../query-string'

import Notifications from '../notifications/notifications'
import MessageCircle from '../svg/message-circle'
import AlertIcon from '../svg/alert'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { observable, makeObservable, action } from 'mobx'

import OnboardingIcon from './navOnboardingIcon'
import NavInbox from './navInbox'

const NotificationContainer = styled.span`
  display: block;
  position: relative;
`

const NotificationIcon = styled.div`
  position: absolute;
  right: -10px;
  top: -5px;
`

const TwoLinesNav = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BetaBadge = styled.div`
  display: flex;
  /*   align-self: flex-end; */
  font-size: x-small;
  text-align: center;
  margin-top: 6px;
  margin-right: 3px;
  background-color: #ffca00;
  padding: 3px;
  border-radius: 5px;
  cursor: default;
`

@inject('locker', 'search', 'uiStore')
@withRouter
@observer
class Navbar extends Component {
  showNotification = false
  isMobile = window.innerWidth < 768

  constructor(props) {
    super(props)

    makeObservable(this, {
      showNotification: observable,
      isMobile: observable,
      updateIsMobile: action
    })
    const { search, location } = this.props
    const criteria = qs.parse(location.hash)
    search.updateQuery({
      name: criteria.w
    })
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const criteria = qs.parse(this.props.location.hash)
    this.props.search.updateQuery({
      name: criteria.w
    })
  }

  handleResize = () => {
    this.updateIsMobile(window.innerWidth)
  }

  updateIsMobile(width) {
    this.isMobile = width <= 768
  }

  updateSearchUrl = ({ location, startDate, endDate, guests }) => {
    const { history } = this.props

    history.push({
      pathname: '/search',
      hash: qs.stringify({
        w:
          location.city && location.state
            ? `${location.city}, ${location.state}`
            : location.placeName
      })
    })
  }

  conditionalGlobalSearch = (query, location, locker) => {
    if (location.pathname !== '/') {
      return (
        <GlobalSearch
          initial={query}
          onSearch={(query) => this.updateSearchUrl(query)}
          isAuthenticated={locker.authenticated}
        />
      )
    }

    if (location.pathname === '/' && this.props.uiStore.searchOnNavbar) {
      return (
        <GlobalSearch
          initial={query}
          onSearch={(query) => this.updateSearchUrl(query)}
          isAuthenticated={locker.authenticated}
        />
      )
    } else {
      return null
    }
  }

  appLayout = (
    unreadNotifications,
    locker,
    query,
    children,
    toggleMenu,
    location,
    unreadMessages
  ) => {
    const logoFragment = (
      <>
        <Link className="logo" to="/">
          <img src={logo} alt="" />
        </Link>
        <BetaBadge data-tip data-for="badge">
          Beta
        </BetaBadge>
        <ReactTooltip id="badge" place="bottom" effect="solid">
          Currently using the Beta version of N'TICE – Stick around to witness
          ongoing improvements to our platform!
        </ReactTooltip>
      </>
    )

    if (location.pathname == '/login' || location.pathname == '/join' || location.pathname == '/welcome' || location.pathname == '/verify')
      return (
        <div className="container container--flex container--flex__centered">
          <div className="logo-search-wrapper login">{logoFragment}</div>
        </div>
      )
    else
      return (
        <div className="container container--flex container--flex__centered">
          <div className="logo-search-wrapper">
            {logoFragment}
            {locker.authenticated &&
              this.conditionalGlobalSearch(query, location, locker)}
          </div>
          {locker.authenticated && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
                gap: '10px',
                marginRight: '5px',
                paddingLeft: '5px'
              }}
            >

              <OnboardingIcon />

              <NavInbox />

              <div data-tip data-for="notifications">
                <button
                  className="nav-button-icon"
                  onClick={() =>
                    (this.showNotification = !this.showNotification)
                  }
                >
                  <NotificationContainer>
                    <AlertIcon />
                    {unreadNotifications && (
                      <NotificationIcon>
                        <MessageCircle />
                      </NotificationIcon>
                    )}
                  </NotificationContainer>

                  <ReactTooltip
                    id="notifications"
                    place="bottom"
                    effect="solid"
                  >
                    View Notifications
                  </ReactTooltip>
                </button>
                <Notifications
                  isOpen={this.showNotification}
                  onClose={() => (this.showNotification = false)}
                />
              </div>
            </div>
          )}

          {children}

          <div className="toggle-menu" onClick={toggleMenu}>
            <div>Menu</div>
            <div>
              <div>
                <Divider stroke="#909fb2" />
              </div>
              <div>
                <Divider stroke="#909fb2" />
              </div>
            </div>
          </div>
        </div>
      )
  }

  mobileLayout = (
    unreadNotifications,
    locker,
    query,
    children,
    toggleMenu,
    location,
    unreadMessages
  ) => {

    const logoFragment = (
      <>
        <Link className="logo" to="/">
          <img src={logo} alt="" />
        </Link>
        <BetaBadge data-tip data-for="badge">
          Beta
        </BetaBadge>
        <ReactTooltip id="badge" place="bottom" effect="solid">
          Currently using the Beta version of N'TICE – Stick around to witness
          ongoing improvements to our platform!
        </ReactTooltip>
      </>
    )

    if (location.pathname == '/login' || location.pathname == '/join' || location.pathname == '/welcome' || location.pathname == '/verify')
      return (
        <div className="container container--flex container--flex__centered">
          <div className="logo-search-wrapper login">{logoFragment}</div>
        </div>
      )
    else
      return (
        <TwoLinesNav>
          <Row>
            <div className="logo-search-wrapper">
              <Link className="logo" to="/">
                <img src={logo} alt="" />
              </Link>
              <BetaBadge>Beta</BetaBadge>
            </div>

            <Row>
              {locker.authenticated && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    gap: '10px',
                    marginRight: '12px'
                  }}
                >

                  <OnboardingIcon />

                  <NavInbox />

                  <button
                    className="nav-button-icon"
                    onClick={() =>
                      (this.showNotification = !this.showNotification)
                    }
                  >
                    <NotificationContainer>
                      <AlertIcon />
                      {unreadNotifications && (
                        <NotificationIcon>
                          <MessageCircle />
                        </NotificationIcon>
                      )}
                    </NotificationContainer>
                  </button>
                  <Notifications
                    isOpen={this.showNotification}
                    onClose={() => (this.showNotification = false)}
                  />
                </div>
              )}

              {children}
              <div
                className="toggle-menu"
                style={{ marginBottom: '5px' }}
                onClick={toggleMenu}
              >
                <div>Menu</div>
                <div>
                  <div>
                    <Divider stroke="#909fb2" />
                  </div>
                  <div>
                    <Divider stroke="#909fb2" />
                  </div>
                </div>
              </div>
            </Row>
          </Row>

          <div style={{ display: 'block' }}>
            <div>
              {locker.authenticated &&
                this.conditionalGlobalSearch(query, location, locker)}
            </div>
          </div>
        </TwoLinesNav>
      )
  }

  render() {
    const unreadNotifications = this.props.locker?.newNotifications
    const unreadMessages = this.props.locker?.newMessages
    const { children, toggleMenu, locker, location } = this.props
    const { query } = this.props.search
    return (
      <div className="navigation">
        {this.isMobile
          ? this.mobileLayout(
            unreadNotifications,
            locker,
            query,
            children,
            toggleMenu,
            location,
            unreadMessages
          )
          : this.appLayout(
            unreadNotifications,
            locker,
            query,
            children,
            toggleMenu,
            location,
            unreadMessages
          )}
      </div>
    )
  }
}

export default Navbar
