import React from 'react'
import { BsBookmarkCheck } from "react-icons/bs";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const blogPosts = [
  {
    id: 1,
    title: "N’TICE the Newest of Airbnb Competitors Just for Teachers",
    content: "There are a few different Airbnb competitors out there, but this one is truly unique for a few different reasons",
    imageUrl: "https://meghanthetravelingteacher.com/wp-content/uploads/2024/03/Traveling-Teacher-Logo-1024x476.jpg",
    url: "https://meghanthetravelingteacher.com/ntice-the-newest-of-airbnb-competitors-just-for-teachers/"
  },
  {
    id: 2,
    title: "37 Teacher Travel Grants: Free Travel Opportunities for Teachers",
    content: "Did you know that there are numerous fellowship and grant programs that allow teachers to travel for FREE? Keep reading to learn more!",
    imageUrl: "https://i0.wp.com/travelingteachergirl.com/wp-content/uploads/2020/12/Free-Travel-for-Teachers-Featured-Image-1.png?resize=745%2C1024&ssl=1",
    url: "https://travelingteachergirl.com/free-travel-opportunities-for-teachers/"
  },
  {
    id: 3,
    title: "16 Must-Have Items to Survive in The Amazon Rainforest",
    content: "Hot, humid and full of bugs, packing for a visit to the Jungle is not easy.",
    imageUrl: "https://i0.wp.com/thetravellingteacher.co.uk/wp-content/uploads/2023/09/diego-guzman-u_4bPYOXujE-unsplash-scaled.jpg?resize=1024%2C683&ssl=1",
    url: "https://thetravellingteacher.co.uk/16-must-have-items-to-survive-in-the-amazon-rainforest/"
  },
]
function RecommendedPosts() {
  return (
    <article className="recommended-posts-container">
      <section>
        <h5>
          <BsBookmarkCheck />
          <span>Recommend blog post</span>
        </h5>
      </section>
      <section className="posts-container">
        {blogPosts.map((post) => (
          <a key={`post ${post.id}`} href={post.url}>
            <article className="recommended-post" key={post.id}>
              <article className="image-container">
                <img src={post.imageUrl} alt={post.title} />
              </article>
              <article className="body-container">
                <h5>{post.title}</h5>
                <p>{post.content}</p>
              </article>
            </article>
          </a>
        ))}
      </section>
    </article>
  )
}
export default RecommendedPosts