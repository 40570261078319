// TODO: evenutally pull this from the db
export const pricingTiers = async () => {
  return [
    {
      id: 3,
      tier: 'silver',
      name: 'Silver',
      description: 'Travel to and enjoy unlimited stays within your country.',
      price: '$49.99',
      regionRestricted: false,
      countryRestricted: true
    },
    {
      id: 1,
      tier: 'gold',
      name: 'Gold',
      description:
      'Travel to and enjoy unlimited stays within your region, plus one additional adjoining region.',
      price: '$149.99',
      monthly: '$5.17',
      regionRestricted: true
    },
    {
      id: 2,
      tier: 'platinum',
      name: 'Platinum',
      description:
        'Travel to and enjoy unlimited stays around the world. Your only limit is your imagination.',
      price: '$249.99',
      regionRestricted: false
    },
  ]
}
