import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import '../styles/privacy-terms.scss'

export default class FoundationPage extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).parentElement.parentElement.scrollTop = 0
  }

  render() {
    return (
      <div className="why page">
        <div className="hero hero--small">
          <div className="hero-container">
            <div className="title">
              <h1>N'TICE Education Foundation (NEF)</h1>
              <div className="subtitle">(Beginning in Fall of 2020)</div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="page-container">
            <div className="sections">
              <div className="section">
                <h2>Mission</h2>
                <div className="box">
                  <p>
                    To improve the learning experience for children across the
                    globe by upgrading learning facilities, bringing educational
                    and technological resources to schools; providing
                    scholarships for students, and professional development for
                    educators.
                  </p>
                  <p>
                    The <abbr title="N'TICE Education Foundation">NEF</abbr>{' '}
                    board, comprised of members who are educators, will be
                    responsible for carrying out the purposes of the mission.
                  </p>
                </div>
              </div>

              <div className="section">
                <h2>How does it work?</h2>
                <div className="box">
                  <p>
                    Educational leaders log on to the{' '}
                    <abbr title="N'TICE Education Foundation">NEF</abbr>{' '}
                    website, and select one or more of four separate
                    applications:
                  </p>

                  <ol className="indented">
                    <li>Scholarships for students</li>
                    <li>Resources for learning</li>
                    <li>Professional development</li>
                    <li>Facilities for schools</li>
                  </ol>

                  <p>
                    Those applications must be submitted by November 30th of
                    each year, with the winners being announced March 30th of
                    the following year. The persons and/or schools who are not
                    awarded funds, are encouraged to re-apply. The{' '}
                    <abbr title="N'TICE Education Foundation">NEF</abbr> board
                    will be happy to provide feedback to assist in the
                    re-application process.
                  </p>

                  <p>
                    The foundation is a project that N'TICE intends to undertake
                    in the future and it does not exist at present time. N'TICE
                    has the intention of launching the N'TICE Education
                    Foundation beginning in the Fall of 2019.
                  </p>
                </div>
              </div>

              <div className="section">
                <h2>
                  How will I know where my money is going and what it is being
                  used for?
                </h2>
                <div className="box">
                  <p>
                    There are two places where one can view the amount of funds
                    donated by N’TICE Inc. and awarded by the{' '}
                    <abbr title="N'TICE Education Foundation">NEF</abbr>.
                  </p>

                  <p>
                    First, donation information will be displayed on the
                    personal profile page of each N’TICE member, reflecting a
                    percent of the annual fee donated to{' '}
                    <abbr title="N'TICE Education Foundation">NEF</abbr>. This
                    percentage may vary from year to year due to{' '}
                    {/* eslint-disable-line */}
                    the donation amount received by N’TICE Inc.
                  </p>

                  <p>
                    Second, to view how contributions are allocated, log on to
                    the website, which makes public the use of donations awarded
                    by N’TICE Inc., showcasing the work of the foundation’s
                    mission throughout the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
