import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="page-not-found">
      <h1>Page does not exist</h1>

      <Link to="/" className="button primary">
        Find your next adventure
      </Link>
    </div>
  )
}

export default NotFound
