import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import logo from './images/nticeLogo.png'
import Divider from './components/svg/divider'
import Navbar from './components/navigation/navbar'
import Menu from './components/navigation/menu'
import './styles/layout.scss'

/* import Notifications from './components/notifications/notifications'
import MessageCircle from './components/svg/message-circle'
import AlertIcon from './components//svg/alert'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { inject, observer } from 'mobx-react'
import { toJS, observable, makeObservable, action } from 'mobx'

const NotificationContainer = styled.span`
  display: block;
  position: relative;
`

const NotificationIcon = styled.div`
  position: absolute;
  right: -10px;
  top: -5px;
  ` */

/* @inject('locker')
@observer */
@withRouter
class Layout extends Component {
  showNotification = false
  constructor() {
    super()
    /*    const totalUnread = this.locker?.newNotifications
       makeObservable(this, {
         showNotification: observable,
       }) */

    this.state = {
      showMenu: false
    }
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {
    const currentPath = this.props.location.pathname

    const { children } = this.props
    const footerYear = new Date().getFullYear()
    const isInbox = this.props.location.pathname.startsWith('/inbox')

    return (
      <div className={this.state.showMenu ? 'show-menu' : ''}>
        <div className="mobile-menu">
          <span className="pointer" onClick={this.toggleMenu}>
            Close
          </span>
          <div className="mobile-nav-container">
            <Menu mobile />
          </div>
        </div>

        <div id="app-container" className={isInbox ? 'overflow-hidden' : undefined}>
          <Navbar toggleMenu={this.toggleMenu}>
            <Menu />
          </Navbar>
          <div className="main">{children}</div>

          <div
            className={`footer ${isInbox && 'hidden'} ${currentPath === '/login' && 'hidden'
              } ${currentPath === '/join' && 'hidden'}
             ${currentPath === '/welcome' && 'hidden'
              }
              ${currentPath === '/verify' && 'hidden'
              }
              ${currentPath === '/forgot' && 'hidden'}
              ${currentPath === '/reset' && 'hidden'
              }
            `
            }
          >
            <div className="container">
              <div className="logo-search-wrapper">
                <Link className="logo" to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <div>
                <ul>
                  <li className="list-title">N'TICE</li>
                  <li>
                    <Divider />
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://medium.com/@ntice"
                    >
                      Blog
                    </a>
                  </li>
                  {/* <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/ntice.me/"
                    >
                      Instagram
                    </a>
                  </li> */}
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/NTICE-944963622329468/"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/nticeinc"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCG37AmWmEJ61GJljFDYDYeg"
                    >
                      YouTube
                    </a>
                  </li>
                </ul>
                <ul>
                  <li className="list-title">SUPPORT</li>
                  <li>
                    <Divider />
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                </ul>
                <ul>
                  <li className="list-title">SECURITY & PRIVACY</li>
                  <li>
                    <Divider />
                  </li>
                  <li>
                    <Link to="/terms">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`post-footer section ${isInbox && 'hidden'} ${currentPath === '/login' && 'hidden'
              } ${currentPath === '/join' && 'hidden'}
             ${currentPath === '/welcome' && 'hidden'}
             ${currentPath === '/verify' && 'hidden'
              }
              ${currentPath === '/forgot' && 'hidden'}
               ${currentPath === '/reset' && 'hidden'
              }
            `}
          >
            ©Copyright {footerYear} N’TICE. All Rights Reserved
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Layout)
