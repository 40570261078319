import React, { useEffect, useState } from 'react'
import { IoMdArrowBack } from 'react-icons/io'
import { IconContext } from 'react-icons'
import { useHistory } from 'react-router-dom'
import { Button } from './Button'
import { inject } from 'mobx-react'
import '../../styles/onBoarding.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import styled from "styled-components"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const InputWrapper = styled.div`
  position: relative;
  width: 100%
`
const EyeIcon = styled.button`
  background: none;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
`

const LogIn = ({ locker }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [showError, setShowError] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    const [_, error] = await locker.signin({ email, password })

    if (!error) {
      history.push('/')
    } else {
      setShowError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    setShowError(false)
  }, [email, password])

  return (
    <div className="onboarding-wrapper">
      <div className="back-nav" onClick={() => history.push('/')}>
        <IconContext.Provider value={{ size: '24px' }}>
          <IoMdArrowBack />
        </IconContext.Provider>
        Back
      </div>

      <div className="form-container">
        <div className="form-heading">
          <div className="step-title ">Log In</div>
        </div>
          <div className="form-body">
            <div className="field-container">
              <label className="label">Email</label>
              <input
                className="inputContent"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <br />
              <label className="label">Password</label>
              <InputWrapper>
                <input
                  type={passwordVisibility ? "text" : "password"}
                  className="inputContent"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
                <EyeIcon className='eye-icon' onClick={() => setPasswordVisibility((prevState) => !prevState)}>
                  {passwordVisibility ? <FaRegEyeSlash /> : <FaRegEye />}
                </EyeIcon>
              </InputWrapper>
              {showError && <p>Invalid email or password</p>}
            </div>
          </div>
          <br />
          <Button
            disabled={loading}
            onClick={onSubmit}
            text={loading ? 'Loging In...' : 'Log In'}
            state={loading ? 'loading' : null}
          />
        <div className='links-container column'>
            <Link to="/forgot" >Forgot your password?</Link>
            <Link to="/join" >Create a new account</Link>
        </div>
      </div>
    </div>
  )
}

export default inject('locker')(LogIn)
