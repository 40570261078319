import React from 'react'

export default function MessageCircle(props) {
  const { fill = '#543aff', x = '6', y = '6' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={x}
      height={y}
      viewBox="0 0 10 10"
    >
      <circle cx="50%" cy="50%" r="5" fill={fill} />
    </svg>
  )
}
