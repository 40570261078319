import React, { Component } from 'react'
import { PageLoading } from '../loader'

export default class Auth0LoginCallback extends Component {
  render() {
    return (
      <div className="login-loading">
        <PageLoading />
      </div>
    )
  }
}
