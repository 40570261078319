import { gql } from '@apollo/client'

export const COUNTRIES_LIST = gql`
  query Countries {
    countries {
      id
      country
      alpha2Code
    }
  }
`

export const LANGUAGES_LIST = gql`
query Query {
  languages {
    id
    language
    alpha2Code
    alpha3Code
  }
}

`

export const ACCOUNT_ALREADY_EXISTS = gql`
  query AccountWithEmailAlreadyExists($email: String!) {
    accountWithEmailAlreadyExists(email: $email)
  }
`
