import React, { Component } from 'react'

export default class TextArea extends Component {
  render() {
    const {
      name,
      placeholder,
      required,
      label,
      small,
      value,
      errorMessage,
      onInvalid,
      onChange
    } = this.props

    return (
      <div className={`form-control ${errorMessage ? 'error' : ''}`}>
        <label htmlFor={name}>{label}</label>
        {small && <small>{small}</small>}
        <textarea
          id={name}
          key={name}
          onInvalid={onInvalid}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
        />
        {errorMessage && <span className="error-text">{errorMessage}</span>}
      </div>
    )
  }
}
