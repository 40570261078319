import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import { Terms } from '../components/svg/privacy-terms'
import '../styles/privacy-terms.scss'

const content = [
  {
    menu: 'Welcome',
    title: 'Welcome',
    children: [
      <p key="1">
        Welcome to N’TICE, a website for teachers around the world to experience
        the best there is to travel through connections with other teachers (the
        “Service”). The Service is provided by N’TICE, Inc. (“N’TICE” or “Us”,
        “We”). This document is the Terms of Use (“Terms”). The Terms are the
        fine print that govern your relationship to Us as you use our website
        (the “Site”) and Service. As such, you should read these Terms very
        carefully, as they will determine how you can use the Site and Services,
        how We are (and are not) liable to you, what responsibilities fall on
        you and which fall on Us, and other key terms and obligations, subject
        to each term and obligation being valid in your country.
      </p>,
      <p key="2">
        By using our Site and Services, you hereby agree to these Terms. In
        other words, before you begin using the Site and book your next great
        adventure, prepare a nice cup of coffee (or beverage of choice) and
        carefully read through these Terms. If you have any questions, write to
        Us! If you do not agree with any particular provision or term, then STOP
        and do not click on the “I Agree” button below. We want you to feel
        comfortable using our Site, but We can only do so when both you and Us
        agree on the terms that will control this new adventure.
      </p>,
      <p key="3">
        We reserve the right to edit and change these Terms from time to time.
        When We do so, We shall upload updated Terms on our Site, which will
        become effective from the day they are published. If following the
        publishing of updated Terms, you continue to use the Site, then you will
        have accepted the new Terms. Thus, We encourage you to visit this
        section of the Site frequently in order to learn about any changes to
        these Terms.
      </p>
    ]
  },
  {
    menu: 'Big Picture',
    title: 'Big Picture',
    children: [
      <p key="1">
        This is a Site for teachers to travel the world, and to do so by using
        the homes of fellow teachers in other countries or cities. By using this
        Site, you will create an account (“Account”) and become a member
        (“Member”). Registering on the Site will require that you select one of
        four types of membership (“Membership”):
      </p>,
      <ul key="2">
        <li>Platinum</li>
        <li>Gold</li>
      </ul>,
      <p key="3">
        Each Membership will be unique as to who can purchase it and what
        benefits it includes. You may be prompted to enter additional
        information to confirm your eligibility to each given Membership upon
        creating your Account. With your Membership, you will be able to stay at
        another Member’s property without making any additional payments.
        However, We do not own the properties where you will stay at, nor do We
        control what happens during your stay. That will be between you and the
        fellow Member at whose property you will stay at. We are merely a
        conduit between Members who seek to have unique life experiences by
        staying at the homes of fellow Members.
      </p>,
      <p key="4">
        The Site is an interactive platform where Members will provide Us with
        certain personal information, which We will use in accordance with our
        Privacy Policy. It is very important that you read our Privacy Policy
        (which is a separate document) so that you understand how your data and
        activity within the Site is used, managed, and as appropriate, shared by
        Us. .
      </p>
    ]
  },
  {
    menu: 'Memberships',
    title: 'Membership',
    children: [
      <p key="1">
        Members must be twenty-one (21) years or older, or have at least the
        minimum age of consent as applicable in your respective country or
        jurisdiction. When you create your Account, you must use your full and
        legal name. You will also be required to submit certain personal
        information (including, but not limited to, an email address, phone
        number, and mailing address). You will also be required to show proof of
        your particular teaching employment, depending on what type of
        Membership you select. Please refer to our Privacy Policy for how We
        manage and use (or not use) this information.
      </p>,
      <p key="2">
        If you provide Us with all of the required information, you will then be
        prompted to choose a Membership to use with your Account. Members can
        choose from one of four Memberships, all of which will depend on how
        many days you make your property (“Property”) available to other Members
        (“Nticement”) and what geographical regions you want to travel to.
        Specifically, Gold and Gold Plus Memberships are for Members who only
        wish to travel within adjacent geographical regions, while Platinum and
        Platinum Plus Members can travel to any part of the world.
      </p>,
      <p key="3">
        We will invoice your Membership fee (“Fee”) upon the successful creation
        of your Account and charge the credit card you provided when you created
        your Account, and will automatically renew your Membership every
        calendar year (“Membership Term”) to said credit card unless you cancel
        your Account prior to the last day before the Membership Term finishes.
      </p>,
      <p key="4">
        We reserve the right to change our Fees and/or Memberships. However, We
        will honor the terms of your Membership as it pertains only to Fees for
        the remainder of the Membership Term, with the new Fees to come into
        effect upon the renewal of the Membership Term.
      </p>,
      <p key="5">
        We reserve the right, but have no obligation, to: (1) request that
        Members provide Us with a copy of a government issued identification;
        (2) perform background checks on Members for criminal conviction or
        sexual offender registries; (3) obtain public records from the Member’s
        jurisdiction; or (4) conduct any other reasonable inquiry related to our
        Members.
      </p>,
      <p key="6">
        You are limited to creating one (1) Account on our Site. If We identify
        that you have created a second Account parallel to your first Account,
        We reserve the right to suspend both Accounts until further notice.
      </p>
    ]
  },
  {
    menu: 'Uptime',
    title: 'Uptime: Keeping the Site going',
    children: [
      <p key="1">
        We will do our best to make sure you have the best experience possible
        when visiting our Site and using Our Services. However, the Internet
        (and the providers who keep Us connected) can experience delays,
        downtime, or interruptions from time to time. Therefore, you acknowledge
        that, although the Internet is often a secure environment, sometimes
        there are interruptions in Service or events that are beyond Our
        control, and that We shall not be responsible for any data lost while
        transmitting information on the Internet. While Our objective is to make
        the Site accessible 24 hours per day, 7 days per week, the Site may be
        unavailable from time to time for any reason including, without
        limitation, routine maintenance. You understand and acknowledge that due
        to circumstances both within and outside of Our control, access to the
        Site may be interrupted, suspended or terminated from time to time.
      </p>,
      <p key="2">
        We shall have the sole right at any time to change or discontinue any
        aspect or feature of the Site, including, but not limited to, content,
        hours of availability and equipment needed for access or use. Further,
        We may discontinue disseminating any portion of information or category
        of information, may change or eliminate any transmission method and may
        change transmission speeds or other signal characteristics.
      </p>
    ]
  },
  {
    menu: 'Nticement',
    title: 'Nticement: Making your property available',
    children: [
      <p key="1">
        When you make your Property available to other Members (Nticement), the
        Property should be in hospitable conditions and suitable for personal
        living (i.e., the premises must be clean and safe). You must create a
        listing (“Listing”) on the Site and include at least three (3) well
        light pictures showing the Property, both from the exterior as well as
        the interior. Any media you submit for your Listing must meet the
        minimal requirements that We will set forth on the Site from time to
        time (i.e., size, minimal resolution, file format, etc.). You may set
        certain conditions for Members to stay at your Listing, such as
        limiting/prohibiting the consumption of alcohol, tobacco products, or
        controlled substances (if they are legal in your jurisdiction). You may
        also require a reasonable cleaning fee, which you must set forth in your
        Listing. If We, at our sole and absolute discretion, consider the
        cleaning fee to be excessive, We will disable your Listing until you
        request an adequate amount for said cleaning fee.
      </p>,
      <p key="2">
        Remember, having a fellow teacher (and Member) at your Property requires
        placing trust on each other, so be truthful in your pictures. In your
        Listing, describe what will be made available to fellow Members (how
        many bathrooms, type of kitchen, amenities, etc.). By making a Listing,
        you represent and warrant that you are in compliance with any applicable
        law, ordinance, regulation or rule, home owner association or
        condominium association rule, contract or lease that would otherwise
        forbid your Listing or your participation on this Site. If We receive
        information that you have materially misrepresented the Property in your
        Listing, We reserve the right to terminate your Listing, and at our own
        judgment, your Account.
      </p>,
      <p key="3">
        Be mindful that We offer this Site to Members around the world.
        Therefore, We may use machine translations (such as Google Translate) to
        translate your Listing to the native language of a fellow Member. We
        cannot guarantee the veracity or correctness of that translation and We
        disclaim all warranties related, directly or indirectly, to that
        translation.
      </p>,
      <p key="4">
        Depending on your Membership, you must make your Property available for
        other Members to stay in during your Membership Term. You will satisfy
        your Nticement requirement by making the Property available through a
        Listing, regardless of whether or not you have Members stay in your
        Property for the total amount of Nticement per your Membership.
      </p>
    ]
  },
  {
    menu: 'Interacting with other Members',
    title: 'Staying at a Members Property and Interacting with other Members',
    children: [
      <p key="1">
        The Site will allow you to view the Listings of fellow Members, and the
        date of availability for said Listings. By making a reservation for
        accommodation (“Reservation”), you enter into a good-faith agreement
        with the Member making the Listing to stay at the Property on the date
        selected. We are not a party to that Reservation, nor do We have no
        responsibility to either party as to securing the Reservation. You have
        sole responsibility when interacting with other Members. Our Services
        provide a platform for Members to arrange stays and travel, engage in
        activities and communicate with one another. We are not a party to, have
        no involvement or interest in, make no representations or warranties as
        to, and have no responsibility or liability with respect to any
        communications, transactions, interactions, disputes or any relations
        whatsoever between you and any other Member. You are solely responsible
        for your interactions with other Members of Our Services. We reserve the
        right, but have no obligation, to monitor interactions between you and
        other Members of our Services. WE WILL NOT CARRY OUT AN INSPECTION OF
        ANY OF OUR MEMBER’S PROPERTY(IES), NOR CAN WE GUARANTEE THAT THE
        PROPERTY YOU SEE ON OUR SITE IS THE SAME ONE YOU WILL VISIT.
      </p>,
      <p key="2">
        Because our Services and Site are merely a platform, in the event that
        you have a dispute with one or more Members, to the fullest extent
        permitted by applicable law you release Us (and our officers, directors,
        members, employees, agents and affiliates) from claims, demands and
        damages (actual and consequential) of every kind and nature, known and
        unknown, suspected and unsuspected, disclosed and undisclosed, arising
        out of or in any way connected with such disputes. If you are a
        California resident, you waive California Civil Code 1542, which says:
        “A general release does not extend to claims which the creditor does not
        know or suspect to exist in his favor at the time of executing the
        release, which if known by him must have materially affected his
        settlement with the debtor.”
      </p>
    ]
  },
  {
    menu: 'Member Surveys',
    title: 'Member Surveys: What happens after you complete your stay',
    children: [
      <p key="1">
        After Members conclude their stay at a Listing, each of the hosting
        Member and the visiting Member must complete a survey as to the
        Reservation and their experience dealing with the other Member. Each
        Member must provide a truthful account or review of the other Member
        (the hosting Member shall rate the visiting Member as to what kind of
        guest that Member was, and the visiting Member shall rate the condition
        of the Property and his or her experience dealing with the hosting
        Member) (the “Review”). Each Member is solely and entirely responsible
        for the content of their Review. N’TICE will not review, approve, edit,
        or in any way intervene with any Review unless another Member reports a
        given Review as inaccurate. Upon receiving a notification from a Member
        that a particular Review about said Member is inaccurate, We will
        examine the Review and contact the authoring Member to try and work out
        a reasonable resolution to the investigation.
      </p>,
      <p key="2">
        Further, We reserve the right to suspend or terminate a Member’s Account
        if said Member receives repeated negative Reviews. Each Review will
        determine an overall rating for each Member. If a Member’s overall
        rating falls below a threshold, to be determined by Us from time to time
        in our sole discretion, then We reserve the right to suspend or
        terminate said Account. No Fees shall be refunded by any termination due
        to negative Reviews.
      </p>
    ]
  },
  {
    menu: 'Intellectual Property',
    title: 'Intellectual Property: What happens to the content that you upload',
    children: [
      <p key="1">
        Depending on your country or jurisdiction, the content and media that
        you create and upload to the Site may be protected by intellectual
        property legislation. In the United States of America, any original
        content that you create and upload may be protected under the Copyright
        Act of 1979 (as amended). So, for Us to publish your Listing, We need a
        license from you in order to reproduce, distribute, adapt, publicly
        display, publicly perform (as applicable), and/or modify in any way
        necessary your copyrightable content (“Member Content”). Accordingly, by
        agreeing to these Terms, you agree to give Us a perpetual (forever),
        non-exclusive, irrevocable, transferable, sub-licensable, worldwide
        license to reproduce, distribute, adapt, publicly display, publicly
        perform (as applicable), and/or modify in any way the Member Content you
        submit to the Site (through a file upload, sharing, posting on the Site,
        or in any other way in which you make available your Member Content on
        the Site). You will always retain ownership of your Member Content, and
        We will retain your permission to use your Member Content in order to
        make your Listing available on the Site, and to otherwise improve your
        experience on the Site.
      </p>,
      <p key="2">
        Likewise, our Site, both in its components and in its entirety (the
        “Platform”), its logos and trademarks (“Trademarks”), images and content
        (“NTICE Content”), is protected by both applicable copyright and
        trademark legislation (as applicable). We will grant you a limited,
        non-exclusive, non-sublicensable, revocable, non-transferable license to
        (i) download and use the Platform on your personal device(s); and (ii)
        access and view any NTICE Content made available on or through the
        Platform and accessible to you, solely for your personal and
        non-commercial use. You will not remove, alter or obscure any copyright,
        Trademarks or other proprietary rights notices incorporated in or
        accompanying the Platform or NTICE Content. You will not use, copy,
        adapt, modify, prepare derivative works of, distribute, license, sell,
        transfer, publicly display, publicly perform, transmit, broadcast or
        otherwise exploit the Platform or NTICE Content, except to the extent
        you are the legal owner of certain Member Content or as expressly
        permitted in these Terms. No licenses or rights are granted to you by
        implication or otherwise under any intellectual property rights owned or
        controlled by Us or its licensors, except for the licenses and rights
        expressly granted in these Terms.
      </p>,
      <p key="3">
        Be aware that you are solely responsible for all Member Content that you
        make available on or through the Site. Any Member Content you upload
        should be yours, or in the alternative, you must have permission from
        the author to use it. Merely finding an image through a web search does
        not give you any rights to use that image. Accordingly, you represent
        and warrant that: (i) you either are the sole and exclusive owner of all
        Member Content that you make available on or through the Site or you
        have all rights, licenses, consents and releases that are necessary to
        grant to Us the rights in and to such Member Content, as contemplated
        under these Terms; and (ii) neither the Member Content nor your posting,
        uploading, publication, submission or transmittal of the Member Content
        or Our use of the Member Content (or any portion thereof) will infringe,
        misappropriate or violate a third party's patent, copyright, trademark,
        trade secret, moral rights or other proprietary or intellectual property
        rights, or rights of publicity or privacy, or result in the violation of
        any applicable law or regulation.
      </p>,
      <p key="4">
        In order for you to share our Site through hyperlinks, you are granted a
        limited, non-exclusive right to create a text hyperlink to the Site,
        provided such link does not portray Us or any of our Services in a
        false, misleading, derogatory or otherwise defamatory manner and
        provided further that the linking site does not contain any adult or
        illegal material or any material that is offensive, harassing or
        otherwise objectionable. This limited right may be revoked at any time.
        You may not use Our Trademarks to link to the Services without the
        express written permission of Us. Further, you may not use, frame or
        utilize framing techniques to enclose any of Our Trademarks, NTICE
        Content, other proprietary information, including the images found on
        the Services, the content of any text or the layout/design of any page
        or form contained on a page of the Services or Platform without Our
        express written consent. Except as noted above, you are not conveyed any
        right or license by implication, estoppel or otherwise in or under any
        patent, trademark, copyright or other proprietary right of N’TICE or any
        third party.
      </p>,
      <p key="5">
        If you believe that your Member Content, or any content you may own, has
        been uploaded to the Site without your authorization, please notify Us
        immediately in accordance with our Copyright Policy.
      </p>
    ]
  },
  {
    menu: 'Prohibited Activity',
    title: 'Prohibited Activity',
    children: [
      <p key="1">
        In order to keep a healthy community in our Site, by accepting this
        Terms you hereby agree that you will not:
      </p>,
      <ul key="2">
        <li>
          Charge, request, invoice, or demand any type of payment or
          consideration from a fellow Member in exchange for staying at your
          Property, with the exception of a reasonable cleaning fee, which must
          be set forth in your Listing (and to which We retain the sole right to
          review, approve, or reject);
        </li>
        <li>
          Breach or circumvent any applicable laws or regulations, agreements
          with third-parties, third-party rights, or our Terms, policies or
          standards (as they may be made public from time to time);
        </li>
        <li>
          Use the Site or NTICE Content for any commercial or other purposes
          that are not expressly permitted by these Terms or in a manner that
          falsely implies our endorsement, partnership or otherwise misleads
          others as to your affiliation with Us;
        </li>
        <li>
          Copy, store or otherwise access or use any information, including
          personally identifiable information about any other Member, contained
          on the Site in any way that is inconsistent with our Privacy Policy or
          these Terms or that otherwise violates the privacy rights of Members
          or third parties;
        </li>
        <li>
          Offer, as an Nticement any Property that you do not yourself own or
          have permission to make available as a residential or other property
          through the Site;
        </li>
        <li>
          Unless We explicitly permit otherwise, book any Listing if you will
          not actually be using yourself;
        </li>
        <li>
          Stay at a Member’s Property, without said Member’s consent, for any
          additional time other than the one set forth in your Reservation;
        </li>
        <li>
          Contact another Member for any purpose other than asking a question
          related to your Listing, or the Member's use of the Site, including,
          but not limited to, recruiting or otherwise soliciting any Member to
          join third-party services, applications or websites, without our prior
          written approval;
        </li>
        <li>
          Discriminate against or harass anyone on the basis of race, national
          origin, religion, gender, gender identity, physical or mental
          disability, medical condition, marital status, age or sexual
          orientation, or otherwise engage in any abusive or disruptive
          behavior;
        </li>
        <li>
          Violate or infringe anyone else’s rights or otherwise cause harm to
          anyone;
        </li>
        <li>
          Smoke any type of tobacco, cigarette, marijuana, or similar substance
          that may be inhaled, even if allowable in the jurisdiction, if the
          hosting Member has forbidden such activity in its Listing;{' '}
        </li>
        <li>
          You may not hack the Site or place any content on the Site that, in
          Our sole determination:
          <ul>
            <li>is or may be fraudulent, false, or misleading,</li>
            <li>is or may be harassing,</li>
            <li>is or may be illegal, abusive or threatening,</li>
            <li>infringes on the copyrights and trademarks of others,</li>
            <li>is or may be sexually explicit,</li>
            <li>is or may be profane, obscene, or pornographic,</li>
            <li>is or may be defamatory or libelous,</li>
            <li>is or may be harmful to minors,</li>
            <li>
              constitutes or encourages conduct that would constitute a criminal
              offense, give rise to civil liability, or otherwise violate any
              local, state, national or international law,
            </li>
            <li>is otherwise objectionable,</li>
            <li>is not permitted by the Site under its normal operation, or</li>
            <li>
              disables or otherwise blocks partial or full functionality of the
              Site to others and/or to Us.
            </li>
          </ul>
        </li>
        <li>
          You may not use the Site or your Account within the Site for any
          political advertisements that have not received prior approval by Us;
        </li>
        <li>
          You may not upload any destructive programs, information, file, or
          software such as viruses and/or self-replicating code or take any
          other actions to harm the Site, other computers, nor any electronic
          equipment;
        </li>
        <li>
          You may not send unsolicited e-mail messages, including junk mail and
          chain letters, to any other User of the Site or the Service;
        </li>
        <li>
          You may not solicit passwords or other personal information from any
          other Members of the Site or the Service;
        </li>
        <li>
          You may not post or transmit any message which discloses private or
          personal matters concerning any person;
        </li>
        <li>
          You may not post or transmit any message, data, image or program in a
          fashion that would violate the property rights of others, including
          unauthorized copyrighted text, images or programs, trade secrets or
          other confidential proprietary information, and Trademarks used in an
          infringing fashion;
        </li>
        <li>
          You will not allow any other person other than yourself to use the
          Site through your Account;
        </li>
        <li>
          You may not transmit, broadcast, retransmit, rebroadcast, distribute,
          redistribute, or make any commercial use of the Site and/or the
          Service. You may not copy, reproduce or prepare derivative works from,
          publicly perform, publicly display or distribute the Service or the
          Site in any manner not expressly authorized by Us;
        </li>
        <li>
          You will not reverse engineer, decrypt, decompile, or otherwise alter
          or interfere with the Site or the Service or attempt to do so or
          assist or encourage others in doing so; or
        </li>
        <li>
          You may not submit through the Site or through the Service false or
          incorrect information to Us.
        </li>
      </ul>,
      <p key="3">
        If you engage in any of the before mentioned prohibited activities, We
        reserve the right to immediately terminate your Account. In this case,
        you will not be entitled to a refund (whole or partial) of your Fees for
        what was the remainder of your Membership Term.
      </p>
    ]
  },
  {
    menu: 'Indemnification and Disclaimers',
    title:
      'Indemnification and Disclaimers: When and how are We liable to you.',
    children: [
      <h5 key="1">THIRD-PARTY CONTENT</h5>,
      <p key="2">
        In using our Services, Site and/or Platform, you may be exposed to
        content from other Members or third parties (“Third-Party Content”),
        either on our Services or through links to third-party websites. We do
        not control, endorse or adopt any Third-Party Content and shall have no
        responsibility for Third-Party Content, including without limitation
        material that may be misleading, incomplete, erroneous, offensive,
        indecent or otherwise objectionable. You must evaluate, and bear all
        risks associated with, Third-Party Content, including without
        limitation, profiles of other Members of our Services.
      </p>,
      <h5 key="3">DISCLAIMER OF WARRANTIES</h5>,
      <p key="4">
        IF YOU USE OUR SITE AND/OR SERVICES, YOU DO SO AT YOUR SOLE RISK. OUR
        SITE AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY
        DISCLAIM, AND YOU WAIVE, ALL OTHER WARRANTIES OF ANY KIND, WHETHER
        EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
        NON-INFRINGEMENT AS TO THE SITE AND SERVICES, INCLUDING THE INFORMATION,
        CONTENT AND MATERIALS CONTAINED THEREIN.
      </p>,
      <p key="5">
        WE DO NOT REPRESENT OR WARRANT THAT (A) OUR SITE OR SERVICES WILL MEET
        YOUR REQUIREMENTS; (B) OUR SITE OR SERVICES WILL BE UNINTERRUPTED,
        TIMELY, SECURE, OR ERROR-FREE; (C) ANY INFORMATION THAT YOU MAY OBTAIN
        THROUGH OUR SITE OR SERVICES WILL BE ACCURATE OR RELIABLE; (D) THE
        QUALITY OF ANY PRODUCTS, SITE, SERVICES, INFORMATION OR OTHER MATERIAL
        PURCHASED OR OBTAINED BY YOU THROUGH OUR SITE AND SERVICES WILL MEET
        YOUR EXPECTATIONS; (E) ANY INFORMATION YOU PROVIDE OR WE COLLECT WILL
        NOT BE DISCLOSED TO THIRD PARTIES; OR (F) ANY ERRORS IN ANY DATA OR
        SOFTWARE WILL BE CORRECTED.
      </p>,
      <p key="6">
        IF YOU ACCESS OR TRANSMIT ANY CONTENT THROUGH THE USE OF OUR SITE OR
        SERVICES, YOU DO SO AT YOUR OWN DISCRETION AND YOUR SOLE RISK. YOU ARE
        SOLELY RESPONSIBLE FOR ANY LOSS OR DAMAGE ARISING OUT OF SUCH ACCESS OR
        TRANSMISSION; THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO
        DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD.
      </p>,
      <p key="7">
        NO DATA, INFORMATION OR ADVICE OBTAINED BY YOU IN ORAL OR WRITTEN FORM
        FROM US OR THROUGH OR FROM OUR SITE OR SERVICES WILL CREATE ANY WARRANTY
        NOT EXPRESSLY STATED IN THESE TERMS.
      </p>,
      <h5 key="12">LIMITATION OF LIABILITY</h5>,
      <p key="8">
        SUBJECT TO APPLICABLE LAW, IN NO EVENT SHALL N’TICE, OR OUR DIRECTORS,
        MEMBERS, SHAREHOLDERS, OFFICERS, AFFILIATES, EMPLOYEES OR AGENTS BE
        LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING,
        BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER
        IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE)
        OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES, ARISING FROM OR RELATING TO: (A) THE USE OR INABILITY TO USE
        OUR SITE AND/OR SERVICES; (B) THE COST OF REPLACEMENT OF ANY GOODS,
        SERVICES OR INFORMATION PURCHASED OR OBTAINED AS A RESULT OF ANY
        INFORMATION OBTAINED FROM OR TRANSACTIONS ENTERED INTO THROUGH OR FROM
        OUR SITE OR SERVICES; (C) DISCLOSURE OF, UNAUTHORIZED ACCESS TO OR
        ALTERATION OF YOUR CONTENT; (D) DAMAGES FOR LOSS OR CORRUPTION OF DATA
        OR PROGRAMS, SERVICE INTERRUPTIONS OR PROCUREMENT OF SUBSTITUTE
        SERVICES, EVEN IF WE KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES; (E) STATEMENTS, CONDUCT OR OMISSIONS OF ANY SERVICE
        PROVIDERS OR OTHER THIRD PARTY ON OUR SITE OR SERVICES; (F) YOUR OR
        ANYONE ELSE’S CONDUCT OR ACTS IN CONNECTION WITH THE USE OF THE SITE OR
        SERVICES; (G) ANY OTHER MATTER ARISING FROM, RELATING TO OR CONNECTED
        WITH OUR SITE OR SERVICES OR THESE TERMS; OR (H) ANY DAMAGE, INJURY, OR
        LOSS TO ANY PROPERTY LISTED ON OUR SITE OR SERVICES ANY DAMAGE, INJURY,
        OR DEATH, CAUSE BY ANOTHER MEMBER TO YOU.
      </p>,
      <p key="9">
        WE SHALL NOT BE LIABLE FOR ANY FAILURE OR DELAY IN PERFORMING UNDER
        THESE TERMS, WHETHER OR NOT SUCH FAILURE OR DELAY IS DUE TO CAUSES
        BEYOND OUR REASONABLE CONTROL SUCH AS, BUT NOT LIMITED TO, POWER,
        INTERNET OR COMMUNICATION OUTAGES, POWER OUTAGES, FIRE, FLOOD,
        EARTHQUAKES, TORNADOES, HURRICANES, WARS, ACTS OF GOD, FORCE MAJEURE, OR
        THE OCCURRENCE OF ANY OTHER UNFORESEEN CONTINGENCY OR EVENT BEYOND THE
        CONTROL OF N’TICE.
      </p>,
      <p key="10">
        IN NO EVENT WILL OUR AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY IN
        ANY MATTER ARISING FROM OR RELATING TO OUR SITE, THE SERVICES, OR THESE
        TERMS EXCEED THE SUM OF ONE HUNDRED US DOLLARS ($100).
      </p>,
      <p key="11">
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
        THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
        DAMAGES. ACCORDINGLY, SOME OF THE LIMITATIONS OF THIS SECTION MAY NOT
        APPLY TO YOU
      </p>,
      <h5 key="13">INDEMNITY</h5>,
      <p key="14">
        You shall defend, indemnify and hold harmless N’TICE (and each of our
        officers, directors, members, shareholders, employees, agents and
        affiliates) from any claim, demand, action, damage, loss, judgment, cost
        or expense, including without limitation reasonable attorneys’ fees and
        costs, arising out of or relating to (a) your use of our Site and/or
        Services; (b) any Member Content or Listing you provide; (c) your
        violation of these Terms; (d) your violation of any rights of another;
        and/or (e) your conduct in connection with the Site and/or Services. If
        you are obligated to indemnify Us, We will have the right, in our sole
        and unfettered discretion, to control any action or proceeding and
        determine whether We wish to settle it, and if so, on what terms.
      </p>
    ]
  },
  {
    menu: 'Dispute Resolution',
    title: 'Dispute Resolution',
    children: [
      <p key="1">
        We will always work with our Members to resolve any dispute or issue
        that may arise in the manner that is most constructive and efficient to
        all parties. However, if We cannot resolve a dispute in a manner that is
        satisfactory to you, then you and N’TICE agree to arbitrate any dispute
        arising from these Terms or relating to the Services, except that you
        and N’TICE are not required to arbitrate any dispute in which either
        party seeks equitable or other relief for the alleged unlawful use of
        copyrights, trademarks, trade names, logos, trade secrets or patents.
        SUBJECT TO ANY RIGHTS YOU MAY HAVE UNDER APPLICABLE LAW, ARBITRATION
        PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL. You and
        N’TICE agree that you will notify each other of any dispute within
        thirty (30) days of when it arises, that you will attempt informal
        resolution prior to any demand for arbitration, that any arbitration
        will occur in San Juan, Puerto Rico (USA) and that arbitration will be
        conducted confidentially by a single arbitrator in accordance with the
        Rules of the American Arbitration Association. These Terms shall be
        exclusively construed and governed by the laws of the Commonwealth of
        Puerto Rico without regard to its conflict of law provisions or the laws
        of any other state or country or your actual state or country of
        residence. You and N’TICE also agree that the state or federal courts in
        the Commonwealth of Puerto Rico have exclusive jurisdiction over any
        appeals of an arbitration award and over any suit between the parties
        not subject to arbitration. Other than class procedures and remedies
        discussed below, the arbitrator has the authority to grant any remedy
        that would otherwise be available in court. WHETHER THE DISPUTE IS HEARD
        IN ARBITRATION OR IN COURT, YOU AND N’TICE WILL NOT COMMENCE AGAINST THE
        OTHER A CLASS ACTION, CLASS ARBITRATION OR OTHER REPRESENTATIVE ACTION
        OR PROCEEDING.
      </p>
    ]
  },
  {
    menu: 'Contacting Us',
    title: 'Contacting Us & Customer Service',
    children: [
      <p key="1">
        Our door is always open to hearing from you, our Members. You may
        contact Us at any of the two addresses below:
      </p>,
      <div key="2" style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <h5>Email</h5>
          dev@ntice.me
        </div>
        <div style={{ flex: 1 }}>
          <h5>Mailing Address</h5>
          NTICE Inc.
          <br />
          44 Progreso St
          <br />
          Aguadilla PR 00603
          <br />
        </div>
      </div>,
      <p key="3">
        You may also contact us concerning any conduct of a fellow Member that
        you believe are not in line with these Terms.
      </p>
    ]
  },
  {
    menu: 'Miscellaneous',
    title: 'Miscellaneous',
    children: [
      <p key="2">
        You may not assign any of your rights or obligations under these Terms
        without prior written consent from Us. We may assign any or all of its
        rights under these Terms, in whole or in part, without obtaining your
        consent or approval.
      </p>,
      <p key="3">
        These Terms contain the entire agreement, and supersede all prior and
        contemporaneous understandings, between the parties regarding their
        subject matter.
      </p>,
      <p key="4">
        Our failure or delay in exercising any right, power or privilege under
        these Terms shall not operate as a waiver thereof.
      </p>,
      <p key="5">
        The invalidity or unenforceability of any of these Terms shall not
        affect the validity or enforceability of any other of these Terms, all
        of which shall remain in full force and effect.
      </p>,
      <p key="6">
        These Terms will govern the relationship between N’TICE and its Members,
        with the exception of any particular clause, obligation or condition
        that is legally inapplicable in your jurisdiction.
      </p>,
      <p key="7">
        Headings of sections are for convenience only and shall not be used to
        limit or construe such sections.
      </p>
    ]
  }
]

export default class TermsPage extends Component {
  constructor(props) {
    super(props)
    this.sections = {}
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).parentElement.parentElement.scrollTop = 0
  }

  scrollTo = (sectionNumber) => () => {
    this.sections[sectionNumber].scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <div className="privacy page">
        <div className="hero">
          <div className="hero-container">
            <div className="title">
              <h1>Terms of Use</h1>
              <p>Everything you need to know about N'TICE and more.</p>
            </div>
            <div className="image">
              <Terms />
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="page-container">
            <div className="table-of-contents">
              <h6>Table Of Contents</h6>
              <ul>
                {content.map((c, i) => (
                  <li key={i} onClick={this.scrollTo(i)}>
                    {c.menu}
                  </li>
                ))}
              </ul>
            </div>
            <div className="sections">
              {content.map((c, i) => (
                <div
                  key={i}
                  ref={(section) => {
                    this.sections[i] = section
                  }}
                  className="section"
                >
                  <h2>{c.title}</h2>
                  <div className="box">{c.children}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
