import axios from 'axios'
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { InMemoryCache } from '@apollo/client/cache'
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

// Adds messages only in a dev environment
if (process.env.NODE_ENV === "development") {  
  loadDevMessages();
  loadErrorMessages();
}

const BASE_URL = process.env.REACT_APP_NTICE_BASE_URL

function getAccessToken() {
  const token = localStorage.getItem('_locker')

  if (token) {
    const { accessToken } = JSON.parse(token) || {}
    if (accessToken) return accessToken
  }

  return null
}

const cache = new InMemoryCache()

const httpLink = createHttpLink({ uri: `${BASE_URL}/graphql` })
const authLink = setContext((_, { headers = {} }) => {
  const token = getAccessToken()
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return { headers }
})

// graphql
const link = authLink.concat(httpLink)


export const apolloClient = new ApolloClient({
  link: ApolloLink.from([link]),
  cache: cache
})

const api = axios.create({
  baseURL: BASE_URL
})

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

api.interceptors.response.use((res) => res.data)

export default api
