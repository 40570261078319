import React, { useState } from 'react'
import CarouselWithControls from '../../components/carousels/CarouselWithControls'
import { RiHeartsLine } from "react-icons/ri";

import '../../styles/carousel.scss'

const placeholderImages = [
  {
    name: 'Quiet Apartment in the Lively Vila de Gracia - Barcelona, Spain',
    image:
      'https://res.cloudinary.com/ds4irsiqv/image/upload/hkqj5k1foiwjnpcgzqsr',
    url: '/venue/12'
  },
  {
    name: 'Deer Park Golf Course and Ski Cottage - Deer Park Washington, US ',
    image:
      'https://res.cloudinary.com/ds4irsiqv/image/upload/ffpcydvfpaxqpaoepmvu',
    url: '/venue/2'
  },
  {
    name: 'Cozy home - Hanoi, Vietnam',
    image:
      'https://res.cloudinary.com/ds4irsiqv/image/upload/rij2vprftfpbqgb0mpzs',
    url: '/venue/26'
  }
]

function FeaturedPlaces({ isAuthenticated }) {
  const [currentImage, setCurrentImage] = useState(placeholderImages[0])
  const [currentIndex, setCurrentIndex] = useState(0)

  const backHandler = () => {
    if (currentIndex === 0) {
      return null
    }
    else {
      setCurrentIndex(prevIndex => prevIndex - 1)
      setCurrentImage(placeholderImages[currentIndex - 1])
    }
  }

  const nextHandler = () => {
    if (currentIndex === placeholderImages.length - 1) {
      return null
    }
    else {
      setCurrentIndex(prevIndex => prevIndex + 1)
      setCurrentImage(placeholderImages[currentIndex + 1])
    }
  }

  return (
    <article className="featured-places-container">
      <section className='header-container'>
        <h5>
          <RiHeartsLine />
          <span>Featured places</span>
        </h5>
        <article className="carousel-dots-container end">
          <div className='carousel-dots'>
            <span className={`carousel-dot ${currentIndex === 0 ? "active" : null}`} />
            <span className={`carousel-dot ${currentIndex === 1 ? "active" : null}`} />
            <span className={`carousel-dot ${currentIndex === 2 ? "active" : null}`} />
          </div>
        </article>
      </section>
      <CarouselWithControls isAuthenticated={isAuthenticated} currentImage={currentImage} setCurrentImage={setCurrentImage} backHandler={backHandler} nextHandler={nextHandler} />
    </article>
  )
}

export default FeaturedPlaces