import Api from '../services/Api'
import { Client } from "@twilio/conversations";

const fetchToken = async () => {
  try {
    const { token } = await Api.get('/api/twilio/conversation/token')
  
    return token
  } catch(e) {
    return null
  }
}

class Conversation {
  initialized = false;
  client = null
  conversations = []
  activeConversation = null

  async getToken() {
    const token = await fetchToken()
    return token
  }

  async initialize(token = null) {
    let chatToken;

    if (!token) {
      chatToken = await fetchToken()
    } else {
      chatToken = token
    }

    if (chatToken) {
      this.client = new Client(chatToken)
      this.initialized = true
    }
  }

  async listConversations() {
    try {
      if (this.client) {
        const conversationsPaginator = await this.client.getSubscribedConversations()
        this.conversations = conversationsPaginator.items
  
        return conversationsPaginator.items
      }
  
      return []
    } catch(e) {
      return null
    }
  }

  async conversationAlreadyExists(email) {
    let existingConvversation = null

    const conversations = await this.client.getSubscribedConversations()
    conversations.items.map(convo => {
      if (convo.attributes.creator.id && convo.attributes.user.id) {
        convo.participants.forEach(participant => {
          if (participant.state.identity == email) existingConvversation = convo
        })
      }
    })

    return existingConvversation
  }

  async createConversation(creator, user) {
   const conversation = await this.client.createConversation({
    attributes: {
      creator,
      user
    }
  })

   await conversation.join()
   await conversation.add(user.email)

   this.activeConversation = conversation
   
   return conversation
  }

  async addConversationParticipant(conversation, email) {
    await conversation.add(email)
    await conversation.getParticipants()
  }

  async leaveConversation(conversation) {
    await conversation.leave();
  }

  async getConversationParticipants() {
    const participants = await this.client.getParticipants()
  }

  // continue here: https://www.twilio.com/docs/conversations/working-with-conversations
}

export default new Conversation()

