import React from 'react'

function Envelope() {
  return (
    <svg
      width="132"
      height="106"
      viewBox="0 0 132 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.024 1.73674C106.104 1.34171 105.034 1.28815 104.017 1.64187L4.24567 36.345C3.22777 36.699 2.42165 37.4041 1.94606 38.2859C1.38452 39.3089 1.23373 40.5467 1.6431 41.7236L22.4648 101.586C22.8741 102.763 23.7609 103.641 24.8357 104.093C25.7565 104.488 26.8265 104.542 27.8434 104.188L127.613 69.4854C128.651 69.1247 129.457 68.4197 129.927 67.5182C130.488 66.4952 130.619 65.2645 130.216 64.1068L109.394 4.24476C108.985 3.06782 108.098 2.18983 107.023 1.73708L107.024 1.73674Z"
        fill="#9889FF"
      />
      <path
        d="M56.5716 56.1707L24.836 104.094C23.7609 103.641 22.8745 102.764 22.4651 101.587L1.64344 41.7245C1.23406 40.5476 1.38452 39.3088 1.94639 38.2868L56.5719 56.1716L56.5716 56.1707Z"
        fill="#543AFF"
      />
      <path
        d="M109.395 4.24441L130.217 64.1064C130.62 65.2641 130.488 66.4952 129.928 67.5179L75.2889 49.6603L107.024 1.73672C108.1 2.19043 108.986 3.06747 109.395 4.24441Z"
        fill="#543AFF"
      />
      <path
        d="M107.024 1.7368L75.2889 49.6604L70.4197 57.0128C69.2404 58.832 67.0061 59.6091 64.9524 58.9145L56.5716 56.1708L1.94605 38.286C2.42294 37.4048 3.22873 36.6988 4.24566 36.3451L104.016 1.64226C105.034 1.28821 106.103 1.3408 107.023 1.73714L107.024 1.7368Z"
        fill="#7661FF"
      />
    </svg>
  )
}

export default Envelope
