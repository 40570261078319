import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useQuery , useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { gql } from '@apollo/client'
import Modal from 'react-modal'
import styled from 'styled-components'
import { Timestamp } from '../messages/timestamp'
import ProfileAvatar from '../messages/profile-avatar'
import { getUserNotifications, markAllAsRead } from '../../services/firebase'
import moment from 'moment'


const NotificationContainer = styled.div`
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: #1a222d;
`

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #543aff;
  align-items: center;
  padding: 14px;
  color: white;
`

const NotificationHeadLink = styled.a`
  &&& {
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }
`

const EmptyListContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const ListContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const NoMessages = styled.div`
  color: #727a84;
`

const NotificationMessageWrapper = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: 15px;
  position: relative;
  align-items: center;

  &:not(:first-child) {
    border-top: 1px solid #141a23;
  }

  &:hover {
    background-color: #141a23;
  }
`

const MessageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MessageText = styled.div`
  max-width: 250px;
  font-size: 14px;
  line-height: 24px;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  .selected & {
    color: white;
    opacity: 0.6;
  }
`
const MessageBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
`
const GoToButton = styled.div`
  font-size: .8rem;
`

const ProfileWrapper = styled.div`
  padding: 10px;
`
const NOTIFICATIONS_TYPES = gql`
query{
  getNotificationsTypes{
    id
    title
    body
  }
}
`

const NotificationMessage = ({

  conversingWith,
  title,
  body,
  createdDate,
  onClick = () => { }
}) => {

  return (
    <NotificationMessageWrapper onClick={onClick}>
      <ProfileWrapper>
        <ProfileAvatar isSelected={false} user={conversingWith} />
      </ProfileWrapper>
      <MessageWrapper>
        <MessageText>
          {title}
        </MessageText>
        <MessageBody>
          <p>{moment(createdDate).fromNow()}</p>
          <GoToButton className='button secondary' onClick={onClick}>Open</GoToButton>
        </MessageBody>
      </MessageWrapper>
    </NotificationMessageWrapper>
  )
}

const NotificationMessageContainer = ({ unreadnotifications, onClose, onClick }) => {

  const { loading, error, data = {} } = useQuery(NOTIFICATIONS_TYPES)


  const notificationsMapRendering = () => {
    const sortedNotifications = unreadnotifications
    .map(notification => {
      const { type } = notification
      const { title, body } = data?.getNotificationsTypes?.find(notificationType => notificationType.id === type)
      return { ...notification, title, body }
    }).sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime());


    return sortedNotifications.map((notification) => (
      <NotificationMessage
        key={notification.id}
        onClick={() => onClick(notification)}
        title={notification.title}
        body={notification.body}
        createdDate={notification.created_date}
      />
    ))
  }

  return (
    <ListContainer>
      {loading && <NoMessages>Loading...</NoMessages>}
      {error && <NoMessages>Error loading Notifications.</NoMessages>}
      {!loading && !error && notificationsMapRendering()}
    </ListContainer>
  )
}


const UNREAD_NOTIFICATIONS_QUERY = gql`
query getUnreadNotifications($userId: Int!) {
  getUnreadNotifications(notify_user_id: $userId) {
    id
    type
    booking_id
    created_date
  }
}
`

const NOTIFICATION_IS_READ= gql`
mutation($notificationId: Int!){
  setIsReadNotification(id:$notificationId){
    id
  }
}
`

const Notifications = (({ locker, isOpen, onClose }) => {

  const history = useHistory()
  const [messages, setMessages] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState([])

  const userId = locker.profile?.currentUser.id


  const { loading, error, data = {} } = useQuery(UNREAD_NOTIFICATIONS_QUERY, {
    pollInterval: 30 * 1000,
    variables: { userId }
  })

  const [notificationIsReadMut] = useMutation(NOTIFICATION_IS_READ)

  useEffect(() => {

    setUnreadNotifications(data.getUnreadNotifications)

    locker.newNotifications = data.getUnreadNotifications?.length > 0


  }, [data, isOpen])

  if (loading) {
    return <div>Loading...</div>
  }

  // if (error) {
  //   return <div>Error loading data ... :(</div>
  // }

  const markNotificationAsRead = (notificationId) => {
    return notificationIsReadMut({
      variables: {
        notificationId: notificationId 
      },
      refetchQueries: [{
        query: UNREAD_NOTIFICATIONS_QUERY,
        variables: { userId }
      }]
    })
  }

  const onNotificationClick = (notification) => {

    if (notification.type === 1) history.push('/account/bookings')
    else if (notification.type === 2) history.push('/account/bookings')
    else if (notification.type === 3) history.push('/account/trips')
    else if (notification.type === 4) history.push('/account/trips')
    markNotificationAsRead(notification.id)
    onClose()
  }

  const markAllAsRead = () => {
    setUnreadNotifications([])
    unreadNotifications.map(notification => {
      markNotificationAsRead(notification.id)
    })
  }

  return (
    <Modal
      className='sidebar-modal-right'
      overlayClassName="modal-overlay"
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
    >
      <NotificationContainer>
        <NotificationHeader>

          <NotificationHeadLink
          onClick={() => markAllAsRead()}
          >
            <i className="icon ion-android-locate" />
            <span>  Mark All as Read</span>
          </NotificationHeadLink>

          <NotificationHeadLink onClick={onClose}>Close</NotificationHeadLink>

        </NotificationHeader>

        {unreadNotifications?.length <= 0 && (
          <EmptyListContainer>
            <NoMessages>You have no notifications</NoMessages>
          </EmptyListContainer>
        )}

        <NotificationMessageContainer
          unreadnotifications={unreadNotifications}
          onClose={onClose}
          onClick={onNotificationClick}
        />
      </NotificationContainer>
    </Modal>
  )
})

export default inject('locker', 'chat')(observer(Notifications))
