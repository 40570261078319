import React, { useEffect, useState } from 'react'
import { IoMdArrowBack } from 'react-icons/io'
import { IconContext } from 'react-icons'
import { useHistory } from 'react-router-dom'

import AboutYourself from './steps/AboutYourself'
import WhereDoYouWork from './steps/WhereDoYouWork'
import SecureYourAccount from './steps/SecureYourAccount'
import VerifyYourAccount from './steps/VerifyYourAccount'
import '../../styles/onBoarding.scss'


const OnBoardingForm = () => {
  const history = useHistory()
  const [step, setStep] = useState('AboutYourself')
  const [form, setForm] = useState({
    firstName: undefined,
    lastName: undefined,
    schoolName: undefined,
    country: undefined,
    countryId: undefined,
    password: undefined,
    repeatPassword: undefined,
    marketing: true,
    terms: true
  })


  const changeStep = (step) => {
    setStep(step)
  }

  const onGoBack = () => {
    if (step === 'AboutYourself') {
      history.push('/')
    } else {
      if (step === 'WhereDoYouWork') setStep('AboutYourself')
      if (step === 'SecureYourAccount') setStep('WhereDoYouWork')
    }
  }

  return (
    <div className="onboarding-wrapper">
      {step !== 'VerifyYourAccount' && (
        <div className="back-nav" onClick={onGoBack}>
          <IconContext.Provider value={{ size: '24px' }}>
            <IoMdArrowBack />
          </IconContext.Provider>
          Back
        </div>
      )}

      {step === 'AboutYourself' && (
        <AboutYourself
          onStepChange={changeStep}
          form={form}
          setForm={setForm}
        />
      )}
      {step === 'WhereDoYouWork' && (
        <WhereDoYouWork
          onStepChange={changeStep}
          form={form}
          setForm={setForm}
        />
      )}
      {step === 'SecureYourAccount' && (
        <SecureYourAccount
          onStepChange={changeStep}
          form={form}
          setForm={setForm}
        />
      )}
      {step === 'VerifyYourAccount' && (
        <VerifyYourAccount onStepChange={() => history.push('/')} form={form} />
      )}


    </div>
  )
}

export default OnBoardingForm
