import React, { Component } from 'react'
import styled from 'styled-components'

const HiddenInput = styled.input`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export default class File extends Component {
  triggerFileInput = () => {
    this.fileInput.click()
  }

  render() {
    /* `required` and `label` are available from this.props */
    const {
      name,
      showInput,
      errorMessage,
      onBlur,
      onChange,
      onInvalid
    } = this.props

    return (
      <div className={`form-control ${errorMessage ? 'error' : ''}`}>
        <button
          className="pointer button secondary"
          onClick={this.triggerFileInput}
        >
          Upload Photos
        </button>
        <HiddenInput
          visible={showInput}
          ref={(input) => {
            this.fileInput = input
          }}
          id={name}
          key={name}
          type="file"
          onInvalid={onInvalid}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          multiple
        />
        {errorMessage && <span className="error-text">{errorMessage}</span>}
      </div>
    )
  }
}
