import PropTypes from 'prop-types'
import React from 'react'
import '../../styles/onBoarding.scss'

export const Button = ({
  text = 'Continue',
  state,
  className,
  onClick,
  disabled,
  type
}) => {
  return (
    <button
      disabled={state === 'disabled' || disabled}
      onClick={onClick}
      className={`button ${className}`}
      type={type}
    >
      {text}
    </button>
  )
}

Button.propTypes = {
  state: PropTypes.string,
  className: PropTypes.string
}
