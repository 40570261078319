import { makeObservable, observable, action } from 'mobx';

class UiStore {
    searchOnNavbar = false;

    constructor() {
        makeObservable(this, {
            searchOnNavbar: observable,
            setSearchOnNavbar: action,
        });
    }

    setSearchOnNavbar(value) {
        this.searchOnNavbar = value;
    }
}

const uiStore = new UiStore();
export default uiStore;