import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Privacy } from '../components/svg/privacy-terms'
import '../styles/privacy-terms.scss'

export default class PrivacyPage extends Component {
  constructor(props) {
    super(props)
    this.sections = {}
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).parentElement.parentElement.scrollTop = 0
  }

  scrollTo = (sectionNumber) => () => {
    this.sections[sectionNumber].scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <div className="privacy page">
        <div className="hero">
          <div className="hero-container">
            <div className="title">
              <h1>Privacy Policy</h1>
              <p>We care about your privacy</p>
            </div>
            <div className="image">
              <Privacy />
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="page-container">
            <div className="table-of-contents">
              <h6>Table Of Contents</h6>
              <ul>
                <li onClick={this.scrollTo(1)}>Welcome</li>
                <li onClick={this.scrollTo(2)}>What is Collected?</li>
                <li onClick={this.scrollTo(3)}>Cookies</li>
                <li onClick={this.scrollTo(4)}>How is it Used?</li>
                <li onClick={this.scrollTo(5)}>Sharing with Members</li>
                <li onClick={this.scrollTo(6)}>Sharing with Third Parties</li>
                <li onClick={this.scrollTo(7)}>Third Party Information</li>
                <li onClick={this.scrollTo(8)}>“Do Not Track” Signs</li>
                <li onClick={this.scrollTo(9)}>Control of Information</li>
                <li onClick={this.scrollTo(10)}>User Options</li>
                <li onClick={this.scrollTo(11)}>Changes to Privacy Policy</li>
                <li onClick={this.scrollTo(12)}>Miscellaneous</li>
              </ul>
            </div>
            <div className="sections">
              <div
                ref={(section) => {
                  this.sections[1] = section
                }}
                className="section"
              >
                <h2>Welcome</h2>
                <div className="box">
                  <p>
                    N’TICE, Inc. (“N’TICE”) is dedicated to protecting your
                    personal information and we hereby inform you how we protect
                    and use such information. This Privacy Policy applies to all
                    transactions, data transmission that occurs with N’TICE
                    through its websites http://www.ntice.mem (the "Site"). By
                    using our Site, you accept the terms and conditions and
                    practices described in this Privacy Policy, as well as our
                    Terms of Use, which is incorporated by reference into this
                    Privacy Policy. If you see an undefined term in this Privacy
                    Policy (such as “Services” or “Listings”), it has the same
                    definition as outlined in our Terms of Service.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[2] = section
                }}
                className="section"
              >
                <h2>What Information is Collected?</h2>
                <div className="box">
                  <p>
                    In order to use our Site and Services, you must provide
                    certain personal information necessary for the transaction,
                    such as name, address, telephone number, credit card number,
                    or email address. In addition, you must provide certain
                    demographic information, such as zip code, city, username,
                    age, date of birth, IP address, and other device histories.
                    In addition, we may collect the following information
                    regarding your interaction with the Site and/or our
                    Services: interactions with other Members, communications
                    with other Members, information provided by third parties
                    (such as Facebook or Google) if you decide to connect your
                    respective third party accounts to our Site, payment methods
                    and/or information that you voluntarily provide to us.
                    Please note that We does not knowingly store, collect or
                    maintain Member information for persons under the age of
                    eighteen (18) years of age. If it comes to our attention
                    that we have inadvertently received information from a
                    person under eighteen (18) years of age through the Site, we
                    will delete that information from our records.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[3] = section
                }}
                className="section"
              >
                <h2>Cookies</h2>
                <div className="box">
                  <p>
                    In order for this Website to function properly, we sometimes
                    install small data files, known as cookies, on users'
                    devices. Most websites do too. A cookie is a small text file
                    that websites install on the computer or mobile device of
                    users who visit them. Cookies make it possible for the
                    website to remember user actions and preferences (login ID,
                    language, font size, and other display preferences) so that
                    it does not have to be reconfigured when you return to the
                    site or browse through its respective pages. On some pages
                    We use cookies to remember, without limitation:
                  </p>
                  <ul>
                    <li>
                      Your presentation preferences (for example, contrast,
                      color, or font size).
                    </li>
                    <li>
                      If you have already responded to a pop-up questionnaire
                      about the usefulness of a particular content (not to ask
                      you to do so again).
                    </li>
                    <li>
                      Whether or not you have given your consent to use cookies
                      on our Site.
                    </li>
                  </ul>
                  <p>
                    Although cookies are not essential for the Site to work, if
                    you enable them you will enjoy a better browsing experience.
                    You can delete or block cookies, but if you do, some of the
                    features on this Site may not work properly. The information
                    associated with cookies is not used to personally identify
                    the user. In addition, we fully maintain data on their
                    navigation patterns. These cookies are used exclusively for
                    the purposes indicated here. You can control or delete
                    cookies whenever you want. For more information, see
                    http://www.aboutcookies.org. In addition to being able to
                    remove all cookies already on your computer, you can also
                    configure most browsers to stop accepting them. But keep in
                    mind that if you reject cookies, you may have to manually
                    reconfigure your preferences each time you visit a site and
                    that certain features and services cease to function.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[4] = section
                }}
                className="section"
              >
                <h2>How is This Information Used?</h2>
                <div className="box">
                  <p>
                    The main purpose of accessing and using your information is
                    to provide Members with a great experience. Your information
                    is collected and stored to provide you with certain
                    personalized services and to develop new products and
                    Services.
                  </p>
                  <p>
                    We may communicate directly with you regarding your
                    transactions, using confirmation emails confirming your
                    transactions or providing information on possible events of
                    interest. It is important to note that this communication
                    may also help us provide you with specific information that
                    you may deem necessary, such as cancellation warnings and
                    other unexpected information. We may use your information
                    for the processing, billing and/or recovery of transactions
                    made on or through the Site.
                  </p>
                  <p>
                    We may use your information to provide or send (via email,
                    telemarketing, location-based marketing, push notifications
                    and/or any other means) information about the Site and
                    Services and/or products provided by N’TICE and/or its
                    affiliates, information about the services and/or products
                    of third parties; and / or information for which you have
                    indicated interest.
                  </p>
                  <p>
                    This information also helps us measure the performance of
                    our Services allowing us to continually improve. The
                    information provided can also help us in the detection of
                    fraud, abuse and other behavior.
                  </p>
                  <p>
                    We may use your information for internal purposes related to
                    research and development; to make business decisions; or to
                    improve or develop our service and / or products. In
                    addition, your information could be used to generate
                    statistical reports or analysis.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[5] = section
                }}
                className="section"
              >
                <h2>Disclosure or Sharing Information with Members</h2>
                <div className="box">
                  <p>
                    When you interact with other Members to make a Reservation,
                    provide a Listing, or stay at their Property, you will be
                    sharing with said Member any information that you have made
                    available on your profile (such as your name, picture, home
                    city, and any other information that may be displayed on
                    your profile).
                  </p>
                  <p>
                    We will not share any billing or credit card information you
                    have submitted to Us to any Member.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[6] = section
                }}
                className="section"
              >
                <h2>Disclosure or Sharing Information with Third Parties</h2>
                <div className="box">
                  <p>
                    Unless otherwise provided in this Privacy Policy, N’TICE
                    agrees to keep all information secure and will not disclose
                    it for any other matter.
                  </p>
                  <p>
                    Some of the information will be shared with other Members
                    (as defined in the Terms of Use) as you interact with them.
                  </p>
                  <p>
                    We may disclose your information if a government agent or
                    law enforcement agent makes an application, as long as it
                    complies with applicable law.
                  </p>
                  <p>
                    We may transfer, assign, rent or sell your information to
                    third parties in connection with events outside our ordinary
                    business practices, including, without limitation, the sale,
                    merger, consolidation, change of control, transfer or sale
                    of assets, reorganization or liquidation.
                  </p>
                  <p>
                    We may share or disclose your information to our affiliates
                    and / or those entities or persons with whom we have entered
                    into agreements to (i) perform functions or services
                    relating to the operation, administration and maintenance of
                    the Site (including but not limited to service providers web
                    hosting, programming or applications); (ii) the processing
                    of collections and payments of transactions made through the
                    Site; (iii) the processing, handling and delivery the
                    Services, Reservations and Listings (each as defined in the
                    Terms of Use) in the Site; and / or (iv) provide or submit
                    information about the Site, the services and products of
                    N’TICE or its affiliates, services and products of third
                    parties, and / or information for which you have indicated
                    interest.
                  </p>
                  <p>
                    We may share or disclose your information with our
                    affiliates and / or third parties who may collect and / or
                    use your information to provide or send you, via any means
                    or method (including, without limitation, email,
                    telemarketing, "push notifications" (i) information about
                    your products and / or services, (ii) information about the
                    products and services of third parties, (iii) marketing
                    information, (iv) newsletters, (v) services or personalized
                    content, and / or (vi) targeted advertising and / or
                    content.
                  </p>
                  <p>
                    We may offer services, products or promotions together with
                    our affiliates or third parties on or through the Site. In
                    such cases, we may share or disclose your information
                    collected in connection with such services, products or
                    joint promotions to the affiliate or third party with which
                    such service, product or promotion is performed.
                  </p>
                  <p>
                    Information shared with affiliates or third parties will be
                    subject to their privacy policies
                  </p>
                  <p>
                    We may share or disclose your information in aggregate form
                    with third parties that you engage to allow viewing and
                    arrangement of online advertising of our services or
                    products on third party sites, pages or websites. These
                    third parties include: advertising networks, advertising
                    platforms, agencies, data analysis and social media service
                    providers, among others. We reserve the right to disclose or
                    share information in aggregate form that does not identify
                    any particular user with third parties for purposes of
                    marketing analysis.
                  </p>
                  <p>
                    As described in this Privacy Policy, we may sometimes share
                    your information within our company or an affiliate company,
                    as may be created from time to time, or with third parties.
                    When you use the Site and Services, you authorize us to
                    transfer, store, and process your information in the United
                    States and possibly other countries. Laws in these countries
                    may differ from the laws applicable to the place where you
                    live. For example, information collected within the European
                    Economic Area may be transferred, stored, and processed
                    outside of the European Economic Area and in the United
                    States for the purposes described in this Privacy Policy. By
                    using our Site and Services, you consent to transferring
                    your data from your home country to the United States.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[7] = section
                }}
                className="section"
              >
                <h2>Access to Third Party Information</h2>
                <div className="box">
                  <p>
                    Third parties may collect information about their activities
                    online at different sites or web pages.
                  </p>
                  <p>
                    We may contract with third parties in matters related to the
                    operation, development and maintenance of the Site (such as
                    banners, web hosting, applications, among others), including
                    topics related to the exhibition and layout of online
                    advertising. These third parties may access, collect and /
                    or use your information through their own cookies, web
                    beacons, local storage technology or other technology to
                    keep track of the number of users accessing the Site and /
                    or to identify advertising insertions more suitable for
                    their interests, preferences and activities.
                  </p>
                  <p>
                    N’TICE and its affiliates are not responsible, nor control
                    or sponsor the webpages, applications, sites and / or
                    third-party websites that can be accessed through the links
                    that appear in the Site. Therefore, these third parties are
                    solely responsible for the collection, administration and /
                    or disclosure of any information you provide on or through
                    such platforms, pages, applications, sites and / or third
                    party websites. N’TICE and its affiliates do not guarantee
                    that such third party platforms, pages, applications, sites
                    and/or portals protect the information they obtain from
                    their users. These other websites, sites and/or portals may
                    have their own privacy policies and information collection
                    policies, which they must examine before providing any
                    information to them.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[8] = section
                }}
                className="section"
              >
                <h2>“Do Not Track” Signs</h2>
                <div className="box">
                  <p>
                    We may track your online behavior by using technology to
                    increase the effectiveness of the ads or content provided on
                    the Website or on the channels. Examples of online behavior
                    that can be traced include response to promotions or
                    advertisements, websites being visited, and content
                    accessed.
                  </p>
                  <p>
                    Currently, some browsers offer their users the option of
                    selecting the "do-not-track" option in their configuration.
                    These browsers, in turn, transmit signals to the websites
                    with which the browser communicates. To the extent that
                    there is no uniformity in the industry with respect to the
                    use of this type of technology, for the moment we will not
                    assign value or meaning to "do-not-track" signals and we
                    will not respond to them.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[9] = section
                }}
                className="section"
              >
                <h2>Control of Information</h2>
                <div className="box">
                  <p>
                    We can offer mechanisms that will allow you to correct or
                    eliminate the information you have provided. You must
                    access, correct or delete the information that you provide
                    as part of your registration through the mechanisms
                    available on the Site. If you wish to delete the information
                    that has been collected, you can do so by sending an email
                    to nticetravel@gmail.com.
                  </p>
                  <p>
                    You acknowledge and agree that even if your information is
                    deleted, certain information may still be stored in the
                    records and/or files of N’TICE, and / or its affiliates with
                    whom such information has been shared as described in this
                    Privacy Policy. However, such information will not be used
                    by N’TICE or its affiliates for any commercial purpose.
                  </p>
                  <p>
                    If you have any questions about this Privacy Policy, please
                    send an email to nticetravel@gmail.com.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[10] = section
                }}
                className="section"
              >
                <h2>User Options</h2>
                <div className="box">
                  <p>
                    You may at any time opt-out the authorization you have given
                    us to use your information to provide or send you
                    information about the services and products of your
                    affiliates or third parties, or to share your information in
                    the manner described in this Privacy Policy. You can
                    exercise these options in the following ways:
                  </p>
                  <p>
                    If you do not wish to receive emails with commercial
                    messages, you can do so using the mechanism provided in the
                    emails with commercial messages sent by us (located at the
                    end of each message) to request that no more messages be
                    sent to you. If you have any inconvenience in using the
                    mechanisms offered by us to request that you no longer
                    receive emails with commercial messages, you can send an
                    email to nticetravel@gmail.com indicating the commercial
                    message(s) you do not want to receive.
                  </p>
                  <p>
                    If you wish to opt-out the authorization you have given us
                    to share your information in the manner described in this
                    Privacy Policy, you must send an email to
                    nticetravel@gmail.com.
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[11] = section
                }}
                className="section"
              >
                <h2>Changes to Privacy Policy</h2>
                <div className="box">
                  <p>
                    N’TICE reserves the right to amend, modify, add to, and/or
                    replace at any time, this Privacy Policy, in whole or in
                    part. We may inform you of such changes by posting them on
                    the Site. In such notice, we will disclose the effective
                    date of such amendments, changes, substitutions or
                    modifications to this Privacy Policy. Our use of the Site to
                    notify amendments, changes, substitutions or modifications
                    to this Privacy Policy will be understood as sufficient
                    notice to you. Unless otherwise provided in a specific
                    notice, changes or amendments to this Privacy Policy will
                    take effect as soon as they are posted through the Site. Any
                    material changes to this Privacy Policy regarding the
                    collection, use and disclosure of information will be of a
                    prospective nature, except in cases where the user grants
                    his express affirmative consent for such material changes to
                    apply to his information collected under previous versions
                    of this Privacy Policy.{' '}
                  </p>
                </div>
              </div>
              <div
                ref={(section) => {
                  this.sections[12] = section
                }}
                className="section"
              >
                <h2>Miscellaneous</h2>
                <div className="box">
                  <p>
                    If any provision of this Privacy Policy is declared null and
                    void, unlawful, unconstitutional or otherwise without legal
                    effect, in whole or in part, by a competent court, the
                    remaining provisions will remain valid and will continue to
                    bind the parties to all legal effects.
                  </p>
                  <p>
                    YOUR ACCESS, USE, VISIT OR TRANSACTIONS MADE ON OR THROUGH
                    THE WEBSITE, AS WELL AS ANY DISPUTES RELATED TO THE PRIVACY
                    OF YOUR INFORMATION, IS SUBJECT TO WHAT IS ESTABLISHED IN
                    THIS PRIVACY POLICY AND THE TERMS OF USE OF THE SITE,
                    INCLUDING, BUT NOT LIMITED TO, THE APPLICABLE LAWS,
                    INDEMNIFICATION AGREEMENTS AND RELEVANT RULES SET FORTH
                    THEREOF.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
